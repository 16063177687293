.usefulPage {

    form {
        margin-bottom: 0;
    }

    &__wrapper {
        margin: 30px 0 0;
        padding: 20px;
        background-color: $color-bg--neutral;
        border-radius: $border-radius;
    }

    &__fields,
    &__answered {
        @include flex($alignItems: center, $justifyContent: space-between);
        gap: 10px 20px;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--4;
        line-height: $line-height--heading;
        margin: 0;
    }

    &__choices {
        display: flex;
        gap: 20px;

        &.done {
            .usefulPage__answer {
                label {
                    cursor: auto;
                    pointer-events: none;
                }
            }

            &[data-answer="true"] {
                .usefulPage__answer {

                    &:not(.answerNo) {
                        label {
                            background: $color-dark;
                            border-color: $color-dark;
                            color: $color-white;
                            opacity: 0.7;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }

                    &.answerNo {
                        label {
                            background: $color-white;
                            border-color: $color-white;
                            color: $color-dark;
                            opacity: 0.5;

                            svg {
                                fill: $color-dark;
                            }
                        }
                    }
                }
            }

            &[data-answer="false"] {
                .usefulPage__answer {

                    &:not(.answerNo) {
                        label {
                            background: $color-white;
                            border-color: $color-white;
                            color: $color-dark;
                            opacity: 0.5;

                            svg {
                                fill: $color-dark;
                            }
                        }
                    }

                    &.answerNo {
                        label {
                            background: $color-dark;
                            border-color: $color-dark;
                            color: $color-white;
                            opacity: 0.7;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }

                }
            }
        }
    }

    &__answer {
        label {
            min-height: 35px;
            margin: 0;
            padding: 4px 20px;
        }

        input {
            display: none;
        }
    }

    &__message {
        margin-top: 15px;

        .acf-field {
            margin: 0 !important;
        }

        label, .description {
            margin: 0 0 5px 0 !important;
        }

        textarea {
            background: $color-white;
        }
    }

    &__mention {
        margin: 10px 0;

        a {
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: $color-text;
            transition: {
                property: color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }
    
            &:hover,&:focus {
                color: $color-text;
                text-decoration-color: transparent;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .usefulPage {
        &__answered,
        &__fields {
            align-items: baseline;
            flex-direction: column;
        }
    }

}

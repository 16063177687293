.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    line-height: $line-height--heading;
}

.h1 {
    font-size: $font-size--1;
}

.h2 {
    font-size: $font-size--2;
}

.h3 {
    font-size: $font-size--3;
}

.h4 {
    font-size: $font-size--4;
}

.h5 {
    font-size: $font-size--5;
}

.h6 {
    font-size: $font-size--6;
}

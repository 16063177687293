//======================================================================================================
// Contact base
//======================================================================================================
// Component badge
.contact-badge {
    display: flex;
    align-items: center;

    &__icon {
        flex: none;
        @include size(26px);
        margin-right: 8px;
        fill: $color-text;
    }

    &__link {
        @include flex($alignItems: center);
        gap: 2px;
        text-decoration: underline;
        word-break: break-all;
        color: $color-text;
        text-decoration: underline;
        text-decoration-color: $color-text;
        transition: {
            property: color, text-decoration-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            color: $color-text;
            text-decoration-color: transparent;

            svg {
                fill: $color-text;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $card-color;
        }

        &--underlinenone {
            text-decoration: none;
        }
    }

    &__additional {
        margin-top: 10px;
        
        &--no-margin {
            margin-top: 0;
        }
    }

    &--icon-top {
        align-items: flex-start;

        .contact-badge__icon {
            margin-top: -2px; // fix for margin on svg
        }
    }

    &--secondary {
        padding: 7px 0;

        .contact-badge__icon {
            box-sizing: content-box;
            @include size($btn_action_icon-size);
            margin-right: 12px;
            background-color: $btn_action_icon-bg;
            border: $btn_action_icon-border-width $btn_action_icon-border-style $btn_action_icon-border-color;
            border-radius: $btn_action_icon-border-radius;
            fill: $btn_action_icon-color;
            transition: {
                property: background-color, border-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {   
            .contact-badge__icon {
                background-color: $btn_action_icon-bg--hover;
                border-color: $btn_action_icon-border-color--hover;
                fill: $btn_action_icon-color--hover;
            }
        }
    }
}


//======================================================================================================
// Contact Single
//======================================================================================================
.contact-single { 

    // Container
    .contact-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    // Component button
    .contact-buttons {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 20px;
    }

}

.card {
    &--adverts {
        .card__container, .card__content {
            height: 100%;
        }

        .card__content {
            justify-content: center;
        }
    }
}

.listing-cards--adverts {
    padding-top: 30px;
}

.adverts__number-post {
    margin-top: 30px;
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    font-size: $font-size--4;
}
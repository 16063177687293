//======================================================================================================
// Bloc Map
//======================================================================================================
.bloc-map {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-map {
        .wrapper_map {
            height: 430px;
        }
    }
}

.c-popup_area {
    @include flex($alignItems: center, $justifyContent: center);
    @include fixed($top: 0, $left: 0);
    z-index: 999999; // idem glightbox z-index
    @include size(100%);
    opacity: 0;
    visibility: hidden;
    background-color: $color-black--rgba;
    transition: {
        property: visibility, opacity;
        duration: $duration;
        timing-function: $timing;
    }

    &__container {
        @include flex($direction: column, $alignItems: flex-start);

        video {
            max-width: 100%;
        }
    }
    
    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(40px);

        &--open {
            @include absolute($top: 50%, $left: 50%);
            transform: translate(-50%, -50%);
            background-color: $color-main;
            border-color: $color-main;
    
            svg {
                fill: $color-white;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: $color-white;
                border-color: $color-main;

                svg {
                    fill: $color-main;
                }
            }
        }

        &--close {
            @include absolute($top: 20px, $right: 20px);
            background-color: $color-white;
            border-color: $color-white;
    
            svg {
                fill: $color-text;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: transparent;
                border-color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &.js-open {
        visibility: visible;
        opacity: 1;
    }

    iframe {
        max-width: 100%;
    }

    .accordion {
        color: $color-white;

        svg {
            fill: $color-white;
        }
    }
}
//======================================================================================================
// General 
//======================================================================================================

$l_section-padding                              : 40px 0 !default;
$l_section_wrapper-gap                          : 40px !default;
$l_section_buttons-gap                          : 20px !default;
$l_section_socials-gap                          : 10px !default;

$swiper-margin                                  : 0 !default;
$swiper-padding                                 : 0 !default;
$swiper-margin--1200                            : 0 !default;
$swiper-padding--1200                           : 0 !default;



//======================================================================================================
// SlideShow
//======================================================================================================

// Height
$m-slideshow_height                             : 590px !default;
$m-slideshow_height--1200                       : 360px !default;
$m-slideshow_height--960                        : 280px !default;
$m-slideshow_height--640                        : 220px !default;


// Content (title + description)
$m-slideshow_content_bottom                     : 80px !default;
$m-slideshow_content_bottom--960                : 75px !default;
$m-slideshow_content_bottom--640                : unset !default;
$m-slideshow_content_bottom--video              : 20px !default;
$m-slideshow_content_bottom--pag                : 65px !default;
$m-slideshow_content_bottom--pag--640           : unset !default;
$m-slideshow_content-maxwidth                   : 485px !default;
$m-slideshow_content-maxwidth--1200             : 485px !default;
$m-slideshow_content-maxwidth--960              : 485px !default;
$m-slideshow_content-padding                    : 20px !default;
$m-slideshow_content-bg                         : $color-bg-caption !default;
$m-slideshow_content-bg--640                    : $color-main !default;
$m-slideshow_content-color                      : $color-caption !default;
$m-slideshow_content-radius                     : $border-radius !default;
$m-slideshow_content-margin--640                : -20px 0 0 0 !default;


// Style Title (with description)
$m-slideshow_title-ffamily                      : $font-family--heading !default;
$m-slideshow_title-fsize                        : $font-size--4 !default;
$m-slideshow_title-fweight                      : $font-weight-bold !default;


// Style description
$m-slideshow_desc-ffamily                       : $font-family !default;
$m-slideshow_desc-fsize                         : $font-size--text-small !default;
$m-slideshow_desc-fweight                       : $font-weight-normal !default;


// Content (title alone)
$m-slideshow_fig_bottom                         : 80px !default;
$m-slideshow_fig_bottom--960                    : 75px !default;
$m-slideshow_fig_bottom--640                    : 70px !default;
$m-slideshow_fig_bottom--video                  : 20px !default;
$m-slideshow_fig_bottom--video--640             : 15px !default;
$m-slideshow_fig_bottom--pag                    : 65px !default;
$m-slideshow_fig_bottom--pag--640               : 60px !default;
$m-slideshow_fig_bottom-maxwidth                : 90% !default;
$m-slideshow_fig-gap                            : 5px !default;
$m-slideshow_fig-padding                        : 8px 15px !default;
$m-slideshow_fig-padding--640                   : 5px 15px !default;
$m-slideshow_fig-bg                             : $color-bg-caption !default;
$m-slideshow_fig-color                          : $color-caption !default;
$m-slideshow_fig-radius                         : $border-radius !default;
$m-slideshow_fig-ffamily                        : $font-family--heading !default;
$m-slideshow_fig-fsize                          : $font-size--text !default;
$m-slideshow_fig-fsize--640                     : $font-size--text-small !default;
$m-slideshow_fig-fweight                        : $font-weight-bold !default;


// Navigation
$m-slideshow_nav-gap                            : 10px !default;

// Navigation -- Button
$m-slideshow_nav_btn-bg                         : $color-bg-caption !default;
$m-slideshow_nav_btn-bd-color                   : $color-bg-caption !default;
$m-slideshow_nav_btn-color                      : $color-text--main !default;
$m-slideshow_nav_btn-bg--hover                  : $color-white !default;
$m-slideshow_nav_btn-bd-color--hover            : $color-main !default;
$m-slideshow_nav_btn-color--hover               : $color-text !default;

// Navigation -- Position
$m-slideshow_nav_bottom                         : 20px !default;
$m-slideshow_nav_bottom--640                    : 15px !default;
$m-slideshow_nav_bottom--640--desc              : unset !default;
$m-slideshow_nav_top--640--desc                 : 145px !default;


// Pagination
$m-slideshow_pag-gap                            : 10px !default;
$m-slideshow_pag-padding                        : 6px 15px !default;
$m-slideshow_pag-bg                             : rgba($color-black, 0.5) !default;
$m-slideshow_pag-radius                         : $border-radius--button !default;

// Pagination -- Button
$m-slideshow_pag_btn-size                       : 12px !default;
$m-slideshow_pag_btn-color                      : $color-white !default;
$m-slideshow_pag_btn-color--hover               : $color-white !default;

// Pagination -- Bullet
$m-slideshow_pag_bullet-size                    : 12px !default;
$m-slideshow_pag_bullet-radius                  : $border-radius--icon !default;
$m-slideshow_pag_bullet-bg                      : transparent !default;
$m-slideshow_pag_bullet-bd                      : 1px solid $color-white !default;
$m-slideshow_pag_bullet-bg--hover               : $color-white !default;
$m-slideshow_pag_bullet-bd-color--hover         : $color-white !default;

// Pagination -- Position
$m-slideshow_pag_bottom                         : 20px !default;
$m-slideshow_pag_bottom--640                    : 15px !default;
$m-slideshow_pag_bottom--640--desc              : unset !default;
$m-slideshow_pag_top--640--desc                 : 155px !default;


// Searchbar
$m-searchbar-justifyContent                     : center !default;
$m-searchbar-paddingTop                         : 0 !default;
$m-searchbar-paddingBottom                      : 0 !default;
$m-searchbar-maxwidth                           : 510px !default;
$m-searchbar-height                             : 50px !default;
$m-searchbar-padding                            : 15px 55px 15px 20px !default;
$m-searchbar-radius                             : $border-radius !default;
$m-searchbar-bg                                 : $color-white--rgba !default;
$m-searchbar-bg--focus                          : $color-white !default;

// Searchbar -- Button
$m-searchbar_btn-right                          : 15px !default;
$m-searchbar_btn-size                           : auto !default;
$m-searchbar_btn-radius                         : $border-radius--icon !default;
$m-searchbar_btn-bg                             : transparent !default;
$m-searchbar_btn-bd                             : 1px solid transparent !default;
$m-searchbar_btn-color                          : $color-text !default;
$m-searchbar_btn-bg--hover                      : transparent !default;
$m-searchbar_btn-bd-color--hover                : $color-text !default;
$m-searchbar_btn-color--hover                   : $color-text !default;

// Searchbar -- Results
$m-searchbar_results-top                        : 55px !default;
$m-searchbar_results-radius                     : $border-radius !default;
$m-searchbar_results-bd                         : 1px solid $color-light !default;
$m-searchbar_results-shadow                     : none !default;



//======================================================================================================
// SlideShow models
//======================================================================================================

// m-fs-croped
$m-croped-padding                               : 0 0 0 0 !default;
$m-croped-radius                                : $border-radius--image !default;
$m-croped-container-margin                      : 0 20px !default;



//======================================================================================================
// Access
//======================================================================================================
$c_access-gap                                   : 5px !default;
$c_access-gap--1200                             : 5px !default;
$c_access-gap--960                              : 5px !default;
$c_access-gap--640                              : 5px !default;

$c_access-alignItems                            : center !default;
$c_acesss-justifyContent                        : unset !default;

$c_access__image-size                           : 90px !default;
$c_access__image-size--1200                     : 80px !default;
$c_access__image-size--960                      : 70px !default;
$c_access__image-size--640                      : 60px !default;
$c_access__image-radius                         : 0 !default;
$c_access__image-bg                             : transparent !default;
$c_access__image-border                         : 0 !default;
$c_access__image-bg--hover                      : transparent !default;
$c_access__image-bd-color--hover                : transparent !default;

$c_access__title-max-width                      : 80px !default;
$c_access__title-ffamily                        : $font-family !default;
$c_access__title-fweight                        : $font-weight-semibold !default;
$c_access__title-fsize                          : $font-size--text-small !default;
$c_access__title-ttransform                     : initial !default;
$c_access__title-talign                         : center !default;
$c_access__title-line-height                    : 1.2 !default;
$c_access__title-color                          : $color-text !default;
$c_access__title-color--hover                   : $color-text !default;


// m-fqa-classic
$m-fqa-classic_section-margin                   : 0 !default;
$m-fqa-classic_section-margin--1200             : 0 !default;
$m-fqa-classic_section-margin--960              : 0 !default;
$m-fqa-classic_section-margin--640              : 0 !default;
$m-fqa-classic_section-padding                  : $l_section-padding !default;
$m-fqa-classic_section-bg                       : transparent !default;

$m-fqa-classic_wrapper-position                 : inherit !default;
$m-fqa-classic_wrapper-zindex                   : inherit !default;
$m-fqa-classic_wrapper-margin                   : 0 !default;
$m-fqa-classic_wrapper-margin--1200             : 0 !default;
$m-fqa-classic_wrapper-margin--960              : 0 !default;
$m-fqa-classic_wrapper-margin--640              : 0 !default;
$m-fqa-classic_wrapper-padding                  : 0 !default;
$m-fqa-classic_wrapper-padding--1200            : 0 !default;
$m-fqa-classic_wrapper-padding--960             : 0 !default;
$m-fqa-classic_wrapper-padding--640             : 0 !default;
$m-fqa-classic_wrapper-bg                       : transparent !default;
$m-fqa-classic_wrapper-bd                       : 0 !default;
$m-fqa-classic_wrapper-radius                   : 0 !default;
$m-fqa-classic_wrapper-boxshadow                : none !default;

$m-fqa-classic_list-gap                         : 20px !default;
$m-fqa-classic_list-gap--1200                   : 20px !default;
$m-fqa-classic_list-gap--960                    : 20px !default;
$m-fqa-classic_list-gap--640                    : 20px !default;

$m-fqa-classic_item-size                        : 150px !default;
$m-fqa-classic_item-size--1200                  : 140px !default;
$m-fqa-classic_item-size--960                   : 100px !default;
$m-fqa-classic_item-size--640                   : 100px !default;

// m-fqa-double
$m-fqa-double-bg                                : $color-bg--neutral !default;
$m-fqa-double_access-bg                         : $color-bg--neutral !default;
$m-fqa-double_access-gap                        : 40px !default;
$m-fqa-double_access-gap--1200                  : 30px !default;
$m-fqa-double_access-gap--960                   : 20px !default;
$m-fqa-double_access-gap--640                   : 20px !default;
$m-fqa-double_menu-bg                           : $color-main !default;
$m-fqa-double_menu_link-padding                 : 13px 15px !default;
$m-fqa-double_menu_link-bg                      : transparent !default;
$m-fqa-double_menu_link-border                  : 1px solid $color-text--main !default;
$m-fqa-double_menu_link-radius                  : $border-radius--input !default;
$m-fqa-double_menu_link-ffamily                 : $font-family !default;
$m-fqa-double_menu_link-fweight                 : $font-weight !default;
$m-fqa-double_menu_link-fsize                   : $font-size--text !default;
$m-fqa-double_menu_link-line-height             : $line-height !default;
$m-fqa-double_menu_link-color                   : $color-text--main !default;
$m-fqa-double_menu_link-bg--hover               : $color-white !default;
$m-fqa-double_menu_link-bd-color--hover         : $color-white !default;
$m-fqa-double_menu_link-color--hover            : $color-text !default;

$m-fqa-double_access-padding--960               : 30px 0 !default; 
$m-fqa-double_menu-padding--960                 : 30px 0 !default; 

$m-fqa-double_item-size--960                    : 100px !default;
$m-fqa-double_item-size--640                    : 100px !default;

// m-fqa-double-left
$m-fqa-double_left_access-padding               : 40px 65px 40px 0 !default;
$m-fqa-double_left_access-padding--1200         : 40px 65px 40px 0 !default;
$m-fqa-double_left_menu-padding                 : 35px 0 35px 60px !default;
$m-fqa-double_left_menu-padding--1200           : 35px 0 35px 60px !default;

// m-fqa-double-right
$m-fqa-double_right_menu-padding                : 35px 60px 35px 0 !default;
$m-fqa-double_right_menu-padding--1200          : 35px 60px 35px 0 !default;
$m-fqa-double_right_access-padding              : 40px 0 40px 65px !default;
$m-fqa-double_right_access-padding--1200        : 40px 0 40px 65px !default;



//======================================================================================================
// Mixed access slideshow
//======================================================================================================

// if only when access over slideshow
$m-mixed-sa__slideshow-width                    : 0 !default;
$m-mixed-sa-position                            : unset !default;
$m-mixed-sa-zindex                              : initial !default;

$m-mixed-sa__access-bg                          : transparent !default;

$m-mixed-sa-padding-top-bottom--960             : 20px !default;
$m-mixed-sa__access-margin-left                 : 0 !default;
$m-mixed-sa__access-radius                      : 0 !default;
$m-mixed-sa__access-bg                          : $color-bg--neutral !default;
$m-mixed-sa__access-padding-left                : 55px !default;
$m-mixed-sa__access-padding-left--1200          : 45px !default;
$m-mixed-sa__access-padding-top-bottom          : 30px !default;
$m-mixed-sa__access-padding-top-bottom--1200    : 20px !default;
$m-mixed-sa__access-padding-top-bottom--960     : 20px !default;
$m-mixed-sa__access-padding-top-bottom--640     : 20px !default;
$m-mixed-sa__access__list-padding               : 0 !default;
$m-mixed-sa__access__list-bg                    : transparent !default;
$m-mixed-sa__access__list-shadow                : none !default;
$m-mixed-sa__access__list-gap                   : 30px 20px !default;
$m-mixed-sa__access__list-gap--1200             : 20px !default;
$m-mixed-sa__access__list-alignItems            : flex-start !default;
$m-mixed-sa__access__list-justifyContent        : unset !default;
$m-mixed-sa__access__list-alignContent          : unset !default;


//======================================================================================================
// Publications models
//======================================================================================================

// 3 defilants (m-fp-3_slides)
$m-fp-3_slides_nav_btn-margin-top               : -80px !default;
$m-fp-3_slides_nav_btn-bg                       : $btn-bg !default;
$m-fp-3_slides_nav_btn-bd-color                 : $btn-border-color !default;
$m-fp-3_slides_nav_btn-color                    : $btn-color !default;
$m-fp-3_slides_nav_btn-bg--hover                : $btn-bg--hover !default;
$m-fp-3_slides_nav_btn-bd-color--hover          : $btn-border-color--hover !default;
$m-fp-3_slides_nav_btn-color--hover             : $btn-color--hover !default;

$m-fp-3_slides_btn-bg                           : $btn-bg !default;
$m-fp-3_slides_btn-bd-color                     : $btn-border-color !default;
$m-fp-3_slides_btn-color                        : $btn-color !default;
$m-fp-3_slides_btn-bg--hover                    : $btn-bg--hover !default;
$m-fp-3_slides_btn-bd-color--hover              : $btn-border-color--hover !default;
$m-fp-3_slides_btn-color--hover                 : $btn-color--hover !default;

$m-fp-3_slides_btn_prop-bg                      : $btn-second-bg !default;
$m-fp-3_slides_btn_prop-bd-color                : $btn-second-border-color !default;
$m-fp-3_slides_btn_prop-color                   : $btn-second-color !default;
$m-fp-3_slides_btn_prop-bg--hover               : $btn-second-bg--hover !default;
$m-fp-3_slides_btn_prop-bd-color--hover         : $btn-second-border-color--hover !default;
$m-fp-3_slides_btn_prop-color--hover            : $btn-second-color--hover !default;


// 2 defilants (m-fp-2_slides)
$m-fp-2_slides_nav_btn-margin-top               : -80px !default;
$m-fp-2_slides_nav_btn-bg                       : $btn-bg!default;
$m-fp-2_slides_nav_btn-bd-color                 : $btn-border-color !default;
$m-fp-2_slides_nav_btn-color                    : $btn-color !default;
$m-fp-2_slides_nav_btn-bg--hover                : $btn-bg--hover !default;
$m-fp-2_slides_nav_btn-bd-color--hover          : $btn-border-color--hover !default;
$m-fp-2_slides_nav_btn-color--hover             : $btn-color--hover !default;

$m-fp-2_slides_btn-bg                           : $btn-bg !default;
$m-fp-2_slides_btn-bd-color                     : $btn-border-color !default;
$m-fp-2_slides_btn-color                        : $btn-color !default;
$m-fp-2_slides_btn-bg--hover                    : $btn-bg--hover !default;
$m-fp-2_slides_btn-bd-color--hover              : $btn-border-color--hover !default;
$m-fp-2_slides_btn-color--hover                 : $btn-color--hover !default;

$m-fp-2_slides_btn_prop-bg                      : $btn-second-bg !default;
$m-fp-2_slides_btn_prop-bd-color                : $btn-second-border-color !default;
$m-fp-2_slides_btn_prop-color                   : $btn-second-color !default;
$m-fp-2_slides_btn_prop-bg--hover               : $btn-second-bg--hover !default;
$m-fp-2_slides_btn_prop-bd-color--hover         : $btn-second-border-color--hover !default;
$m-fp-2_slides_btn_prop-color--hover            : $btn-second-color--hover !default;

$m-fp-2_slides_date-pos-top                     : 20px !default;
$m-fp-2_slides_date-pos-right                   : unset !default;
$m-fp-2_slides_date-pos-bot                     : unset !default;
$m-fp-2_slides_date-pos-left                    : 20px !default;
$m-fp-2_slides_date-pos-width                   : fit-content !default;
$m-fp-2_slides_date-pos-margin                  : 0 !default;

$m-fp-2_slides_date-pos-top--960                : 20px !default;
$m-fp-2_slides_date-pos-right--960              : unset !default;
$m-fp-2_slides_date-pos-bot--960                : unset !default;
$m-fp-2_slides_date-pos-left--960               : 20px !default;



// 1 grand et 2 petits (m-fp-1_big_2_smalls)
$m-fp-1_big_2_smalls_btn-bg                     : $btn-bg !default;
$m-fp-1_big_2_smalls_btn-bd-color               : $btn-border-color !default;
$m-fp-1_big_2_smalls_btn-color                  : $btn-color !default;
$m-fp-1_big_2_smalls_btn-bg--hover              : $btn-bg--hover !default;
$m-fp-1_big_2_smalls_btn-bd-color--hover        : $btn-border-color--hover !default;
$m-fp-1_big_2_smalls_btn-color--hover           : $btn-color--hover !default;

$m-fp-1_big_2_smalls_btn_prop-bg                : $btn-second-bg !default;
$m-fp-1_big_2_smalls_btn_prop-bd-color          : $btn-second-border-color !default;
$m-fp-1_big_2_smalls_btn_prop-color             : $btn-second-color !default;
$m-fp-1_big_2_smalls_btn_prop-bg--hover         : $btn-second-bg--hover !default;
$m-fp-1_big_2_smalls_btn_prop-bd-color--hover   : $btn-second-border-color--hover !default;
$m-fp-1_big_2_smalls_btn_prop-color--hover      : $btn-second-color--hover !default;

// if padding total
$m-fp-1_big_2_smalls_item--large_content-padding : 20px !default;


// 1 grand et 3 defilants (m-1_big_3_slides)
$m-fp-1_big_3_slides_nav_btn-bg                 : $btn-bg !default;
$m-fp-1_big_3_slides_nav_btn-bd-color           : $btn-border-color !default;
$m-fp-1_big_3_slides_nav_btn-color              : $btn-color !default;
$m-fp-1_big_3_slides_nav_btn-bg--hover          : $btn-bg--hover !default;
$m-fp-1_big_3_slides_nav_btn-bd-color--hover    : $btn-border-color--hover !default;
$m-fp-1_big_3_slides_nav_btn-color--hover       : $btn-color--hover !default;

$m-fp-1_big_3_slides_btn-bg                     : $btn-bg !default;
$m-fp-1_big_3_slides_btn-bd-color               : $btn-border-color !default;
$m-fp-1_big_3_slides_btn-color                  : $btn-color !default;
$m-fp-1_big_3_slides_btn-bg--hover              : $btn-bg--hover !default;
$m-fp-1_big_3_slides_btn-bd-color--hover        : $btn-border-color--hover !default;
$m-fp-1_big_3_slides_btn-color--hover           : $btn-color--hover !default;

$m-fp-1_big_3_slides_btn_prop-bg                : $btn-second-bg !default;
$m-fp-1_big_3_slides_btn_prop-bd-color          : $btn-second-border-color !default;
$m-fp-1_big_3_slides_btn_prop-color             : $btn-second-color !default;
$m-fp-1_big_3_slides_btn_prop-bg--hover         : $btn-second-bg--hover !default;
$m-fp-1_big_3_slides_btn_prop-bd-color--hover   : $btn-second-border-color--hover !default;
$m-fp-1_big_3_slides_btn_prop-color--hover      : $btn-second-color--hover !default;

$m-fp-1_big_3_slides_date-pos-top               : 20px !default;
$m-fp-1_big_3_slides_date-pos-right             : unset !default;
$m-fp-1_big_3_slides_date-pos-bot               : unset !default;
$m-fp-1_big_3_slides_date-pos-left              : 20px !default;
$m-fp-1_big_3_slides_date-pos-width             : fit-content !default;


// 4 identiques (m-fp-4_identical)
$m-fp-4_identical_btn-bg                        : $btn-bg !default;
$m-fp-4_identical_btn-bd-color                  : $btn-border-color !default;
$m-fp-4_identical_btn-color                     : $btn-color !default;
$m-fp-4_identical_btn-bg--hover                 : $btn-bg--hover !default;
$m-fp-4_identical_btn-bd-color--hover           : $btn-border-color--hover !default;
$m-fp-4_identical_btn-color--hover              : $btn-color--hover !default;

$m-fp-4_identical_btn_prop-bg                   : $btn-second-bg !default;
$m-fp-4_identical_btn_prop-bd-color             : $btn-second-border-color !default;
$m-fp-4_identical_btn_prop-color                : $btn-second-color !default;
$m-fp-4_identical_btn_prop-bg--hover            : $btn-second-bg--hover !default;
$m-fp-4_identical_btn_prop-bd-color--hover      : $btn-second-border-color--hover !default;
$m-fp-4_identical_btn_prop-color--hover         : $btn-second-color--hover !default;


//======================================================================================================
// Publications mixed
//======================================================================================================
// Buttons posts
$m-mixed_pub_post_btn-bg                        : $btn-bg !default;
$m-mixed_pub_post_btn-bd-color                  : $btn-border-color !default;
$m-mixed_pub_post_btn-color                     : $btn-color !default;
$m-mixed_pub_post_btn-bg--hover                 : $btn-bg--hover !default;
$m-mixed_pub_post_btn-bd-color--hover           : $btn-border-color--hover !default;
$m-mixed_pub_post_btn-color--hover              : $btn-color--hover !default;

$m-mixed_pub_post_btn_prop-bg                   : $btn-second-bg !default;
$m-mixed_pub_post_btn_prop-bd-color             : $btn-second-border-color !default;
$m-mixed_pub_post_btn_prop-color                : $btn-second-color !default;
$m-mixed_pub_post_btn_prop-bg--hover            : $btn-second-bg--hover !default;
$m-mixed_pub_post_btn_prop-bd-color--hover      : $btn-second-border-color--hover !default;
$m-mixed_pub_post_btn_prop-color--hover         : $btn-second-color--hover !default;

// Buttons events
$m-mixed_pub_event_btn-bg                       : $btn-bg !default;
$m-mixed_pub_event_btn-bd-color                 : $btn-border-color !default;
$m-mixed_pub_event_btn-color                    : $btn-color !default;
$m-mixed_pub_event_btn-bg--hover                : $btn-bg--hover !default;
$m-mixed_pub_event_btn-bd-color--hover          : $btn-border-color--hover !default;
$m-mixed_pub_event_btn-color--hover             : $btn-color--hover !default;

$m-mixed_pub_event_btn_prop-bg                  : $btn-second-bg !default;
$m-mixed_pub_event_btn_prop-bd-color            : $btn-second-border-color !default;
$m-mixed_pub_event_btn_prop-color               : $btn-second-color !default;
$m-mixed_pub_event_btn_prop-bg--hover           : $btn-second-bg--hover !default;
$m-mixed_pub_event_btn_prop-bd-color--hover     : $btn-second-border-color--hover !default;
$m-mixed_pub_event_btn_prop-color--hover        : $btn-second-color--hover !default;

$m-mixed_pub_event_only_card__container-padding--1200   : 0 !default;
$m-mixed_pub_event_only_card__content-padding--1200     : 10px !default;
$m-mixed_pub_event_only_card__container-padding--640    : 0 !default;
$m-mixed_pub_event_only_card__content-padding--640      : 20px !default;



//======================================================================================================
// Documents
//======================================================================================================
$c_document_image-radius                        : $border-radius--image !default;
$c_document_image-shadow                        : $shadow !default;

$c_document_content-padding                     : 30px !default;
$c_document_content-bg                          : $card-bg !default;
$c_document_content-radius                      : $border-radius !default;
$c_document_content-shadow                      : $shadow !default;

$c_document_title-ffamily                       : $font-family--heading !default;
$c_document_title-fweight                       : $font-weight--heading !default;
$c_document_title-fsize                         : $font-size--4 !default;
$c_document_title-lineheight                    : $line-height--heading !default;
$c_document_title-ttransform                    : unset !default;
$c_document_title-color                         : $card-color !default;

$c_document_subinf-color                        : $card-color--second !default;

$c_document_button-bg                           : $btn-bg !default;
$c_document_button-bd-color                     : $btn-border-color !default;
$c_document_button-color                        : $btn-color !default;
$c_document_button-bg--hover                    : $btn-bg--hover !default;
$c_document_button-bd-color--hover              : $btn-border-color--hover !default;
$c_document_button-color--hover                 : $btn-color--hover !default;

$c_document_nav-bg                              : $btn-bg !default;
$c_document_nav-bd-color                        : $btn-border-color !default;
$c_document_nav-color                           : $btn-color !default;
$c_document_nav-bg--hover                       : $btn-bg--hover !default;
$c_document_nav-bd-color--hover                 : $btn-border-color--hover !default;
$c_document_nav-color--hover                    : $btn-color--hover !default;

$c_document_button_sys-bg                       : $btn-bg !default;
$c_document_button_sys-bd-color                 : $btn-border-color !default;
$c_document_button_sys-color                    : $btn-color !default;
$c_document_button_sys-bg--hover                : $btn-bg--hover !default;
$c_document_button_sys-bd-color--hover          : $btn-border-color--hover !default;
$c_document_button_sys-color--hover             : $btn-color--hover !default;


// Model Coverflow
$m-fd-coverflow_content-padding                 : 40px !default;
$m-fd-coverflow_swiper-margin                   : 0 !default;
$m-fd-coverflow_swiper-padding                  : 0 !default;
$m-fd-coverflow_swiper-margin--1200             : 0 !default;
$m-fd-coverflow_swiper-padding--1200            : 0 !default;



//======================================================================================================
// Banner
//======================================================================================================
$m-banner_section-margin                        : 0 !default;
$m-banner_section-padding                       : 40px 0 !default;
$m-banner_section-bg                            : unset !default;

$m-banner_wrapper-alignItems                    : center !default;
$m-banner_wrapper-justifyContent                : space-between !default;
$m-banner_wrapper-gap                           : 30px !default;
$m-banner_wrapper-gap--1200                     : 30px !default;
$m-banner_wrapper-margin                        : 0 !default;
$m-banner_wrapper-padding                       : 30px !default;
$m-banner_wrapper-padding--1200                 : 30px !default;
$m-banner_wrapper-padding--960                  : 30px !default;
$m-banner_wrapper-padding--640                  : 30px !default;
$m-banner_wrapper-bg                            : $color-bg--neutral !default;
$m-banner_wrapper-border                        : 0 !default;
$m-banner_wrapper-radius                        : $border-radius !default;
$m-banner_wrapper-shadow                        : $shadow !default;

$m-banner_links-wrap                            : nowrap !default;
$m-banner_links-wrap--1200                      : wrap !default;
$m-banner_links-alignItems                      : center !default;
$m-banner_links-justifyContent                  : space-between !default;
$m-banner_links-gap                             : 30px !default;
$m-banner_links-gap--1200                       : 30px !default;

$m-banner__socials_btn-bg                       : $btn-bg !default;
$m-banner__socials_btn-bd-color                 : $btn-border-color !default;
$m-banner__socials_btn-color                    : $btn-color !default;
$m-banner__socials_btn-bg--hover                : $btn-bg--hover !default;
$m-banner__socials_btn-bd-color--hover          : $btn-border-color--hover !default;
$m-banner__socials_btn-color--hover             : $btn-color--hover !default;

$m-banner_buttons-justifyContent                : flex-start !default;
$m-banner_buttons_btn-bg                        : $btn-bg !default;
$m-banner_buttons_btn-bd-color                  : $btn-border-color !default;
$m-banner_buttons_btn-color                     : $btn-color !default;
$m-banner_buttons_btn-bg--hover                 : $btn-bg--hover !default;
$m-banner_buttons_btn-bd-color--hover           : $btn-border-color--hover !default;
$m-banner_buttons_btn-color--hover              : $btn-color--hover !default;



//======================================================================================================
// Banner & Documents
//======================================================================================================
$m-db-docs_banner__wrapper-alignItems           : stretch !default;
$m-db-banner__wrapper-padding                   : 50px !default;
$m-db-banner__wrapper-padding--1200             : 50px !default;
$m-db-banner__wrapper-padding--960              : 30px !default;
$m-db-banner__wrapper-padding--640              : 30px 20px !default;
$m-db-banner__wrapper-min-height                : 330px !default;
$m-db-banner__wrapper-bg                        : $color-bg--neutral !default;
$m-db-banner__wrapper-border                    : 0 !default;
$m-db-banner__wrapper-radius                    : $border-radius !default;
$m-db-banner__wrapper-shadow                    : $shadow !default;
$m-db-banner__wrapper-color                     : $color-text !default;

$m-db-banner__socials_btn-bg                    : $color-main !default;
$m-db-banner__socials_btn-bd-color              : $color-main !default;
$m-db-banner__socials_btn-color                 : $color-white !default;
$m-db-banner__socials_btn-bg--hover             : $color-white !default;
$m-db-banner__socials_btn-bd-color--hover       : $color-white !default;
$m-db-banner__socials_btn-color--hover          : $color-text !default;

$m-db-banner__buttons-justifyContent            : flex-start !default;
$m-db-banner__buttons_btn-bg                    : $color-main !default;
$m-db-banner__buttons_btn-bd-color              : $color-main !default;
$m-db-banner__buttons_btn-color                 : $color-white !default;
$m-db-banner__buttons_btn-bg--hover             : $color-white !default;
$m-db-banner__buttons_btn-bd-color--hover       : $color-white !default;
$m-db-banner__buttons_btn-color--hover          : $color-text !default;

$m-db-banner__docs-swiper-margin                : -15px 0 -15px -15px !default;
$m-db-banner__docs-swiper-padding               : 15px 0 15px 15px !default;
$m-db-banner__docs-swiper-margin--1200          : -10px 0 -10px -10px !default;
$m-db-banner__docs-swiper-padding--1200         : 10px 0 10px 10px !default;



//======================================================================================================
// Admin area
//======================================================================================================

// Admin area in common
$c_admin_area__caption-padding                  : 10px 20px !default;
$c_admin_area__caption-bg                       : $color-main--rgba !default;
$c_admin_area__caption-border                   : 0 !default;
$c_admin_area__caption-radius                   : $border-radius--second !default;
$c_admin_area__caption-color                    : $color-text--main !default;

$c_admin_area__video_btn-bg                     : $btn-bg !default;
$c_admin_area__video_btn-bd-color               : $btn-border-color !default;
$c_admin_area__video_btn-color                  : $btn-color !default;
$c_admin_area__video_btn-bg--hover              : $color-white !default;
$c_admin_area__video_btn-bd-color--hover        : $color-white !default;
$c_admin_area__video_btn-color--hover           : $color-text !default;


// 5 blocs défilants (m-fa-5_sliding_blocs)
$m-fa-5_sliding_blocs_nav_btn-margin-top        : -80px !default;
$m-fa-5_sliding_blocs_nav_btn-bg                : $btn-bg !default;
$m-fa-5_sliding_blocs_nav_btn-bd-color          : $btn-border-color !default;
$m-fa-5_sliding_blocs_nav_btn-color             : $btn-color !default;
$m-fa-5_sliding_blocs_nav_btn-bg--hover         : $btn-bg--hover !default;
$m-fa-5_sliding_blocs_nav_btn-bd-color--hover   : $btn-border-color--hover !default;
$m-fa-5_sliding_blocs_nav_btn-color--hover      : $btn-color--hover !default;

$m-fa-5_sliding_blocs_image-radius              : $border-radius--image !default;

$m-fa-5_sliding_blocs_title-ffamily             : $font-family !default;
$m-fa-5_sliding_blocs_title-fweight             : $font-weight-bold !default;
$m-fa-5_sliding_blocs_title-fsize               : $font-size--text-small !default;
$m-fa-5_sliding_blocs_title-ttransform          : uppercase !default;
$m-fa-5_sliding_blocs_title-talign              : center !default;
$m-fa-5_sliding_blocs_title-color               : $color-text--light !default;
$m-fa-5_sliding_blocs_content-max-width         : unset !default;

$m-fa-5_sliding_blocs-justifyContent            : flex-end !default;
$m-fa-5_sliding_blocs_btn-bg                    : $btn-bg !default;
$m-fa-5_sliding_blocs_btn-bd-color              : $btn-border-color !default;
$m-fa-5_sliding_blocs_btn-color                 : $btn-color !default;
$m-fa-5_sliding_blocs_btn-bg--hover             : $btn-bg--hover !default;
$m-fa-5_sliding_blocs_btn-bd-color--hover       : $btn-border-color--hover !default;
$m-fa-5_sliding_blocs_btn-color--hover          : $btn-color--hover !default;


// 2 blocs défilant (m-fa-2_sliding_blocs)
$m-fa-2_sliding_blocs_nav_btn-margin-top        : -80px !default;
$m-fa-2_sliding_blocs_nav_btn-bg                : $btn-bg !default;
$m-fa-2_sliding_blocs_nav_btn-bd-color          : $btn-border-color !default;
$m-fa-2_sliding_blocs_nav_btn-color             : $btn-color !default;
$m-fa-2_sliding_blocs_nav_btn-bg--hover         : $btn-bg--hover !default;
$m-fa-2_sliding_blocs_nav_btn-bd-color--hover   : $btn-border-color--hover !default;
$m-fa-2_sliding_blocs_nav_btn-color--hover      : $btn-color--hover !default;

$m-fa-2_sliding_blocs_image-radius              : $border-radius--image !default;

$m-fa-2_sliding_blocs-justifyContent            : flex-end !default;
$m-fa-2_sliding_blocs_btn-bg                    : $btn-bg !default;
$m-fa-2_sliding_blocs_btn-bd-color              : $btn-border-color !default;
$m-fa-2_sliding_blocs_btn-color                 : $btn-color !default;
$m-fa-2_sliding_blocs_btn-bg--hover             : $btn-bg--hover !default;
$m-fa-2_sliding_blocs_btn-bd-color--hover       : $btn-border-color--hover !default;
$m-fa-2_sliding_blocs_btn-color--hover          : $btn-color--hover !default;


// 1 bloc défilant (m-fa-1_sliding_bloc)
$m-fa-1_sliding_bloc_nav_btn-margin-top        : -80px !default;
$m-fa-1_sliding_bloc_nav_btn-bg                 : $btn-bg !default;
$m-fa-1_sliding_bloc_nav_btn-bd-color           : $btn-border-color !default;
$m-fa-1_sliding_bloc_nav_btn-color              : $btn-color !default;
$m-fa-1_sliding_bloc_nav_btn-bg--hover          : $btn-bg--hover !default;
$m-fa-1_sliding_bloc_nav_btn-bd-color--hover    : $btn-border-color--hover !default;
$m-fa-1_sliding_bloc_nav_btn-color--hover       : $btn-color--hover !default;

$m-fa-1_sliding_bloc_image-radius               : $border-radius--image !default;

$m-fa-1_sliding_bloc_content-min-height         : 290px !default;
$m-fa-1_sliding_bloc_content-min-height--960    : 180px !default;
$m-fa-1_sliding_bloc_content-padding            : 40px !default;
$m-fa-1_sliding_bloc_content-padding--1200      : 30px !default;
$m-fa-1_sliding_bloc_content-padding--640       : 20px !default;
$m-fa-1_sliding_bloc_content-radius             : $border-radius !default;
$m-fa-1_sliding_bloc_content-bg                 : $color-bg--neutral !default;
$m-fa-1_sliding_bloc_content-bd                 : 0 !default;
$m-fa-1_sliding_bloc_content-shadow             : none !default;
$m-fa-1_sliding_bloc_content-color              : $color-text !default;
$m-fa-1_sliding_bloc_content-bg--hover          : $color-card--hover !default;
$m-fa-1_sliding_bloc_content-bd-color--hover    : none !default;
$m-fa-1_sliding_bloc_content-shadow--hover      : $m-fa-1_sliding_bloc_content-shadow !default;
$m-fa-1_sliding_bloc_content-color--hover       : $m-fa-1_sliding_bloc_content-color !default;

$m-fa-1_sliding_bloc_title-ffamily              : $font-family--heading !default;
$m-fa-1_sliding_bloc_title-fweight              : $font-weight-bold !default;
$m-fa-1_sliding_bloc_title-fsize                : $font-size--3 !default;
$m-fa-1_sliding_bloc_title-lineheight           : $line-height--heading !default;
$m-fa-1_sliding_bloc_title-color                : $m-fa-1_sliding_bloc_content-color !default;

$m-fa-1_sliding_bloc_desc-ffamily               : $font-family !default;
$m-fa-1_sliding_bloc_desc-fweight               : $font-weight !default;
$m-fa-1_sliding_bloc_desc-fsize                 : $font-size--text !default;
$m-fa-1_sliding_bloc_desc-lineheight            : $line-height !default;
$m-fa-1_sliding_bloc_desc-color                 : $m-fa-1_sliding_bloc_content-color !default;

$m-fa-1_sliding_bloc_icon-bg                    : transparent !default;
$m-fa-1_sliding_bloc_icon-color                 : $color-text !default;

$m-fa-1_sliding_bloc-justifyContent             : flex-end !default;
$m-fa-1_sliding_bloc_btn-bg                     : $btn-bg !default;
$m-fa-1_sliding_bloc_btn-bd-color               : $btn-border-color !default;
$m-fa-1_sliding_bloc_btn-color                  : $btn-color !default;
$m-fa-1_sliding_bloc_btn-bg--hover              : $btn-bg--hover !default;
$m-fa-1_sliding_bloc_btn-bd-color--hover        : $btn-border-color--hover !default;
$m-fa-1_sliding_bloc_btn-color--hover           : $btn-color--hover !default;


// 3 bloc (m-fa-3_blocs)
$m-fa-3_blocs_image-radius                      : $border-radius--image !default;

$m-fa-3_blocs_bloc-justifyContent               : flex-end !default;
$m-fa-3_blocs_bloc_btn-bg                       : $btn-bg !default;
$m-fa-3_blocs_bloc_btn-bd-color                 : $btn-border-color !default;
$m-fa-3_blocs_bloc_btn-color                    : $btn-color !default;
$m-fa-3_blocs_bloc_btn-bg--hover                : $btn-bg--hover !default;
$m-fa-3_blocs_bloc_btn-bd-color--hover          : $btn-border-color--hover !default;
$m-fa-3_blocs_bloc_btn-color--hover             : $btn-color--hover !default;

// 3 bloc (m-fa-3_blocs) always description
$m-fa-3_blocs_before-bg                         : linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 77.37%) !default;

$m-fa-3_blocs_with_desc_caption-bg              : transparent !default;
$m-fa-3_blocs_with_desc_caption-radius          : 0 !default;
$m-fa-3_blocs_with_desc_caption-ffamily         : $font-family--heading !default;
$m-fa-3_blocs_with_desc_caption-fweight         : $font-weight-bold !default;
$m-fa-3_blocs_with_desc_caption-fsize           : toRem(32) !default;
$m-fa-3_blocs_with_desc_caption-fsize--1200     : toRem(28) !default;
$m-fa-3_blocs_with_desc_caption-fsize--960      : toRem(24) !default;
$m-fa-3_blocs_with_desc_caption-fsize--640      : toRem(20) !default;
$m-fa-3_blocs_with_desc_caption-lineheight      : 1.1 !default;
$m-fa-3_blocs_with_desc_caption-talign          : left !default;
$m-fa-3_blocs_with_desc_caption-ttransform      : none !default;
$m-fa-3_blocs_with_desc_caption-color           : $color-white !default;
$m-fa-3_blocs_with_desc_caption-filter          : drop-shadow(0px 0px 10px #000000CC) !default;



//======================================================================================================
// Social wall
//======================================================================================================
$m-social_wall__socials_btn-bg                  : $btn-bg !default;
$m-social_wall__socials_btn-bd-color            : $btn-border-color !default;
$m-social_wall__socials_btn-color               : $btn-color !default;
$m-social_wall__socials_btn-bg--hover           : $btn-bg--hover !default;
$m-social_wall__socials_btn-bd-color--hover     : $btn-border-color--hover !default;
$m-social_wall__socials_btn-color--hover        : $btn-color--hover !default;

$m-social_wall__buttons_btn-bg                  : $btn-bg !default;
$m-social_wall__buttons_btn-bd-color            : $btn-border-color !default;
$m-social_wall__buttons_btn-color               : $btn-color !default;
$m-social_wall__buttons_btn-bg--hover           : $btn-bg--hover !default;
$m-social_wall__buttons_btn-bd-color--hover     : $btn-border-color--hover !default;
$m-social_wall__buttons_btn-color--hover        : $btn-color--hover !default;

$m-social_wall__socials_card-bg                 : $card-bg;
$m-social_wall__socials_card-color              : $card-color;
$m-social_wall__socials_card-shadow             : $card-shadow;
$m-social_wall__socials_card-bg--hover          : $card-bg--hover;
$m-social_wall__socials_card-shadow--hover      : $card-shadow--hover;
$m-social_wall__socials_card-radius             : $card-radius;
$m-social_wall__socials_card-ff-item-bar-bd-color : $color-white !default;
.jobs {

    // TOP POST
    &__intro {
        font-size: 1.25rem; // 20px
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    &__publication-date {
        margin-bottom: 5px;
        color: $color-date--single;

        svg.icon {
            fill: $color-date--single;
        }
    }

    &__limit-date {
        font-weight: $font-weight-bold;
    }

    // SINGLE    
    &__container {
        display: flex;
        margin-top: 30px;
    }

    &__informations {
        width: 370px;
    }

    &__details, &__employer {
        border-radius: $border-radius;
        background-color: $color-bg--neutral
    }

    &__details {
        padding: 20px 20px 20px 15px;
        margin-bottom: 20px;

        &__postuler {
            @extend .button;
            @extend .button--icon;
            @extend .button--primary;
            width: 100%;
            margin-bottom: 30px;
            
            svg {
                flex: none;
                @include size(26px);
                fill: $btn-color;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                svg {
                    fill: $btn-color--hover;
                }
            }
        }

        &__item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;

            & svg {
                @include size(40px);
                min-width: 40px;
                fill: $color-svg--jobs;
                margin-right: 15px;
            }

            &:last-child {
                margin: 0;
            }
        }

        &__label {
            font-size: $font-size--text-small;
            line-height: 1rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        &__value {
            font-size: $font-size--text;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }

    &__employer {
        padding: 20px;

        &__header {
            display: flex;
            padding-top: 10px;

            & img {
                height: 100%;
                margin-right: 15px;
            }
        }

        &__informations {
            margin-top: 25px;
            
            &--empty {
                margin-top: 0;
            }
        }

        &__name {
            display: flex;
            flex-direction: column;
            font-size: $font-size--3;
            font-weight: $font-weight-bold;
            line-height: 1.875rem;

            &__label {
                font-size: $font-size--text-small;
                line-height: $font-size--text;
                text-transform: uppercase;
            }
        }

        &__links {
            @include flex($direction: column, $justifyContent:  flex-start);
            gap: 20px;
            margin-top: 20px;
        }

        &__item {
            @include flex($alignItems: center);
            gap: 8px;
        }

        &__phone {
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: $color-text;
            transition: text-decoration-color $duration $timing;

            &:hover, &:focus {
                text-decoration-color: transparent;
            }
        }

        &__email, &__url {
            @include flex($alignItems: center);
            gap: 2px;
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: $color-text;
            transition: text-decoration-color $duration $timing;

            &:hover, &:focus {
                text-decoration-color: transparent;
            }

            svg {
                flex: none;
                @include size(20px);
            }
        }

        &__description {
            margin-top: 25px;

            &__label {
                display: block;
                margin-top: 25px;
                margin-bottom: 10px;
                font-size: $font-size--text-small;
                line-height: $font-size--text;
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &__flexible-content {
        width: 770px;
        margin-top: 0;
        margin-left: 30px;
    }

    // Listing
    &__post-count {
        font-size: $font-size--text-medium;
        font-weight: $font-weight-bold;
        padding-bottom: 0;
        margin-bottom: 30px;
    }
    
    &__proposition {
        margin-bottom: 30px;
    }
}

.card__jobs {
    margin-bottom: 30px;

    .card__description {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 15px;
    }

    &__employer-name, &__localisation {
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: $font-size--text-small;
    }

    &__localisation {
        @include flex($alignItems: center);
        gap: 5px;

        svg {
            flex: none;
            @include size(26px);
            fill: $color-text;
        }
    }

    .card__publication-date {
        margin: 0;
    }
}

// Filtres
.jobs__filters {

    .filter__options {
        padding-bottom: 0;
    }

    & #keywordsFilter {
        height: 68px;
        padding: 24px;
        // background-color: $color-white;
        // border-color: $color-white;
    }

    & #keywordsReset {
        right: 24px;
    }
}

// Form
#prop_jobs {

    .acf-field-group {
        .acf-label {
            display: none;
        }

        .acf-input {
            .acf-label {
                display: block;
            }
        }
    }

    .acf-field-true-false {
        display: flex;

        .acf-label {
            order: 1;
        }

        .acf-input {
            .acf-true-false {
                label {
                    margin: 0 8px 0 0;
                }
            }
        }
    }

    .acf-url {
        .acf-icon {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .jobs {
        &__flexible-content {
            width: 550px;
            margin-left: 20px;
        }
    }
    
}


// 960
@media screen and (max-width: $medium) {

    .jobs {

        &__container {
            flex-direction: column;
        }

        &__informations {
            width: 100%;
        }

        &__details {
            
            &__postuler {
                max-width: 290px;
            }
        
            &__list {
                column-count: 2;
                column-gap: 20px;
                gap: 20px;
            }
        }

        &__employer {
            margin-bottom: 20px;
        }

        &__flexible-content {
            width: 100%;
            margin: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {
    
    .jobs {
        &__details {
            &__list {
                column-count: 1;
            }
        }
    }

}
.m-fd-coverflow {

    &__container {
        @include flex($alignItems: center, $justifyContent: flex-start);
        width: 100%;
    }  

    .swiper-docs-infos {
        width: 470px;

        .swiper {
            margin: $m-fd-coverflow-swiper-margin;
            padding: $m-fd-coverflow-swiper-padding;
        }

        .swiper-slide {
            display: flex;
            opacity: 0 !important;
            @include size(100%, auto);
            box-sizing: border-box;

            &-active {
                opacity: 1 !important;
            }
        }
    }

    .swiper-docs-image {
        width: 615px;
        overflow: hidden;

        .swiper {
            @include size(245px, 345px);
            overflow: visible !important;
            margin: 0 auto 0 0 !important;
    
            img {
                object-fit: cover;
                @include size(100%);
            }
        }

        .swiper-wrapper {
            @include flex($alignItems: center);
        }

        .swiper-slide {
            position: relative;
            z-index: 0;
            @include flex($alignItems: center);
            @include size(245px !important, 345px !important);
            //box-shadow: $c_document_image-shadow;

            a {
                @include size(100%);
            }
        }
    }

    .c-docs {
        &__image {
            @include size(100%);
            filter: none;
            border-radius: 0;
        }

        &__content {
            min-height: 260px;
            padding: $m-fd-coverflow_content-padding;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &__title {
            font-size: $font-size--3;
        }
    }

    &__button {
        background-color: $c_document_button_sys-bg;
        border-color: $c_document_button_sys-bd-color;
        color: $c_document_button_sys-color;
    
        &:hover, &:focus {
            background-color: $c_document_button_sys-bg--hover;
            border-color: $c_document_button_sys-bd-color--hover;
            color: $c_document_button_sys-color--hover;
        }
    }

    .m-coverflow__nav {
        margin-left: 45px;
        flex-direction: column;
        justify-content: center;

        .nav-swiper--prev, .nav-swiper--next {
            background-color: $c_document_nav-bg;
            border-color: $c_document_nav-bd-color;

            .button__icon {
                fill: $c_document_nav-color;
            }
        
            &:hover, &:focus {
                background-color: $c_document_nav-bg--hover;
                border-color: $c_document_nav-bd-color--hover;

                .button__icon {
                    fill: $c_document_nav-color--hover;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fd-coverflow {
        .m-coverflow__nav {
            margin-left: 25px;
        }

        .swiper-docs-infos {
            width: 380px;

            .swiper {
                margin: $m-fd-coverflow-swiper-margin--1200;
                padding: $m-fd-coverflow-swiper-padding--1200;
            }
        }

        .swiper-docs-image {
            width: 495px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .m-fd-coverflow {
        .swiper-docs-infos {
            flex: 1;
            width: calc(100% - (245px + 65px));
        }

        .swiper-docs-image {
            width: 245px;

            .swiper {
                overflow: hidden !important;
            }
        }

        .c-docs {
            &__content {
                min-height: inherit;
                padding: 30px;
            }
        }

        .l-section__buttons {
            justify-content: flex-start;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fd-coverflow {

        &__container {
            flex-wrap: wrap;
            gap: 20px 15px;
        }

        .swiper-docs-infos {
            order: 3;
        }

        .swiper-docs-image {
            order: 1;
        }

        .m-coverflow__nav {
            margin-left: 0;
            order: 2;
        }

        .c-docs {
            &__image {
                margin-left: 0;
            }

            &__content {
                padding-top: 30px;
                margin-top: 0;
                border-radius: $c_document_content-radius;
            }
        }

    }


}

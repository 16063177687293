.m-fa-3_blocs {

    &--empty {
        display: none;
    }

    .m-3_blocs {
        &__buttons {
            justify-content: $m-fa-3_blocs_bloc-justifyContent;
        }

        &__button {
            background-color: $m-fa-3_blocs_bloc_btn-bg;
            border-color: $m-fa-3_blocs_bloc_btn-bd-color;
            color: $m-fa-3_blocs_bloc_btn-color;
            
            .button__icon {
                fill: $m-fa-3_blocs_bloc_btn-color;
            }

            &:hover, &:focus {
                background-color: $m-fa-3_blocs_bloc_btn-bg--hover;
                border-color: $m-fa-3_blocs_bloc_btn-bd-color--hover;
                color: $m-fa-3_blocs_bloc_btn-color--hover;
                
                .button__icon {
                    fill: $m-fa-3_blocs_bloc_btn-color--hover;
                }
            }
        }
    }


    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 20px;
    }

    &__content {
        @include flex($alignItems: stretch);
        gap: 30px;

        &__large {
            flex: 1;
        }

        &__small {
            @include flex($direction: column, $justifyContent: center);
            gap: 30px;
            min-width: 370px;
            max-width: 370px;
        }
    }
    
    &__item {
        .c-admin_area {
            &__image {   
                @include size(100%, 220px);
                border-radius: $m-fa-3_blocs_image-radius;
            }
        }

        &--large {
            grid-area: 1 / 1 / 3 / 2;

            .c-admin_area {
                &__image {   
                    @include size(100%, 470px);
                }
            }
        }
    }

    .c-admin_area {
        position: relative;

        &__figure--display_desc {
            &::before {
                content: "";
                @include absolute($top: 0, $left: 0);
                z-index: 1;
                @include size(100%);
                background: $m-fa-3_blocs_before-bg;
                pointer-events: none;
            }
        }

        &__figcaption {
            bottom: 15px;
            margin-left: 15px;
            margin-right: 15px;
            opacity: 0;
            transform: translateY(20px);
            transition: {
                property: transform, opacity;
                duration: $duration--second;
                timing-function: $timing;
            }

            &--display_desc {
                @include absolute($left: 30px, $bottom: 30px);
                z-index: 2;
                margin: 0;
                padding: 0;
                background-color: $m-fa-3_blocs_with_desc_caption-bg;
                border-radius: $m-fa-3_blocs_with_desc_caption-radius;
                font-family: $m-fa-3_blocs_with_desc_caption-ffamily;
                font-weight: $m-fa-3_blocs_with_desc_caption-fweight;
                font-size: $m-fa-3_blocs_with_desc_caption-fsize;
                line-height: $m-fa-3_blocs_with_desc_caption-lineheight;
                text-align: $m-fa-3_blocs_with_desc_caption-talign;
                text-transform: $m-fa-3_blocs_with_desc_caption-ttransform;
                color: $m-fa-3_blocs_with_desc_caption-color;
                filter: $m-fa-3_blocs_with_desc_caption-filter;
                opacity: 1;
                transform: none;
                transition: none;
            }
        }
        
        &:hover, &:focus {
            .c-admin_area__figcaption {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .c-popup_area__button--open, &__icon {
            z-index: 2;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fa-3_blocs {
        &__item {
            .c-admin_area__image {
                height: 180px;
            }

            &--large .c-admin_area__image {
                height: 380px;
            }
        }

        &__content {
            gap: 20px;

            &__small {
                gap: 20px;
                max-width: 300px;
                min-width: 300px;
            }
        }

        .c-admin_area__figcaption--display_desc {
            font-size: $m-fa-3_blocs_with_desc_caption-fsize--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fa-3_blocs {
        &__item {
            flex: 1;
        }

        &__content {
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;

            &__small {    
                flex-direction: row;
                align-items: stretch;
                justify-content: inherit;
                max-width: inherit;
                min-width: inherit;

                &--display_desc {
                    flex-wrap: wrap;

                    .m-fa-3_blocs__item {
                        flex: unset;
                        width: 100%;

                        .c-admin_area__image {
                            height: 280px;
                        }
                    }
                }
            }
        }

        .c-admin_area__figcaption {
            display: none;

            &--display_desc {
                bottom: 20px;
                left: 20px;
                right: 20px;
                display: block;
                font-size: $m-fa-3_blocs_with_desc_caption-fsize--960;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fa-3_blocs {
        &__content {
            gap: 10px;

            &__small {
                gap: 10px;

                &--display_desc {
                    .m-fa-3_blocs__item {
                        .c-admin_area__image {
                            height: 180px;
                        }
                    }
                }
            }
        }

        &__item {
            .c-admin_area__image {
                height: 100px;
            }

            &--large .c-admin_area__image {
                height: 180px;
            }
        }

        .c-admin_area__figcaption--display_desc {
            font-size: $m-fa-3_blocs_with_desc_caption-fsize--640;        
        }
    }

}
.m-fp-1_big_3_slides {

    &__content {
        @include flex($direction: column);
        gap: 30px;

        .swiper {
            margin: 0;
        }
    }
    
    &__item {
        height: auto !important; // swiper override

        &--large {
            .card {
                &__container {
                    @include flex($direction: row, $alignItems: center, $justifyContent: inherit);
                    text-align: unset;
                    border: 0;
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    overflow: inherit;
                    
                    &:hover, &:focus {                        
                        .card__content {
                            box-shadow: $card-shadow--hover; 
                        }
                    }
                }

                &__image-wrapper {
                    flex: none;
                    @include size(58%, 410px);
                    border-radius: $border-radius--image;
                }

                &__content {
                    justify-content: center;
                    align-items: flex-start;
                    gap: 10px;
                    min-height: 280px;
                    margin-left: -30px;
                    padding: 40px;
                    border: $card-border;
                    border-radius: $card-radius;
                    box-shadow: $card-shadow;
                    transition: {
                        property: background-color, box-shadow;
                        duration: $duration--second;
                        timing-function: $timing;
                    }
                }

                &__category {
                    position: unset;
                }

                &__title {
                    font-size: $font-size--3;
                    text-align: unset;
                }

                &__date {
                    top: $m-fp-1_big_3_slides_date-pos-top !important;
                    right: $m-fp-1_big_3_slides_date-pos-right !important;
                    bottom: $m-fp-1_big_3_slides_date-pos-bot !important;
                    left: $m-fp-1_big_3_slides_date-pos-left !important;
                    width: $m-fp-1_big_3_slides_date-pos-width !important;
                }        
            }

            &:not(:has(.card__description)) {
                .card__title {
                    font-size: $font-size--2;
                }

                .card__content--has-category {
                    .card__title {
                        font-size: $font-size--3;
                    }
                }
            }
        }
    }

    &__footer {
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    .m-1_big_3_slides__nav {
        justify-content: flex-start;
    }

    .m-1_big_3_slides {
        &__nav .nav-swiper--prev, &__nav .nav-swiper--next {
            background-color: $m-fp-1_big_3_slides_nav_btn-bg;
            border-color: $m-fp-1_big_3_slides_nav_btn-bd-color;

            .button__icon {
                fill: $m-fp-1_big_3_slides_nav_btn-color;
            }
        
            &:hover, &:focus {
                background-color: $m-fp-1_big_3_slides_nav_btn-bg--hover;
                border-color: $m-fp-1_big_3_slides_nav_btn-bd-color--hover;

                .button__icon {
                    fill: $m-fp-1_big_3_slides_nav_btn-color--hover;
                }
            }
        }

        &__button {
            background-color: $m-fp-1_big_3_slides_btn-bg;
            border-color: $m-fp-1_big_3_slides_btn-bd-color;
            color: $m-fp-1_big_3_slides_btn-color;
        
            &:hover, &:focus {
                background-color: $m-fp-1_big_3_slides_btn-bg--hover;
                border-color: $m-fp-1_big_3_slides_btn-bd-color--hover;
                color: $m-fp-1_big_3_slides_btn-color--hover;
            }
    
            &--prop {
                background-color: $m-fp-1_big_3_slides_btn_prop-bg;
                border-color: $m-fp-1_big_3_slides_btn_prop-bd-color;
                color: $m-fp-1_big_3_slides_btn_prop-color;
            
                &:hover, &:focus {
                    background-color: $m-fp-1_big_3_slides_btn_prop-bg--hover;
                    border-color: $m-fp-1_big_3_slides_btn_prop-bd-color--hover;
                    color: $m-fp-1_big_3_slides_btn_prop-color--hover;
                }
         
            }
        }
    }
    
    .swiper {
        margin: $swiper-margin;
        padding: $swiper-padding;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fp-1_big_3_slides {
        &__item--large {
            .card {
                &__image-wrapper {
                    height: 330px;
                }

                &__content {
                    padding: 30px;
                }
            }
        }

        .swiper {
            margin: $swiper-margin--1200;
            padding: $swiper-padding--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fp-1_big_3_slides {
        &__item--large {
            .card {
                &__image-wrapper {
                    @include size(100%, 360px);
                }

                &__content {
                    min-height: inherit;
                    margin: -30px 30px 0 30px;
                    padding: 30px;
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fp-1_big_3_slides {

        &__content {
            gap: 20px;
        }

        &__item--large {
            .card {
                &__image-wrapper {
                    height: 250px;
                }

                &__content {
                    margin: -30px 0 0 0;
                    padding: 20px;
                }
            }
        }

        &__footer {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;
        }
    }

}
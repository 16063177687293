.card {
    width: 100%;
    margin-bottom: 15px;

    &--v2, &--square {
        .card {
            &__container {
                position: relative;
                @include flex($direction: column, $wrap: wrap);
                align-content: flex-start;
                height: 100%;
            }

            &__content {
                position: relative;
                @include flex($direction: column, $justifyContent: flex-start, $alignItems: $card_content-alignItems);  
                height: 100%;
                flex: 1;
                padding: $card_content-padding--square;

                &:has(.card__category) {
                    padding-top: $card_content_has-category-padding-top;
                }
            }

            &__title {
                text-align: $card_title-talign--square;
            }
        
            &__container {
                text-align: $card-talign--square;
            }
        }
    }

    &--square {
        .card {
            &__infos {
                position: $taxo--square-pos;
                top: $taxo--square-pos-top;
                right: $taxo--square-pos-right;
                bottom: $taxo--square-pos-bottom;
                left: $taxo--square-pos-left;
                width: $taxo--square-width;
                align-items: $taxo--square-alignItems;
            }
        }
    }

    // Only for card home builder
    &--v1 {
        .card {
            &__container, &__content {
                height: 100%;
            }

            &__content {
                flex-direction: row;
                gap: $card-event-v1-gap;
                justify-content: flex-start;
                padding: $card-event-v1-content-padding;
            }

            &__infos {
                justify-content: center;
                padding: $card-event-v1-infos-padding;
            }
        }
    }
    
    &--v2 {
        .card {
            &__content {
                gap: $card_content-gap;

                &:not(:has(.card__description)) {
                    justify-content: $card_content-justifyContent;
                }
            }

            &__category {
                position: $taxo--square-pos;
                top: $taxo--square-pos-top;
                right: $taxo--square-pos-right;
                bottom: $taxo--square-pos-bottom;
                left: $taxo--square-pos-left;
                align-items: $taxo--square-alignItems;
            }
        }
    }

    &__link {
        display: block;
        height: 100%;
    }

    &__container {
        box-sizing: border-box;
        padding: $card-padding;
        background-color: $card-bg;
        border: $card-border;
        border-radius: $card-radius;
        color: $card-color;
        overflow: hidden;
        box-shadow: $card-shadow;
        transition: {
            property: background-color, box-shadow;
            duration: $duration--second;
            timing-function: $timing;
        }

        &:hover, &:focus {
            background-color: $card-bg--hover;
            box-shadow: $card-shadow--hover;

            .card__content {
                background-color: $card-bg--hover;   
            }
        }

        &--search {
            .card__head-content {
                @include flex($direction: column-reverse, $alignItems: flex-start, $justifyContent: center);
                gap: 15px;
            }
        }
    }

    &__image-wrapper {
        position: relative;
        @include flex($justifyContent: center, $alignItems: center);
        @include size(100%, 230px);
        min-height: unset;
        background-color: $color-bg--image;
        overflow: hidden; 
        border-radius: $card_image-radius;

        picture, img {
            @include size(100%);
            object-fit: cover;
            //object-position: top;
        }

        img {
            transition: transform $duration--second $timing;
        }
    }

    &__link {
        &:hover, &:focus {
            .card__image-wrapper img {
                transform: scale(1.03);
            }
        }
    }

    &__content {
        @include flex($direction: column, $justifyContent: center);
        gap: 10px;  
        flex: 1;
        padding: $card_content-padding;
        background-color: $card-bg;
        transition: background-color $duration--second $timing;
    }

    &__category {
        @include flex($alignItems: center, $justifyContent: center);
        width: fit-content;
        padding: $taxo-padding;
        background-color: $taxo-bg;
        border-top-width: $taxo-border-top-width;
        border-right-width: $taxo-border-right-width;
        border-bottom-width: $taxo-border-bottom-width;
        border-left-width: $taxo-border-left-width;
        border-style: $taxo-border-style;
        border-color: $taxo-border-color;
        border-radius: $taxo-border-radius;
        font-family: $taxo-font-family;
        font-size: $taxo-font-size;
        font-weight: $taxo-font-weight;
        line-height: $taxo-line-height;
        text-transform: $taxo-text-transform;
        color: $taxo-color;
    }

    &__infos {
        @include flex($direction: column, $justifyContent: flex-start);
        gap: 10px;
    }

    &__title {
        margin: 0;
        font-family: $card_title-ffamily;
        font-weight: $card_title-fweight;
        font-size: $card_title-fsize;
        line-height: $card_title-lineheight;
        color: $card_title-color;
    }

    &__description {
        color: $card-color;
    }

    &__publication-date {
        @include flex($alignItems: center);
        gap: 5px;
        color: $card-color--second;
    
        svg {
            flex: none;
            @include size(26px);
            fill: $card-color--second;
        }
    }

    .a-subinfo {
        color: $card-color--second;
    
        .icon {
            fill: $card-color--second;
        }
    }

    // &--v2.swiper-slide {
    //     .card__container {
    //         box-shadow: none;
    //     }
    // }


    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    &__image-wrapper {
        background-repeat: no-repeat;
        background-position: center;

        &.post,
        &.category {
            background-image: url($urlShort + '/posts-icons/post.svg');
        }

        &.page,
        &.transitions, 
        &.pages-systeme {
            background-image: url($urlShort + '/pages-icons/icon-page.svg');
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .card {
        &__image-wrapper {
            height: 215px;
        }
    }

}


// // 960
// @media screen and (max-width: $medium) {


// }


// 640
@media screen and (max-width: $small) {

    .card {
        &__content {
            flex-direction: column;
        }

        // Only for card home builder
        &--v1 {
            .card {
                &__content {
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

}

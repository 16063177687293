.c-custom_menu {
    &__list {
        position: relative;
        z-index: 1;
        @include flex($direction: column, $justifyContent: center);
        gap: 10px;
    }

    &__link {
        @include flex($alignItems: center, $justifyContent: space-between);
    }
}
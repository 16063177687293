.header {
    position: relative;

    &__wrapper {
        @include fixed($top: 0, $left: 0, $right: 0);
        z-index: 10;
        @include flex($alignItems: center, $justifyContent: center);
        height: $header-height;
        padding: $header-padding;
        background-color: $header-bg;
        border: $header-border;
        box-shadow: $header-shadow;
        transition: all $duration--second $timing;
    }

    &__container {
        width: 100%;
    }

    &__content {
        // position: relative;
        // z-index: 10;
        @include flex($alignItems: $header_content-alignItems, $justifyContent: space-between);
    }

    &__nav {
        @include flex($alignItems: center, $justifyContent: flex-end);
        gap: $header_nav-gap;
        margin-left: auto;
    }

    // Slideshow/TopPost full under top
    &--full_screen_under_menu {
        .header__wrapper {
            background: $header_m_full_under_top-bg;
        }

        .identity .logo-creaville path {
            fill: $header_m_full_under_top_svg-color;
        }

        .nav-main--simple .menu__link {
            color: $header_m_full_under_top_link-color;
        }

        .identity,
        .nav-main--simple .menu__link,
        .tools {
            filter: $header_m_full_under_top-filter-shadow;
            transition: filter $duration $timing;
        }

        .nav-main--burger .nav-button__menu-open {
            filter: $header_m_full_under_top-filter-shadow;

            transition: {
                property: background-color, border-color, color, filter;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }
}

.admin-bar .header__wrapper {
    top: calc(32px);
}


//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);

    &__title {
        margin: 0;
        font-size: 0;
        line-height: 1;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    &__container {
        @include flex($alignItems: center);
        gap: 10px;
    }

    &__item {
        background-color: $tool-bg;
        border-color: $tool-border-color;

        .button__icon {
            fill: $tool-color;
        }

        &:hover,
        &:focus {
            background-color: $tool-bg--hover;
            border-color: $tool-border-color--hover;

            .button__icon {
                fill: $tool-color--hover;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }

    }
}


//======================================================================================================
// Header fixed
//======================================================================================================
.header--fixed {
    .header {
        &__wrapper {
            height: $header-height--fixed;
            padding: $header-padding--fixed;
            background-color: $header-bg--fixed;
            border: $header-border--fixed;
            box-shadow: $header-shadow--fixed;
        }

        &__content {
            align-items: $header_content-alignItems--fixed;
        }
    }

    &.header--full_screen_under_menu {
        .header {
            &__wrapper {
                background: $header_m_full_under_top-bg--fixed;
            }
        }

        .identity .logo-creaville path {
            fill: $header_m_full_under_top_svg-color--fixed;
        }

        .nav-main--simple .menu__link {
            color: $header_m_full_under_top_link-color--fixed;
        }

        .identity,
        .nav-main--burger .nav-button__menu-open,
        .nav-main--simple .menu__link,
        .tools {
            filter: $header_m_full_under_top-filter-shadow--fixed;
        }
    }

    .tools__item {
        background-color: $tool-bg--fixed;
        border-color: $tool-border-color--fixed;

        svg {
            fill: $tool-color--fixed;
        }

        &:hover,
        &:focus {
            background-color: $tool-bg--fixed--hover;
            border-color: $tool-border-color--fixed--hover;

            svg {
                fill: $tool-color--fixed--hover;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header {
        &__nav {
            gap: $header_nav-gap--1200;
        }
    }

    .identity .logo-creaville {
        @include size(188px, 57px);
    }
}


// 960
@media screen and (max-width: $medium) {

    .header {
        &__nav {
            gap: $header_nav-gap--960;
        }

        &__wrapper {
            @include relative($top: unset);
            height: $header-height--960;
        }

        &--full_screen_under_menu {
            .header__wrapper {
                position: absolute;
            }

            .nav-main--simple .menu__link {
                color: $color-text--main;
            }

            .identity .logo-creaville path {
                fill: $header_m_full_under_top_svg-color--mobile;
            }
        }
    }

    .admin-bar .header__wrapper {
        top: unset;
    }

}


// 640
@media screen and (max-width: $small) {

    .header {
        &__wrapper {
            height: $header-height--640;
        }

        &__content {
            justify-content: center;
        }

        &__nav-mobile {
            @include fixed($bottom: 0, $left: 0, $right: 0);
            justify-content: space-between;
            @include size(100%, $header--fixed-height--640);
            padding: 10px calc(5% + 10px);
            background-color: $header-bg--fixed;
            box-shadow: $header-shadow--fixed;
        }

        &--full_screen_under_menu {
            .header {
                &__wrapper {
                    background: $header_m_full_under_top-bg--mobile;
                }

                &__nav-mobile {
                    background-color: $header_m_full_under_top-bg--fixed;
                }
            }

            .identity,
            .nav-main--burger .nav-button__menu-open,
            .tools {
                filter: $header_m_full_under_top-filter-shadow--fixed;
            }

        }

        .tools__item {    
            &:hover,
            &:focus {
                background-color: $tool-bg;
                border-color: $tool-border-color;
        
                svg {
                    fill: $tool-color;
                }
            }
        }
    }

}
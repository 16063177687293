.m-fs-full_screen {
    position: relative;
    
    &__container {
        @include absolute(0, 0, 0, 0);
        pointer-events: none;
    }

    &--with-searchbar {
        .m-fs-full_screen__container--pag {
            display: flex;
            justify-content: center;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .m-fs-full_screen {

        &--with-description {
            .m-full_screen__nav {
                bottom: $m-slideshow_nav_bottom--640--desc;
                top: $m-slideshow_nav_top--640--desc;
            } 

            .m-full_screen__pag {
                bottom: $m-slideshow_pag_bottom--640--desc;
                top: $m-slideshow_pag_top--640--desc;
            } 
        }
    } 

}

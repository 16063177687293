.card--event, .transition-publications {
    .card__container--event-v1 {
        .card {
            &__content {
                flex-direction: row;
                gap: $card-event-v1-gap;
                padding: $card-event-v1-content-padding;
            }

            &__infos {
                flex: 1;
                justify-content: center;
                padding: $card-event-v1-infos-padding;
            }
        } 
    }
}

.card {
    &--v2, &--square {
        .card__date {
            @include absolute($top: $card_date-pos-top, $right: $card_date-pos-right, $bottom: $card_date-pos-bottom, $left: $card_date-pos-left);      
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    &__image-wrapper {
        &.events,
        &.events_category {
            background-image: url($urlSpriteImage + '/events-icons/events.svg');
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .card--event, .transition-publications {
        .card__container--event-v1 {
            .card__content {
                flex-direction: column;
            }
            .card__infos {
                justify-content: flex-start;
            }
        }
    }

}


// //======================================================================================================
// // Responsive
// //======================================================================================================


// // 960
// @media screen and (max-width: $medium) {

//     .card {
//         &:not(.card--square):not(.card--category):not(.card--portraits) {

//             .card__image-wrapper:not(.events) {
//                 display: none;
//             }

//             .card__image-wrapper.events {
//                 background: none;
//                 width: auto;

//                 img {
//                     display: none;
//                 }
//             }

//             .card__date {
//                 position: relative;

//                 p {
//                     width: 85px;
//                 }
//             }

//         }

//         &__container--event-v1 {
//             .card__date {
//                 padding: 20px 5px;
//             }
//         }
//     }

// }


// // 640
// @media screen and (max-width: $small) {

//     .card  {
//         &:not(.card--square):not(.card--category):not(.card--portraits) {

//             .card__image-wrapper.events {
//                 background: none;
//                 width: 100%;
//                 height: 70px;
//                 margin: 0 0 20px 0;
//             }

//             .card__date {
//                 position: relative;
//                 flex-direction: row;
//                 align-items: center;
//                 justify-content: center;
//                 width: 100%;
//                 padding: 0;

//                 &__many {
//                     flex-direction: row;
//                     justify-content: center;
//                     align-items: center;
//                 }

//                 p {
//                     width: auto;
//                     padding: 15px;
//                 }

//                 svg {
//                     transform: rotate(-90deg);
//                 }
//             }
//         }

//         &__container--event-v1 {
//             height: auto;
//         }
//     }

// }

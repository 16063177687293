.card {

    &__container {
        &--consultation-v1 {
            display: flex;
            min-height: 180px;
            gap: 20px;
            padding: 20px;

            .card__content {
                padding: 0;
            }
        }
    }

    &-vote {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        padding: $card_vote--vertical-padding;
        background-color: $card_vote-bg-v1;
        border-radius: $card_vote-radius;
        font-family: $card_vote-ffamily;
        font-weight: $card_vote-fweight;
        font-size: $card_vote-fsize;
        text-transform: $card_vote-ttransform;
        color: $card_vote-color; // like c-date
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.consultations {
            background-image: url($urlSpriteImage + '/consultation-icons/consultations.svg');
        }
    }

    &--square {

        .card {
            &-vote {
                @include absolute($top: $card_vote-pos-top, $right: $card_vote-pos-right, $bottom: $card_vote-pos-bottom, $left: $card_vote-pos-left);
                z-index: 1;
                @include flex($direction: row, $alignItems: center);
                column-gap: 5px;
                width: $card_vote-width;
                height: $card_vote-height;
                padding: $card_vote-padding;
                background-color: $card_vote-bg-v2; // like c-date
                border-radius: $card_vote-radius;
                line-height: $card_vote-lineheight;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {
    
    .card__container--consultation-v1 {
        flex-direction: column;

        .card-vote {
            flex-direction: row;
            column-gap: 5px;
            height: $card_vote-height;
            width: $card_vote-width;
            padding: $card_vote-padding;
            line-height: $card_vote-lineheight;
        }
    }

}

//======================================================================================================
// Init
//======================================================================================================
$theme_name                                     : 'creaville' !default;


//======================================================================================================
// Breakpoints
//======================================================================================================
$large                                          : 1200px;
$medium                                         : 960px;
$small                                          : 640px;
$x-small                                        : 480px;


//======================================================================================================
// Grids
//======================================================================================================
$gl-colCount                                    : 12;
$gl-gridName                                    : grid;
$gl-colName                                     : col;
$gl-attributeName                               : class;
$gl-gutter                                      : 30px;
$gl-gutter-vertical                             : 30px;

//Gutter responsive medium
$gl-gutter-md                                   : 20px;
$gl-gutter-md-vertical                          : 20px;

//Gutter responsive small
$gl-gutter-sm                                   : 20px;
$gl-gutter-sm-vertical                          : 20px;

//Gutter responsive x-small
$gl-gutter-xs                                   : 20px;
$gl-gutter-xs-vertical                          : 20px;

$gl-mq-width                                    : 'max-width';
$gl-mq-list     : (
lg      : $large,
md      : $medium,
sm      : $small,
xs      : $x-small,
);


//======================================================================================================
// Global Colors
//======================================================================================================

// Colors
$red                                            : #C92C2C;
$redlight                                       : #FFE6E6;
$orange                                         : #E8810B;
$orangelight                                    : #fae6ce;
$green                                          : #46B450;
$greenlight                                     : #E2FFEF;

// Black & White
$color-white                                    : #FFFFFF;
$color-light                                    : #E9EDF2 !default;
$color-gray                                     : #586672 !default;
$color-dark                                     : #000000 !default;
$color-black                                    : #000000;

// Main Colors
$color-main                                     : #232635 !default;
$color-second                                   : #414456 !default;
$color-third                                    : $color-light !default;
$color-fourth                                   : $color-gray !default;

$color-card--hover                              : #DADFE6 !default;

// RGBA
$color-main--rgba                               : rgba($color-main, 0.9) !default;
$color-second--rgba                             : rgba($color-second, 0.9) !default;
$color-third--rgba                              : rgba($color-third, 0.9) !default;
$color-white--rgba                              : rgba($color-white, 0.9) !default;
$color-black--rgba                              : rgba($color-black, 0.9) !default;

// Bloc infos
$color-error                                    : $red;
$color-alert                                    : $orange;
$color-success                                  : $green;
$color-bg-error                                 : $redlight;
$color-bg-alert                                 : $orangelight;
$color-bg-success                               : $greenlight;
$color-flexible-alert                           : #E20000; // This color is only used on bloc-content--alert

// Text
$color-text                                     : $color-dark !default;
$color-text--light                              : $color-gray !default;
$color-text--main                               : $color-white !default; // Color text on color main
$color-link                                     : $color-second !default;
$color-link--hover                              : $color-text !default;
$color-highlight                                : #FFEEA8;

// Background
$color-bg--neutral                              : $color-light !default;
$color-bg--body                                 : $color-white !default;
$color-bg--image                                : $color-gray !default;

// SVG
$color-svg                                      : $color-main !default;
$color-svg--events                              : $color-svg !default; // single events
$color-svg--maps                                : $color-svg !default; // popup maps
$color-svg--jobs                                : $color-svg !default; // single jobs

// Single Date publication (post & jobs & adverts)
$color-date--single                             : $color-text--light !default;


//======================================================================================================
// Typography
//======================================================================================================

// Font Weight
$font-weight-thin                               : 200;
$font-weight-light                              : 300;
$font-weight-normal                             : 400;
$font-weight-medium                             : 500;
$font-weight-semibold                           : 600;
$font-weight-bold                               : 700;
$font-weight-extrabold                          : 800;
$font-weight-black                              : 900;

// Body
$font-size                                      : 16px;
$font-style                                     : normal;
$font-variant                                   : normal;
$font-family                                    : "Lato", sans-serif !default;
$font-weight                                    : $font-weight-normal;
$line-height                                    : 1.5;

// Headings
$font-family--heading                           : "Figtree", sans-serif !default;
$font-weight--heading                           : $font-weight-bold !default;
$line-height--heading                           : 1.2 !default;
$font-size--1                                   : toRem(50) !default;
$font-size--2                                   : toRem(34) !default;
$font-size--3                                   : toRem(28) !default;
$font-size--4                                   : toRem(22) !default;
$font-size--5                                   : toRem(18) !default;
$font-size--6                                   : toRem(16) !default;

// Font Size
$font-size--text-medium                         : toRem(20) !default;
$font-size--text                                : toRem(16) !default;
$font-size--text-small                          : toRem(14) !default;
$font-size--text-xsmall                         : toRem(13) !default;


//======================================================================================================
// Borders
//======================================================================================================

// Borders
$border-width                                   : 1px !default;
$border-style                                   : solid !default;
$border-color                                   : $color-dark !default;

// Borders radius
$border-radius                                  : 20px !default;
$border-radius--second                          : 15px !default;
$border-radius--input                           : 5px !default;
$border-radius--button                          : 50px !default;
$border-radius--icon                            : 50% !default;
$border-radius--image                           : 20px !default;


//======================================================================================================
// Box-shadow
//======================================================================================================
$shadow                                         : none !default;
$shadow--hover                                  : none !default;


//======================================================================================================
// Transitions
//======================================================================================================
$duration                                       : .25s !default;
$duration--second                               : .50s !default;
$timing                                         : ease !default;
$delay                                          : .50s !default;


//======================================================================================================
// Image URL
//======================================================================================================
$urlImage                                       : '/wp-content/themes/#{$theme_name}/assets/src/images/';
// Need for no-image icon plugins       :
$urlSpriteImage                                 : '/wp-content/themes/#{$theme_name}/assets/src/images/sprite-images/';
$urlThemeShort                                  : '/wp-content/themes/#{$theme_name}/assets/src/images/sprite-images/theme-icons';
// Need for no-image icon socle + post-navigation       :
$urlShort                                       : '/wp-content/plugins/creasit-builder/assets/src/images/sprite-images/base-icons/';


//======================================================================================================
// Buttons
//======================================================================================================

// Font
$btn-font-family                                : $font-family !default;
$btn-font-weight                                : $font-weight-bold !default;
$btn-font-size                                  : $font-size--text-small !default;
$btn-line-height                                : 1.2 !default;
$btn-text-transform                             : uppercase !default;

// Style
$btn-height                                     : 45px !default;
$btn-padding                                    : 8px 25px !default;
$btn-padding--640                               : 10px 15px !default;
$btn-border-width                               : $border-width !default;
$btn-border-style                               : $border-style !default;
$btn-border-radius                              : $border-radius--button !default;

// Normal
$btn-bg                                         : $color-main !default;
$btn-border-color                               : $btn-bg !default;
$btn-color                                      : $color-text--main !default;

// Hover/Focus
$btn-bg--hover                                  : transparent !default;
$btn-border-color--hover                        : $btn-border-color !default;
$btn-color--hover                               : $color-text !default;


    //===============================================
    // Button secondary
    //===============================================

    // Normal
    $btn-second-bg                                  : $color-light !default;
    $btn-second-border-color                        : $btn-second-bg !default;
    $btn-second-color                               : $color-text !default;
    
    // Hover/Focus
    $btn-second-bg--hover                           : transparent !default;
    $btn-second-border-color--hover                 : $color-text !default;
    $btn-second-color--hover                        : $color-text !default;


    //===============================================
    // Button empty
    //===============================================

    // Normal
    $btn-empty-bg                                   : transparent !default;
    $btn-empty-border-color                         : $color-dark !default;
    $btn-empty-color                                : $color-text !default;
    
    // Hover/Focus
    $btn-empty-bg--hover                            : $color-dark !default;
    $btn-empty-border-color--hover                  : $btn-empty-border-color !default;
    $btn-empty-color--hover                         : $color-white !default;


    //===============================================
    // Button tag (button bottom page)
    //===============================================

    // Style
    $btn-tag-height                                 : 40px !default;
    $btn-tag-padding                                : 10px 20px !default;
    $btn-tag-border-width                           : $border-width !default;

    // Normal
    $btn-tag-bg                                     : $color-main !default;
    $btn-tag-border-color                           : $btn-tag-bg !default;
    $btn-tag-color                                  : $color-text--main !default;
    
    // Hover/Focus
    $btn-tag-bg--hover                              : transparent !default;
    $btn-tag-border-color--hover                    : $btn-tag-border-color !default;
    $btn-tag-color--hover                           : $color-text !default;


//===============================================
// Button document-details button (and contact details)
//===============================================

// Style
$btn_action_icon-size                           : 30px !default; // Width & height
$btn_action_icon-border-width                   : 0 !default;
$btn_action_icon-border-style                   : $border-style !default;
$btn_action_icon-border-radius                  : $border-radius--icon !default;

// Normal
$btn_action-ffamily                             : $font-family !default;
$btn_action-fweight                             : $font-weight !default;
$btn_action-fsize                               : $font-size--text !default;
$btn_action-color                               : $color-text !default;
$btn_action_icon-color                          : $color-text !default;
$btn_action_icon-bg                             : $color-white !default;
$btn_action_icon-border-color                   : $btn_action_icon-bg !default;

// Hover/Focus
$btn_action-color--hover                        : $color-text !default;
$btn_action_icon-color--hover                   : $color-white !default;
$btn_action_icon-bg--hover                      : $color-second !default;
$btn_action_icon-border-color--hover            : $btn_action_icon-bg--hover !default;


//======================================================================================================
// Forms
//======================================================================================================
$forms                                          : ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$input-bg                                       : transparent;
$placeholder                                    : darken($color-main, 20%);
$form-border-color                              : $color-text--light;
$form-border-color--focus                       : $color-dark;
$form-border                                    : $border-width $border-style $form-border-color;
$form-border-radius                             : $border-radius--input;
$form-color-icon                                : $color-text--light; // Couleur des icones dans les formulaires proposition events
$form-color-iconr--focus                        : $color-dark;


//======================================================================================================
// Search input (for popup, search page and 404)
//======================================================================================================
$search_input-height                            : 70px;
$search_input-bg                                : $color-white;
$search_input-border-radius                     : $border-radius;
$search_input-border-color                      : $color-dark;
// 404 border color
$search_input-border-color--404                 : $color-dark;

// button search loop
$search_input-button-size                       : 30px;
$search_input-button-border-radius              : $border-radius--icon;
$search_input-button-border                     : $border-width $border-style $color-main;
$search_input-button-bg                         : $color-main;
$search_input-button-color                      : $color-text--main;

// Active / focus
$search_input-border-color--active              : $color-main;
// button search loop
$search_input-button-bg--active                 : transparent;
$search_input-button-color--active              : $color-text;
$search_input-button-border-color--focus        : $form-border-color--focus;

// Search Results
$search_results-bg                              : $color-white;
$search_results-radius                          : 0 0 $border-radius $border-radius;
$search_results-border                          : $border-width $border-style $color-light;
$search_results-color                           : $color-text;
$search_results-color--light                    : $color-text--light;
// Active
$search_results-bg--hover                       : $color-main;
$search_results-border--hover                   : $color-main;
$search_results-color--hover                    : $color-text--main;
$search_results-color--light--hover             : $color-text--main;

// Popup
$search_popup-bg                                : $color-bg--neutral;
$search_popup-color                             : $color-text;



//======================================================================================================
// Tables
//======================================================================================================
$stripes                                        : #f8f8f8;
$caption                                        : #ababab;

// Flexible table
$table-bg                                       : $color-white;
$table-color                                    : $color-text;
$table-border-width                             : 1px;
$table-border-color                             : $color-dark;
$table-even-bg                                  : $color-white; // Pair background-color
$table-even-color                               : $color-text; // Pair color
$table-odd-bg                                   : $color-light; // Impair background-color
$table-odd-color                                : $color-text; // Impair color
$table-head-bg                                  : $color-dark;
$table-head-color                               : $color-white;
$table-head-border-color                        : $color-white;


//======================================================================================================
// Code
//======================================================================================================
$code-color                                     : $color-text;
$code-size                                      : $font-size--text-small;
$code-family                                    : Menlo, monospace;
$code-bg                                        : transparent;


//======================================================================================================
// Card
//======================================================================================================
$card-padding                                   : 0 !default; // padding total card
$card-bg                                        : $color-bg--neutral !default;
$card-bg--second                                : $color-bg--neutral !default;
$card-bg--hover                                 : $color-card--hover !default;
$card-border                                    : 0 !default;
$card-radius                                    : $border-radius !default;
$card-shadow                                    : $shadow !default;
$card-shadow--hover                             : $shadow--hover !default;
$card-color                                     : $color-text !default;
$card-color--second                             : $color-text--light !default; // Date publication & location card
$card-talign--square                            : unset !default;

$card_content-padding                           : 20px !default; // if $card-padding > 0        : 0
$card_content-padding--square                   : 20px !default; // if $card-padding > 0        : 20px 0 0 0
$card_content_has-category-padding-top          : 25px !default; // has .card__category
$card_content-alignItems                        : flex-start !default;

// Only card--v2
$card_content-gap                               : 10px !default;
// Only card--v2 & not description
$card_content-justifyContent                    : center !default;

$card-event-v1-content-padding                  : 20px !default; // if $card-padding > 0        : 0
$card-event-v1-infos-padding                    : 0 !default; // if $card-padding > 0       : 0
$card-event-v1-gap                              : 20px !default; // if $card-padding > 0        : 20px

$card-f-chronology_image-height                 : 200px !default; // if $card-padding > 0       : 180px
$card-f-chronology_image-height--640            : 160px !default; // if $card-padding > 0       : 140px

// if padding total & border-radius image
$card_image-radius                              : 0 !default;

$card_date-pos-top                              : 20px !default;
$card_date-pos-right                            : unset !default;
$card_date-pos-bottom                           : unset !default;
$card_date-pos-left                             : 20px !default;

$card_date-width                                : fit-content !default;
$card_date-height                               : 45px !default;
$card_date-justifyContent                       : center !default;
$card_date-padding                              : 9px 20px !default;
$card_date-bg-v1                                : $color-main !default;
$card_date-bg-v2                                : $color-main--rgba !default;
$card_date-radius                               : $border-radius--second !default;
$card_date-ffamily                              : $font-family--heading !default;
$card_date-fweight                              : $font-weight-semibold !default;
$card_date-fsize                                : $font-size--5 !default;
$card_date-lineheight                           : 1 !default;
$card_date-ttransform                           : uppercase !default;
$card_date-color                                : $color-text--main !default;

$card_date--vertical-width                      : 65px !default;
$card_date--vertical-width--640                 : 100% !default;
$card_date--vertical-height                     : unset !default;
$card_date--vertical-height--640                : 45px !default;
$card_date--vertical-padding                    : 20px 10px !default;
$card_date--vertical-padding--640               : 10px 20px !default;
$card_date--vertical-radius                     : $card_date-radius !default;
$card_date--vertical-justifyContent--640        : center !default;

$card_title-ffamily                             : $font-family--heading !default;
$card_title-fweight                             : $font-weight--heading !default;
$card_title-fsize                               : $font-size--4 !default;
$card_title-lineheight                          : $line-height--heading !default;
$card_title-talign--square                      : $card-talign--square !default;
$card_title-color                               : $card-color !default;


// Document
$card_docs_img-bd-color                         : transparent !default;


// Consultations plugins / vote
$card_vote-pos-top                              : $card_date-pos-top !default;
$card_vote-pos-right                            : $card_date-pos-right !default;
$card_vote-pos-bottom                           : $card_date-pos-bottom !default;
$card_vote-pos-left                             : $card_date-pos-left !default;

$card_vote-width                                : $card_date-width !default;
$card_vote-height                               : $card_date-height !default;
$card_vote-padding                              : $card_date-padding !default;
$card_vote-bg-v1                                : $card_date-bg-v1 !default;
$card_vote-bg-v2                                : $card_date-bg-v2 !default;
$card_vote-radius                               : $card_date-radius !default;
$card_vote-ffamily                              : $card_date-ffamily !default;
$card_vote-fweight                              : $card_date-fweight !default;
$card_vote-fsize                                : $card_date-fsize !default;
$card_vote-lineheight                           : $card_date-lineheight !default;
$card_vote-ttransform                           : $card_date-ttransform !default;
$card_vote-color                                : $card_date-color !default;

$card_vote--vertical-padding                    : 15px !default;

//======================================================================================================
// Taxo (card + document-details + reports)
//======================================================================================================

$taxo--square-pos                               : absolute !default;
$taxo--square-pos-top                           : -15px !default;
$taxo--square-pos-right                         : unset !default;
$taxo--square-pos-bottom                        : unset !default;
$taxo--square-pos-left                          : unset !default;
$taxo--square-width                             : fit-content !default;
$taxo--square-alignItems                        : flex-start !default;

// Font
$taxo-font-family                               : $font-family !default;
$taxo-font-size                                 : toRem(14) !default;
$taxo-font-weight                               : $font-weight-bold !default;
$taxo-line-height                               : 1.5 !default;
$taxo-text-transform                            : uppercase !default;

// Color
$taxo-color                                     : $color-text !default;

// Background
$taxo-bg                                        : $color-white !default;

// Borders
$taxo-border-style                              : $border-style;
$taxo-border-color                              : transparent !default;
$taxo-border-top-width                          : 0 !default;
$taxo-border-right-width                        : 0 !default;
$taxo-border-bottom-width                       : 0 !default;
$taxo-border-left-width                         : 0 !default;
$taxo-border-radius                             : $border-radius !default;

// Padding
$taxo-padding                                   : 5px 15px !default;



//======================================================================================================
// Image Caption
//======================================================================================================

$border-radius-caption                          : $border-radius--second;

// Background Caption
$color-bg-caption                               : $color-main--rgba;

// Text Caption
$color-caption                                  : $color-text--main;



//======================================================================================================
// Flexibles - bloc content background
//======================================================================================================
// Fexible bloc Primary
$b-primary-bg                                   : $color-main !default; // For flexible bloc content border & background
$b-primary-color                                : $color-text--main !default;

// Fexible bloc Secondary
$b-secondary-bg                                 : $color-second !default; // For flexible bloc content border & background
$b-secondary-color                              : $color-white !default;

// Fexible bloc Neutral
$b-neutral-bg                                   : $color-bg--neutral !default; // For flexible bloc content border & background
$b-neutral-color                                : $color-text !default;



//======================================================================================================
// Flexibles - bloc paragraph bullet
//======================================================================================================
// Bullet list first
$bullet-primary-color                           : $color-main !default;

// Bullet list second
$bullet-secondary-color                         : $color-second !default;

// Bullet list third
$bullet-neutral-color                           : $color-dark !default;



//======================================================================================================
// Flexibles - Albums - Swiper + Glightbox navigation
//======================================================================================================

// Arrow
$arrow-size                                     : 40px; // width + height
$arrow-border-radius                            : $border-radius--icon !default;
$arrow-bg                                       : $btn-bg !default;
$arrow-border                                   : $border-width $border-style $btn-border-color !default;
$arrow-color                                    : $btn-color !default;
$arrow-bg--hover                                : $color-white !default;
$arrow-bd-color--hover                          : $btn-border-color--hover !default;
$arrow-color--hover                             : $btn-color--hover !default;


// Cross on Glightbox
$cross-size                                     : 40px; // width + height
$cross-border-radius                            : $border-radius--icon !default;
$cross-bg                                       : $arrow-bg !default;
$cross-border                                   : $arrow-border !default;
$cross-color                                    : $arrow-color !default;
$cross-bg--hover                                : $arrow-bg--hover !default;
$cross-bd-color--hover                          : $arrow-bd-color--hover !default;
$cross-color--hover                             : $arrow-color--hover !default;


//======================================================================================================
// Accessibility a42
//======================================================================================================
$a42-background                                 : $color-white;
$a42-border                                     : 0;
$a42-border-radius                              : $border-radius;

$a42_title-ffamily                              : $font-family--heading;
$a42_title-fweight                              : $font-weight--heading;
$a42_title-fsize                                : toRem(28);
$a42_title-lineheight                           : $line-height--heading;
$a42_title-color                                : $color-text;

$a42-card-background                            : $color-bg--neutral;
$a42-card-border                                : $color-main;
$a42-card-border-width                          : 0 0 0 .625em;
$a42-card-border-radius                         : 0;
$a42-card-color                                 : $color-text;
$a42-card-input-background                      : $color-white;
$a42-card-input-border                          : $color-main;
$a42-card-input-active                          : $color-main;

$a42-cross-thickness                            : 1px;
$a42-cross-bg                                   : $btn-bg;
$a42-cross-bd-color                             : $btn-border-color;
$a42-cross-color                                : $btn-color;
$a42-cross-bg--hover                            : $btn-bg--hover;
$a42-cross-bd-color--hover                      : $btn-border-color--hover;
$a42-cross-color--hover                         : $btn-color--hover;

$color-overlay--rgba                            : rgba($color-black, 0.8); // on overlay accessconfig


//======================================================================================================
// Filters
//======================================================================================================

// Filter bloc
$filter-bg                                      : $color-white !default;

//===============================================
// Button toggler
//===============================================

// Title
$btn-toggler-title-font-family                  : $font-family--heading !default;
$btn-toggler-title-font-size                    : $font-size--text-medium !default;
$btn-toggler-title-font-weight                  : $font-weight-normal !default;
$btn-toggler-title-line-height                  : $line-height--heading !default;
$btn-toggler-title-text-transform               : uppercase !default;
$btn-toggler-title-color                        : $color-text !default;

// Icon - Colors
$btn-toggler-color                              : $color-white !default;
$btn-toggler-color--hover                       : $color-dark !default;

// Icon - Backgrounds
$btn-toggler-bg                                 : $color-dark !default;
$btn-toggler-bg--hover                          : transparent !default;

// Icon - Borders
$btn-toggler-border-color                       : $btn-toggler-bg !default;
$btn-toggler-border-color--hover                : $btn-toggler-border-color !default;


//===============================================
// Filter input
//===============================================
$f-input-font-size                              : $font-size--text-small !default;
$f-input-color                                  : $color-text !default;
$f-input-bg                                     : transparent !default;
$f-input-border-width                           : 1px !default;
$f-input-border-radius                          : $border-radius--input !default;
$f-input-border-color                           : $color-text--light !default;
$f-input-icon                                   : $color-text--light !default;
// Container subcategory
$f-input-container-bg                           : $color-white !default;

// Active filter
$f-input-color--active                          : $color-text !default;
$f-input-bg--active                             : transparent !default;
$f-input-border-color--active                   : $color-main !default;
$f-input-icon--active                           : $color-text !default;

// Selected filter
$f-input-color--selected                        : $color-text !default;
$f-input-bg--selected                           : $color-light !default;
$f-input-border-color--selected                 : $color-main !default;
$f-input-icon--selected                         : $color-text !default;

//===============================================
// Sub Filter input
//===============================================
$f-sub-input-color                              : $color-text !default;
$f-sub-input-bg                                 : $color-white !default;
$f-sub-input-border-width                       : 1px !default;
$f-sub-input-border-radius                      : $border-radius--input !default;
$f-sub-input-border-color                       : $color-text--light !default;

// Sub filter checked
$f-sub-input-color--checked                     : $color-text--main !default;
$f-sub-input-bg--checked                        : $color-main !default;
$f-sub-input-border-color--checked              : $color-main !default;


//===============================================
// Filter input Keyword + Button Close
//===============================================
// Input
$f-inputKeyword-border-color                    : $f-input-border-color !default;

// Active filter
$f-inputKeyword-border-color--active            : $color-main !default;

// Selected filter
$f-inputKeyword-border-color--selected          : $color-main !default;


// Button Close
//===============================================
$f-Keyword-close-border-width                   : 1px !default;

// Colors
$f-Keyword-color                                : $color-text--main !default;
$f-Keyword-color--hover                         : $color-text !default;

// Backgrounds
$f-Keyword-bg                                   : $color-main !default;
$f-Keyword-bg--hover                            : transparent !default;

// Borders
$f-Keyword-border-color                         : $f-Keyword-bg !default;
$f-Keyword-border-color--hover                  : $f-Keyword-bg !default;



//======================================================================================================
// Propositions
//======================================================================================================
$c_proposition-bg                               : $color-bg--neutral !default;
$c_proposition-border                           : 0 !default;
$c_proposition-radius                           : $border-radius !default;
$c_proposition-boxshadow                        : none !default;
$c_proposition-color                            : $color-text !default;

$c_proposition_btn-bg                           : $btn-bg !default;
$c_proposition_btn-bd-color                     : $btn-border-color !default;
$c_proposition_btn-color                        : $btn-color !default;
$c_proposition_btn-bg--hover                    : $btn-bg--hover !default;
$c_proposition_btn-bd-color--hover              : $btn-border-color--hover !default;
$c_proposition_btn-color--hover                 : $btn-color--hover !default;

.nav-swiper-autoplay {
            
    &__icon {
        position: absolute;
        opacity: 0;
        transition: {
            property: transform, opacity, fill !important;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &--pause {
        .nav-swiper-autoplay__icon--play {
            transform: rotate(0) scale(1);
            opacity: 1;
        }
        .nav-swiper-autoplay__icon--pause {
            transform: rotate(180deg) scale(0);
            opacity: 0;
        }
    }
        
    &--play {
        .nav-swiper-autoplay__icon--play {
            transform: rotate(180deg) scale(0);
            opacity: 0;
        }
        .nav-swiper-autoplay__icon--pause {
            transform: rotate(0) scale(1);
            opacity: 1;
        }
    }
}

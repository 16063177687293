.m-fqa-double_right {
    .c-custom_menu {
        padding: $m-fqa-double_right_menu-padding;

        &::before {
            right: 0;
        }
    }

    .m-access {
        padding: $m-fqa-double_right_access-padding;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fqa-double_right {
        .c-custom_menu {
            padding: $m-fqa-double_right_menu-padding--1200;
        }
    
        .m-access {
            padding: $m-fqa-double_right_access-padding--1200;
        }
    } 

}

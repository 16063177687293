.adverts {

    // TOP POST
    &__publication-date {
        margin: 30px 0;
    }

    // Container
    &__container {
        display: flex;
        gap: 30px;
    }

    &__aside {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: none;
        width: 300px;
    }

    // Image
    &__img {
        overflow: hidden;
        max-width: 300px;
        height: auto;
        margin: 0;
        border-radius: $border-radius;
        line-height: 0;
    }

    // Details
    &__details {
        background-color: $color-bg--neutral;
        border-radius: $border-radius;
        padding: 30px;

        &__title {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--4;
            line-height: $line-height--heading;
        }

        &__list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
            margin-top: 20px;
        }

        &__item {
            display: flex;
            align-items: center;

            svg {
                flex: none;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            text-decoration: underline;
            word-break: break-all;
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: $color-text;
            transition: text-decoration-color $duration $timing;

            &:hover, &:focus {
                text-decoration-color: transparent;

                .adverts__details__icon {
                    fill: $color-text;
                }
            }
        }
        
        &__icon {
            flex: none;
            @include size(26px);
            margin-right: 8px;
            fill: $color-text;
            transition: fill $duration $timing;

            &--blank {
                @include size(20px);
                margin: 0;
                fill: $color-text;
            }
        }
    }

    &__content {
        width: 100%;
    }

    // Title H2
    &__title {
        margin-top: 0;
    }

    // Manage link
    &__link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 40px;
        color: $color-text;
        text-decoration: underline;
        text-decoration-color: $color-text;
        transition: text-decoration-color $duration $timing;

        &:hover, &:focus {
            text-decoration-color: transparent;
        }

        svg {
            fill: $color-text;
            width: 26px;
            height: 26px;
            transition: fill $duration $timing;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .adverts {
        &__container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            gap: 30px;
        }

        &__aside {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
        }

        &__img {
            max-width: 250px;
        }

        &__details {
            width: 100%;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .adverts {
        &__aside {
            flex-wrap: wrap;
        }

        &__img {
            max-width: inherit;
        }
    }

}
.c-title {
    position: relative;
    margin: 0;
    padding-bottom: 5px;
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    font-size: $font-size--1;
    line-height: $line-height--heading;

    &--classic, &--2_parts {
        &::after {
            content: '';
            @include absolute($bottom: -4px, $left: 0);
            display: block;
            @include size(50px, 4px);
            background-color: $color-second;
        }
    }

    &__part2 {
        font-weight: $font-weight-light;
    }
}

.c-subtitle {
    position: relative;
    @include flex($alignItems: center);
    gap: 10px;
    font-family: $font-family--heading;
    font-weight: $font-weight-semibold;
    font-size: $font-size--5;
    line-height: $line-height--heading;
    text-transform: uppercase;

    &::before {
        content: '';
        display: block;
        @include size(50px, 4px);
        background-color: $color-second;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .c-title {
        font-size: toRem(45);
    }

}

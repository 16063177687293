.cover {
    position: relative;

    &__image-wrapper {
        @include size(100%, 450px);
        background-color: $color-bg--image;
                
        picture {
            display: block;
            @include size(100%);
        }

        img {
            @include size(100%);
            object-fit: cover;
            //object-position: top;
        }
    }

    &__category {
        font-family: $font-family;
        font-size: $font-size--text-medium;
        text-transform: uppercase;
    }

    &__informations {
        background-color: $top_post-bg;
        margin: $top_post-margin;
        padding: $top_post-padding;
    }

    &__content {
        @include flex($direction: column);
        gap: 15px;
    }

    &__title {
        margin: 0;
        font-family: $top_post_title-ffamily;
        font-weight: $top_post_title-fweight;
        font-size: $top_post_title-size;
        line-height: $top_post_title-line-height;
        color: $top_post_title-color;
    }

    &__lead-wrapper {
        p {
            margin: 0;
            font-family: $top_post_desc-ffamily;
            font-weight: $top_post_desc-fweight;
            font-size: $top_post_desc-size;
            line-height: $top_post_desc-line-height;
            color: $top_post_desc-color;
        }
    }

    &__intro {
        color: $top_post-color;
    }

    &--full_screen {
        .cover__image-wrapper {
            height: $top_post_model_full_image-height;
        }
    }

    &--full_screen_under_menu {
        .cover__image-wrapper {
            height: $top_post_model_full_under_image-height;
        }
    }

    &--croped {
        .cover__image-wrapper {
            height: $top_post_model_croped_image-height;
            border-radius: $top_post_model_croped_radius;
            overflow: hidden;
        }
    }

    &--small_with_text {
        padding: $top_post_model_small-padding;
        background-color: $top_post_model_small-bg;

        .cover {
            &__wrapper {
                display: flex;
                gap: 30px;
            }

            &__image-wrapper {
                flex: none;
                width: calc($top_post_model_small_image-width - (30px / 2));
                height: $top_post_model_small_image-min-height;
                border-radius: $top_post_model_small_image-radius;
                overflow: hidden;
            }

            &__informations {
                @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: center);
                width: 100%;
                padding: $top_post_model_small_infos-padding;
                background-color: $top_post_model_small_infos-bg;
                border-radius: $top_post_model_small_infos-radius;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &--full_screen {
            .cover__image-wrapper {
                height: $top_post_model_full_image-height--1200;
            }
        }
    
        &--full_screen_under_menu {
            .cover__image-wrapper {
                height: $top_post_model_full_under_image-height--1200;
            }
        }
    
        &--croped {
            .cover__image-wrapper {
                height: $top_post_model_croped_image-height--1200;
            }
        }

        &--small_with_text {
            .cover {
                &__wrapper {
                    gap: 20px;
                }
    
                &__image-wrapper {
                    width: calc($top_post_model_small_image-width - (20px / 2));
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .cover {
        &--full_screen {
            .cover__image-wrapper {
                height: $top_post_model_full_image-height--960;
            }
        }
    
        &--full_screen_under_menu {
            .cover__image-wrapper {
                height: $top_post_model_full_under_image-height--960;
            }
        }
    
        &--croped {
            .cover__image-wrapper {
                height: $top_post_model_croped_image-height--960;
            }
        }

        &--small_with_text {
            .cover {
                &__wrapper {
                    flex-direction: column;
                }
    
                &__image-wrapper {
                    position: unset;
                    @include size(100%, $top_post_model_small_image-height--960);
                    min-height: inherit;
                }
    
                &__informations--image {
                    min-height: unset;
                    margin-left: 0;
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__title {
            font-size: $top_post_title-size--640;
        }

        &--full_screen {
            .cover__image-wrapper {
                height: $top_post_model_full_image-height--640;
            }
        }
    
        &--full_screen_under_menu {
            .cover__image-wrapper {
                height: $top_post_model_full_under_image-height--640;
            }
        }
    
        &--croped {
            .cover {
                &__container {
                    max-width: 100%;
                    padding: 0;
                }
                
                &__image-wrapper {
                    height: $top_post_model_croped_image-height--640;
                }
            }
        }

        &--small_with_text {
            .cover {    
                &__image-wrapper {
                    height: $top_post_model_small_image-height--640;
                }
    
                &__informations {
                    padding: $top_post_model_small_infos-padding--640;
                }
            }
        }
    }

}

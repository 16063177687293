.contact-details-v2 {

    // Grid
    .contact-list {
        display: flex;
        align-items: flex-start;

        &__item {
            flex: 0 1 25%;

            &:first-child {
                @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
                row-gap: 20px;
            }
        }
    }

    // Component title
    .contact-title {
        margin-top: 0;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .contact-details-v2 {
        // Grid
        .contact-list {
            &__item {
                flex: 1 1 0;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .contact-details-v2 {

        // Grid
        .contact-list {
            flex-wrap: wrap;
            gap: 10px 30px;

            &__item {
                flex: inherit;

                &:first-child {
                    row-gap: 10px;
                }
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .contact-details-v2 {

        // Grid
        .contact-list {

            &__item {               
                &--additional {
                    padding-left: 34px;
                }
            }

        }
    }

}

.l-section {
    padding: $l_section-padding;

    &__wrapper {
        @include flex($direction: column, $justifyContent: center);
        gap: $l_section_wrapper-gap;
    }

    &__buttons {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-end);
        gap: $l_section_buttons-gap;
    }

    &__socials {
        @include flex($wrap: wrap, $alignItems: center);
        gap: $l_section_socials-gap;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 640
@media screen and (max-width: $small) {

    .l-section {
        &__wrapper {
            gap: 30px;
        }
        
        &__buttons {
            justify-content: flex-start;
        } 
    }

}

// 480
@media screen and (max-width: $x-small) {

    .l-section__buttons {
        width: 100%;
    }

}

.bloc-content--404 {
    h2 {
        margin: 0;
        border: 0;
        @include font-size(200);
        text-align: center;
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;
    margin-top: 30px;

    .searchResult {
        border: 1px solid $search_input-border-color--active;

        li {
            a  {
                border-bottom: 1px solid $search_input-border-color--active
            }
            
            &:last-child {
                a {
                    border-bottom: 0;
                }
            }
        }
    }

    .search-container {
        position: relative;
        @include flex($alignItems: center);
    
        .searchInput {
            border-color: $search_input-border-color--404 !important;
            
            &:focus {
                border-color: $search_input-border-color--active  !important;
            }
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            @include font-size(160);
        }
    }

}

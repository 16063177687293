//=================================================================================================
// Avoid main content
//=================================================================================================
.avoid-main-content {
    position: relative;
    display: block;
    width: max-content;
    height: 0;
    text-align: center;
    opacity: 0;
    box-sizing: border-box;
    z-index: 1000;

    &:focus {
        height: auto;
        margin: 10px;
        opacity: 1;
    }
}  
 
.a11y-avoid-list {
    @include fixed($top: 0, $left: 0);
    z-index: 1000;
    box-sizing: border-box;
    @include flex($alignItems: center, $justifyContent: flex-start, $wrap: wrap);
    gap: 15px;
    @include size(100%, 0);
    opacity: 0;
    background-color: $color-white;
    pointer-events: none;
    transition: {
        property: opacity, padding;
        duration: $duration;
        timing-function: $timing;
    }

    &:focus-within {
        height: auto;
        padding: 10px;
        opacity: 1;
    }
} 

.admin-bar .a11y-avoid-list {
    top: 32px;
}  


//=================================================================================================
// Classic clearfix 
//=================================================================================================
.clearfix::before,
.clearfix::after {
    content: " ";
    display: block;
}

.clearfix:after {
    clear: both;
}


//=================================================================================================
// Text alignment
//=================================================================================================
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}


//=================================================================================================
// Display
//=================================================================================================
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}


//=================================================================================================
// Vertical center
//=================================================================================================
.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


//=================================================================================================
// Responsive images
//=================================================================================================
.responsive-image {
    max-width: 100%;
    height: auto;
}


//=================================================================================================
// Display State
//=================================================================================================
.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.invisible {
    visibility: hidden;
}


//=================================================================================================
// Floats
//=================================================================================================
.float-left {
    float: left;
}

.float-right {
    float: right;
}


//=================================================================================================
// Padding
//=================================================================================================
.no-padding-top {
    padding-top: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

.padding-top {
    padding-top: 2rem;
}

.padding-bottom {
    padding-bottom: 2rem;
}


//=================================================================================================
// Margins
//=================================================================================================
.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.margin-top {
    margin-top: 2rem;
}

.margin-bottom {
    margin-bottom: 2rem;
}


//=================================================================================================
// Screen reader text for accessibility
//=================================================================================================
.screen-reader-text {
    width: 1px;
    height: 1px;
    position: absolute;
    word-wrap: normal !important;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    padding: 0;
    border: 0;
    margin: -1px;
    overflow: hidden;
}


//=================================================================================================
// Global site
//=================================================================================================
.global-wrapper {
    overflow: hidden;
}


//=================================================================================================
// Reset button style
//=================================================================================================
.reset-button-style {
    display: inherit;
    margin: 0;
    padding: 0;
    background: inherit;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
    text-align: inherit;
    transition: none;

    svg {
        width: inherit;
        height: inherit;
        fill: inherit;
        transition: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background: inherit;
        border-color: inherit;
        color: inherit;

        svg {
            fill: inherit;
        }
    }
}


//===============================================
// Animation Voir plus
//===============================================
.showX {
    opacity: 1;
    transition: opacity 0.30s ease-out;
}

.hideX {
    opacity: 0;
    transition: opacity 0.30s ease-in;
    pointer-events: none;
}

.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.m-fp-1_big_2_smalls {
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 20px;
    }
    
    &__item {
        &--large {
            grid-area: 1 / 1 / 3 / 2;

            .card {
                &__image-wrapper {
                    @include size(100%, 350px);
                    border-radius: $border-radius--image;
                }

                &__container {
                    border: 0;
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    overflow: unset;

                    &:has(.card__image-wrapper) {
                        height: auto;

                        .card__content {
                            margin: -90px 20px 0 20px;
                        }
                    }

                    &:hover, &:focus {                        
                        .card__content {
                            box-shadow: $card-shadow--hover; 
                        }
                    }
                }
    
                &__content {
                    position: relative;
                    margin: 0;
                    padding: $m-fp-1_big_2_smalls_item--large_content-padding;
                    border: $card-border;
                    border-radius: $card-radius;
                    box-shadow: $card-shadow;
                    transition: {
                        property: background-color, box-shadow;
                        duration: $duration--second;
                        timing-function: $timing;
                    }
                }
            }

        }
    }

    // If model without category & description
    &__item:not(:has(.card__description)) {
        .card__title {
            font-size: $font-size--3;
        }

        .card__content--has-category {
            .card__title {
                font-size: $font-size--4;
            }
        }
    }


    .m-1_big_2_smalls__button {
        background-color: $m-fp-1_big_2_smalls_btn-bg;
        border-color: $m-fp-1_big_2_smalls_btn-bd-color;
        color: $m-fp-1_big_2_smalls_btn-color;
    
        &:hover, &:focus {
            background-color: $m-fp-1_big_2_smalls_btn-bg--hover;
            border-color: $m-fp-1_big_2_smalls_btn-bd-color--hover;
            color: $m-fp-1_big_2_smalls_btn-color--hover;
        }

        &--prop {
            background-color: $m-fp-1_big_2_smalls_btn_prop-bg;
            border-color: $m-fp-1_big_2_smalls_btn_prop-bd-color;
            color: $m-fp-1_big_2_smalls_btn_prop-color;
        
            &:hover, &:focus {
                background-color: $m-fp-1_big_2_smalls_btn_prop-bg--hover;
                border-color: $m-fp-1_big_2_smalls_btn_prop-bd-color--hover;
                color: $m-fp-1_big_2_smalls_btn_prop-color--hover;
            }
    
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fp-1_big_2_smalls {

        &__list {
            grid-column-gap: 20px;
        }

        &__item--large .card__image-wrapper {
            height: 280px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fp-1_big_2_smalls {

        &__list {
            @include flex($direction: column);
        }
        
        &__item--large {
            .card {
                &__image-wrapper {
                    height: 380px;
                }

                &__container {
                    &:has(.card__image-wrapper) {    
                        .card__content {
                            margin: -30px 0 0;
                        }
                    }
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fp-1_big_2_smalls {
        &__item--large {
            .card {
                &__image-wrapper {
                    height: 250px;
                }
            }
        }
    }

}
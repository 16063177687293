//======================================================================================================
// Top Post
//======================================================================================================

// Top post infos
$top_post-margin                                : 0 !default;
$top_post-padding                               : 30px 0 !default;
$top_post-bg                                    : $color-bg--neutral !default;
$top_post-color                                 : $color-text !default;

// Top post title
$top_post_title-ffamily                         : $font-family--heading !default;
$top_post_title-fweight                         : $font-weight--heading !default;
$top_post_title-size                            : $font-size--1 !default;
$top_post_title-size--640                       : toRem(38) !default;
$top_post_title-line-height                     : $line-height--heading !default;
$top_post_title-color                           : $top_post-color !default;

// Top post description
$top_post_desc-ffamily                          : $font-family !default;
$top_post_desc-fweight                          : $font-weight-bold !default;
$top_post_desc-size                             : $font-size--5 !default;
$top_post_desc-line-height                      : $line-height !default;
$top_post_desc-color                            : $top_post-color !default;

// Top post model -- full_screen
$top_post_model_full_image-height               : 400px !default;
$top_post_model_full_image-height--1200         : 400px !default;
$top_post_model_full_image-height--960          : 380px !default;
$top_post_model_full_image-height--640          : 250px !default;

// Top post model -- full_screen_under_menu
$top_post_model_full_under_image-height         : 590px !default;
$top_post_model_full_under_image-height--1200   : 590px !default;
$top_post_model_full_under_image-height--960    : 380px !default;
$top_post_model_full_under_image-height--640    : 250px !default;

// Top post model -- croped
$top_post_model_croped_image-height             : 450px !default;
$top_post_model_croped_image-height--1200       : 450px !default;
$top_post_model_croped_image-height--960        : 380px !default;
$top_post_model_croped_image-height--640        : 250px !default;
$top_post_model_croped_radius                   : 0 !default;

// Top post model -- small_with_text
$top_post_model_small-padding                   : 40px 0 !default;
$top_post_model_small-bg                        : $top_post-bg !default;
$top_post_model_small_infos-padding             : 0 !default;
$top_post_model_small_infos-padding--640        : 0 !default;
$top_post_model_small_infos-bg                  : transparent !default;
$top_post_model_small_infos-radius              : 0 !default;
$top_post_model_small_image-width               : 50% !default;
$top_post_model_small_image-min-height          : 350px !default;
$top_post_model_small_image-height--960         : 300px !default;
$top_post_model_small_image-height--640         : 200px !default;
$top_post_model_small_image-radius              : $border-radius--image !default;


//======================================================================================================
// Breadcrumb
//======================================================================================================
$breadcrumb-color                               : $color-text--light !default;
$breadcrumb-color--hover                        : $color-text !default;

.page-pagination {
    @include flex($direction: column, $alignItems: center, $justifyContent: center);
    text-align: center;
    margin: 0 0 30px 0;

    p {
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: $font-weight-bold;
        font-size: $font-size--text-small;
        color: $color-text--light;
        text-transform: uppercase;
    }
}

// For archive button see more post
button#load-older-posts {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    width: fit-content;
    min-height: $btn-tag-height;
    padding: $btn-tag-padding;
    background-color: $btn-tag-bg;
    border: $btn-tag-border-width $btn-border-style $btn-tag-border-color;
    color: $btn-tag-color;
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    text-transform: $btn-text-transform;
    text-decoration: none;
    transition: {
        property: color, background-color, border-color;
        duration: $duration;
        timing-function: $timing;
    }
    
    &:hover,
    &:focus {
        background-color: $btn-tag-bg--hover;
        border-color: $btn-tag-border-color--hover;
        color: $btn-tag-color--hover;
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .page-pagination {     
        margin-top: 15px;
    } 

} 

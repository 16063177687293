.c-searchbar {
    z-index: 2;
    pointer-events: none;
    @include flex($direction: column, $alignItems: center, $justifyContent: $m-searchbar-justifyContent);
    padding-top: $m-searchbar-paddingTop;
    padding-bottom: $m-searchbar-paddingBottom;
    
    &__content {
        display: block !important;
        pointer-events: auto;
        width: 100%;
        max-width: $m-searchbar-maxwidth;
    }
    
    input.c-searchbar__input {
        @include size(100%, $m-searchbar-height);
        padding: $m-searchbar-padding;
        background-color: $m-searchbar-bg;
        border-radius: $m-searchbar-radius;
        border-color: transparent !important;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        transition: background-color $duration $timing;
    
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    
        &:focus {
            background-color: $m-searchbar-bg--focus;
        }
    }

    & .search-container-open {
        input.c-searchbar__input {
            background-color: $m-searchbar-bg--focus;
        }
    }

    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        @include absolute($top: 50%, $right: $m-searchbar_btn-right);
        transform: translateY(-50%);
        @include size($m-searchbar_btn-size);
        background-color: $m-searchbar_btn-bg;
        border: $m-searchbar_btn-bd;
        border-radius: $m-searchbar_btn-radius;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }
    
        svg {
            fill: $m-searchbar_btn-color;
        }
    
        &:hover, &:focus {
            background-color: $m-searchbar_btn-bg--hover;
            border-color: $m-searchbar_btn-bd-color--hover;
    
            svg {
                fill: $m-searchbar_btn-color--hover;
            }
        }
    }

    &__results#js-searchBarResults {
        @include absolute($top: $m-searchbar_results-top);
        width: 100%;
        border-radius: $m-searchbar_results-radius;
        overflow: hidden;
        border: $m-searchbar_results-bd;
        box-shadow: $m-searchbar_results-shadow;
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .c-searchbar {
        display: none;
    }

}

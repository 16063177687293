//===============================================
// Actions buttons
//===============================================
.documents-details__button {
    @include flex($alignItems: center);
    column-gap: 10px;
    margin: 0;
    cursor: pointer;
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size--text;
    line-height: $line-height;
    text-transform: none;
    color: $btn_action-color;
    text-decoration: underline;
    text-decoration-color: $btn_action-color;
    transition: {
        property: color, text-decoration-color;
        duration: $duration;
        timing-function: $timing;
    }

    svg {
        @include size($btn_action_icon-size);
        background-color: $btn_action_icon-bg;
        border: $btn_action_icon-border-width $btn_action_icon-border-style $btn_action_icon-border-color;
        border-radius: $btn_action_icon-border-radius;
        fill: $btn_action_icon-color;
        transition: {
            property: background-color, border-color, fill;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &:hover, &:focus {
        color: $btn_action-color--hover;
        text-decoration-color: transparent;

        svg {
            background-color: $btn_action_icon-bg--hover;
            border-color: $btn_action_icon-border-color--hover;
            fill: $btn_action_icon-color--hover;
        }
    }
}

//======================================================================================================
// Bloc Nombres
//======================================================================================================
.bloc-numbers {
    @include flex($justifyContent: center, $wrap: wrap);
    margin: 30px 0;

    &__block {
        @include flex($direction: column, $alignItems: center);
        text-align: center;
        padding: 20px;
    }

    &__figure {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--2;
        color: $color-text;
    }

    &__unit {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--3;
        color: $color-text;
    }

    &__exponent {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--text;
        color: $color-text;
    }

    &__caption {
        max-width: 250px;
        font-family: $font-family--heading;
        font-weight: $font-weight;
        font-size: $font-size--5;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-numbers {
        &__block {
            flex-basis: 50%;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    
    .bloc-numbers {
        &__block {
            flex-basis: 100%;
        }
    }

}

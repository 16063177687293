.m-fp-3_slides {
    
    &--with_title {
        .m-3_slides__nav {
            position: relative;
            margin-top: $m-fp-3_slides_nav_btn-margin-top;
            min-height: 40px;
        }
    }

    &__content {
        margin: 0 !important; // swiper override
    }

    &__item {
        height: auto !important; // swiper override
    }

    .swiper {
        margin: $swiper-margin;
        padding: $swiper-padding;
    }

    .m-3_slides {
        &__nav .nav-swiper--prev, &__nav .nav-swiper--next {
            background-color: $m-fp-3_slides_nav_btn-bg;
            border-color: $m-fp-3_slides_nav_btn-bd-color;
            color: $m-fp-3_slides_nav_btn-color;

            .button__icon {
                fill: $m-fp-3_slides_nav_btn-color;
            }
        
            &:hover, &:focus {
                background-color: $m-fp-3_slides_nav_btn-bg--hover;
                border-color: $m-fp-3_slides_nav_btn-bd-color--hover;

                .button__icon {
                    fill: $m-fp-3_slides_nav_btn-color--hover;
                }
            }
        }

        &__button {
            background-color: $m-fp-3_slides_btn-bg;
            border-color: $m-fp-3_slides_btn-bd-color;
            color: $m-fp-3_slides_btn-color;
        
            &:hover, &:focus {
                background-color: $m-fp-3_slides_btn-bg--hover;
                border-color: $m-fp-3_slides_btn-bd-color--hover;
                color: $m-fp-3_slides_btn-color--hover;
            }

            &--prop {
                background-color: $m-fp-3_slides_btn_prop-bg;
                border-color: $m-fp-3_slides_btn_prop-bd-color;
                color: $m-fp-3_slides_btn_prop-color;
            
                &:hover, &:focus {
                    background-color: $m-fp-3_slides_btn_prop-bg--hover;
                    border-color: $m-fp-3_slides_btn_prop-bd-color--hover;
                    color: $m-fp-3_slides_btn_prop-color--hover;
                }
        
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fp-3_slides {
        .swiper {
            margin: $swiper-margin--1200;
            padding: $swiper-padding--1200;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fp-3_slides {
        &--with_title .m-3_slides__nav {
            position: unset;
            margin-top: 0;
            min-height: unset;
        }
    }

}

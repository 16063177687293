.m-db-documents_banner, .m-db-banner_documents {
    
    &__wrapper {
        @include flex($alignItems: $m-db-docs_banner__wrapper-alignItems, $justifyContent: space-between);
    }

    .m-docs {
        width: calc(50% - 15px);

        &__wrapper {
            gap: 25px;
        }

        &__title {
            padding-bottom: 0;
            
            &::after {
                display: none;
            }
        }

        &__swiper {
            margin: $m-db-banner__docs-swiper-margin;
            padding: $m-db-banner__docs-swiper-padding;
        }

        .c-docs {
            display: flex !important; // override swiper sass

            &__content {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &__button {
            background-color: $c_document_button_sys-bg;
            border-color: $c_document_button_sys-bd-color;
            color: $c_document_button_sys-color;
        
            &:hover, &:focus {
                background-color: $c_document_button_sys-bg--hover;
                border-color: $c_document_button_sys-bd-color--hover;
                color: $c_document_button_sys-color--hover;
            }
        }

        .m-documents_banner__nav, .m-banner_documents__nav {    
            .nav-swiper--prev, .nav-swiper--next {
                background-color: $c_document_nav-bg;
                border-color: $c_document_nav-bd-color;
    
                .button__icon {
                    fill: $c_document_nav-color;
                }
            
                &:hover, &:focus {
                    background-color: $c_document_nav-bg--hover;
                    border-color: $c_document_nav-bd-color--hover;
    
                    .button__icon {
                        fill: $c_document_nav-color--hover;
                    }
                }
            }
        }
        
        &__footer {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            gap: 20px;
        }
    }

    .m-docbanner {
        width: 500px;
        min-height: $m-db-banner__wrapper-min-height;
        @include flex($alignItems: center);
        background-color: $m-db-banner__wrapper-bg;
        border: $m-db-banner__wrapper-border;
        border-radius: $m-db-banner__wrapper-radius;
        box-shadow: $m-banner_wrapper-shadow;
    
        &__wrapper {
            @include flex($direction: column, $justifyContent: center);
            gap: 20px;
            @include size(100%);
            padding: $m-db-banner__wrapper-padding;
        }

        &__infos {
            @include flex($direction: column);
            gap: 10px;
            color: $m-db-banner__wrapper-color;
        }
        
        &__links {
            @include flex($direction: column);
            gap: 20px;
        }
        
        &__title {
            padding-bottom: 0;
            color: $m-db-banner__wrapper-color;
            
            &::after {
                display: none;
            }
        }

        &__social {
            background-color: $m-db-banner__socials_btn-bg;
            border-color: $m-db-banner__socials_btn-bd-color;
            
            .button__icon {
                fill: $m-db-banner__socials_btn-color;
            }

            &:hover, &:focus {
                background-color: $m-db-banner__socials_btn-bg--hover;
                border-color: $m-db-banner__socials_btn-bd-color--hover;
                
                .button__icon {
                    fill: $m-db-banner__socials_btn-color--hover;
                }
            }
        }
    
        &__buttons {
            justify-content: $m-db-banner__buttons-justifyContent;
        }

        &__button {
            background-color: $m-db-banner__buttons_btn-bg;
            border-color: $m-db-banner__buttons_btn-bd-color;
            color: $m-db-banner__buttons_btn-color;
            
            .button__icon {
                fill: $m-db-banner__buttons_btn-color;
            }

            &:hover, &:focus {
                background-color: $m-db-banner__buttons_btn-bg--hover;
                border-color: $m-db-banner__buttons_btn-bd-color--hover;
                color: $m-db-banner__buttons_btn-color--hover;
                
                .button__icon {
                    fill: $m-db-banner__buttons_btn-color--hover;
                }
            }
        }
    } 
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-db-documents_banner, .m-db-banner_documents {
        .m-docs {
            width: calc(50% - 10px);
            
            &__swiper {
                margin: $m-db-banner__docs-swiper-margin--1200;
                padding: $m-db-banner__docs-swiper-padding--1200;
            }
        }

        .m-docbanner {
            width: 400px;
        
            &__wrapper {
                padding: $m-db-banner__wrapper-padding--1200;
            }
        } 
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-db-documents_banner, .m-db-banner_documents {

        &__wrapper {
            flex-direction: column;
            align-items: inherit;
            justify-content: inherit;
            gap: 50px;
        }

        .m-docs {
            width: 100%;
        }

        .m-docbanner {
            width: 100%;

            &__wrapper {
                padding: $m-db-banner__wrapper-padding--960;
            }
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .m-db-documents_banner, .m-db-banner_documents {
        .m-docbanner {       
            &__wrapper {
                padding: $m-db-banner__wrapper-padding--640;
            }
        } 

        .m-docs .c-docs__content {
            border-radius: $c_document_content-radius;
        }
    }

}

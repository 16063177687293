//======================================================================================================
// FORM
//======================================================================================================
.adverts {

    &__password {
        .acf-input {
            position: relative;
        }

        input {
            padding-right: 45px !important;

            &:focus {
                ~ .adverts__password__button svg {
                    fill: $color-text !important;
                }
            }
        }

        &__button {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);

            svg {
                width: 26px;
                height: 26px;
                fill: $form-color-icon;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                fill: $color-text;
            }
        }
    }

    &__fieldset {
        border: 0;
        border-radius: 0;

        legend {
            padding: 0;
            margin-bottom: 15px;
            font-size: $font-size--text;
            font-weight: $font-weight-bold;
        }

        ul {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
    
            li {
                display: flex;
                align-items: center;
                column-gap: 5px;
    
                label {
                    margin: 0;
                    font-weight: $font-weight;
                }
            }
        }
    }
}

// Form proposition
#prop_adverts {
    .categorychecklist-holder {
        max-height: inherit;
        border: 0;
        border-radius: 0;

        ul.acf-checkbox-list {
            padding: 0;
            border: 0;
            border-radius: 0;

            &:focus-within {
                border: 0;
                border-radius: 0;
            }

            li {
                font-size: $font-size--text;
                line-height: $line-height;

                label {
                    font-weight: $font-weight;
                }
            }
        }
    }

    .acf-field {
        &[data-name=adverts_details_photo] .acf-label::after {
            content: "Poids maximal autorisé : 1 Mo";
            position: relative;
            display: block;
            margin: 10px 0 0;
            color: $color-text--light;
            font-style: italic; 
        }
        
    }
}

.manage-adverts .cover {
    margin-bottom: 30px;
}

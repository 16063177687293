$theme_name                             : 'vue';

// Black & White
$color-white                            : #FFFFFF;
$color-light                            : #E9EDF2;
$color-gray                             : #586672;
$color-dark                             : #000000;

// Main Color
$color-main                             : #333B8F;
$color-second                           : #333B8F;

$color-bg--transparent                  : transparent;

// Color Card
$card-bg--hover                         : #DADFE6;

// Text
$color-text                             : $color-dark;
$color-text--main                       : $color-white;
$color-link                             : $color-second;

// Font
$font-family                            : 'Roboto';
$font-family--heading                   : 'scarlet';

// Border-radius
$border-radius                          : 20px;
$border-radius--second                  : 15px;
$border-radius--input                   : 5px;
$border-radius--image                   : $border-radius--second;

// Box-shadow
$shadow                                 : 1px 1px 15px 0px rgba(40, 40, 40, 0.10);

// Transition
$duration                               : .25s;
$timing                                 : ease-in-out;

// URL shortner
$urlThemeShort                          : '/wp-content/themes/' + $theme_name + '/assets/src/images/sprite-images/theme-icons/';


@use '../../../plugins/creasit-builder/assets/src/sass/styles.scss' with (

// VARIABLES

    $theme_name                             : $theme_name,

    // Black & White
    $color-light                            : $color-light,
    $color-gray                             : $color-gray,
    $color-dark                             : $color-dark,

    // Main Colors
    $color-main                             : $color-main,
    $color-second                           : $color-second,

    // Text color on main color
    $color-text                             : $color-text,
    $color-text--main                       : $color-white,
    $color-link                             : $color-link,

    // Font
    $font-family                            : $font-family,
    $font-family--heading                   : $font-family--heading,

    // Border-radius
    $border-radius                          : $border-radius,
    $border-radius--second                  : $border-radius--second,
    $border-radius--input                   : $border-radius--input,
    $border-radius--image                   : $border-radius--image,

    // Card
    $card-bg                                : $color-white,
    $card-bg--hover                         : $card-bg--hover,

    $card-shadow                            : $shadow,
    $card-shadow--hover                     : unset,

    // Taxo
    $taxo-bg                                : $color-light,

    // Header
    $header-shadow                          : $shadow, // Unset si Alu avec marges
    $header-shadow--fixed                   : $shadow,

        // Header - Model full under top
        $header_m_full_under_top-filter-shadow          : unset,
        $header_m_full_under_top-filter-shadow--fixed   : $shadow,

        $header_m_full_under_top-bg--fixed              : $color-white,

        $header_m_full_under_top_link-color             : $color-white,
        $header_m_full_under_top_link-color--fixed      : $color-text,

    // Menu

        $smenu_menu_link-ffamily                        : $font-family,

        // Last link in button style
        $smenu_menu_link_last_item-min-height           : inherit,
        $smenu_menu_link_last_item-padding              : 10px 0,
        $smenu_menu_link_last_item-bg                   : transparent,
        $smenu_menu_link_last_item-border               : 0,
        $smenu_menu_link_last_item-radius               : inherit,
        $smenu_menu_link_last_item-color                : $color-text,
        $smenu_menu_link_last_item-bg--hover            : none,
        $smenu_menu_link_last_item-border-color--hover  : none,
        $smenu_menu_link_last_item-color--hover         : $color-text,

        // Button Close Menu
        $smenu_button_close-bg                      : $color-white,
        $smenu_button_close-border-color            : $color-white,
        $smenu_button_close-color                   : $color-dark,
        $smenu_button_close-bg--hover               : $color-bg--transparent,
        $smenu_button_close-border-color--hover     : $color-text--main,
        $smenu_button_close-color--hover            : $color-text--main,

    // Footer

        // Infos
        $footer_infos-gap                           : 60px,

        // Coord
        $footer_coord-maxwidth                      : 185px,

        // Opening hours
        $footer_opening-maxwidth                    : 380px,

        // Subzone visual link
        $footer_subzone_visualink-alignItems        : center,
        $footer_subzone_visualink-alignItems--1200  : center,
        $footer_subzone_visualink-alignItems--960   : center,
        $footer_subzone_visualink-alignItems--640   : center,

    // Home

        // Quick Access
        $c_access-alignItems                : center,
        $c_acesss-justifyContent            : center,
        $c_access__title-max-width          : calc(100% - 20px),
            
            // Classic
            $m-fqa-classic_item-size--960   : 120px,
            $m-fqa-classic_item-size--640   : 120px,

            // Double
            $m-fqa-double_item-size--960    : 120px,
            $m-fqa-double_item-size--640    : 120px,

        // Events
        $m-fp-3_slides_btn_prop-bg          : $color-white,
);

@import 'abstracts/mixins';
@import 'base/font-face';
@import 'components/f-titles';
@import 'components/c-title';
@import 'pages/home';


// Cards
.card {

    &__container {

        .card__category {
            background-color: $color-light;
            transition: background-color $duration $timing;
        }

        &:hover, &:focus {
            .card__category {
                background-color: $color-white;
            }
        }

        // Document
        &--document {
            box-shadow: unset!important;
            background-color: $color-light!important;

            .card__category {
                background-color: $color-white;
            }

            .card__content {
                background-color: $color-light!important;
            }
            
            &:hover, &:focus {
                box-shadow: unset!important;

                .card__content {
                    background-color: $color-light!important;
                }
            }
        }
    }
}

// Paragraphe coloré + Border coloré
.bloc-content--paragraph {
    
    &.border.secondary {
        border-color: #EC6726;
    }

    &.background.secondary {
        background-color: #EC6726;
        color: $color-text;

        .bloc-content__icon {
            fill: $color-text;
        }

        ul li::before {
            background-color: $color-text;
        }

        a {
            color: $color-text;
            text-decoration-color: $color-text;
        }
    }
}
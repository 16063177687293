.icon {
    flex: none;
    
    &--bg {
        background-color: $color-white;
        border-radius: $border-radius--icon;
    }

    &--big {
        @include size(30px);
    }

    &--normal {
        @include size(26px);
    }

    &--small {
        @include size(20px);
    }
}

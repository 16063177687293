.footer {
    background-color: $footer-bg;

    &__wrapper, &__content {
        @include flex($alignItems: flex-start);
        gap: 60px;
    }

    &__wrapper {
        justify-content: space-between;
        padding: $footer_wrapper-padding;
        row-gap: 50px;
    }

    &__logo {
        flex: none;
        @include size($footer_logo-width, $footer_logo-height);

        svg {
            @include size(100%);
        }

        image {
            @include size(100%, auto);
        }
    }

    &__infos-list {
        @include flex($direction: column, $justifyContent: flex-start, $alignItems: flex-start);
        gap: 30px;
    }

    &__infos {
        @include flex($direction: row, $justifyContent: flex-start, $alignItems: flex-start);
        gap: $footer_infos-gap;
        background-color: $footer_infos-bg;
        padding: $footer_infos-padding;
        border: $footer_infos-border-width solid $footer_infos-border-color;
    }

    &__coord,
    &__opening-hours {
        @include flex($direction: column, $justifyContent: center, $alignItems: flex-start);
        gap: 15px;
        color: $footer-color;
    }

    &__coord {
        max-width: $footer_coord-maxwidth;
    }

    &__opening-hours {
        max-width: $footer_opening-maxwidth ;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        font-size: $font-size--4;
        line-height: $line-height--heading;
        color: $footer-color;
    }

    &__phone {
        color: $footer_phone-color;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: {
            property: color, text-decoration-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            color: $footer_phone-color--hover;
            text-decoration-color: $footer_phone-color--hover;
        }
    }

    &__subzone {
        @include flex($direction: column, $wrap: wrap, $justifyContent: center);
        flex: 1;
        max-width: $footer_subzone-maxwidth;
        padding: $footer_subzone-padding;
        background-color: $footer_subzone-bg;
        border: $footer_subzone-border;
        border-radius: $footer_subzone-radius;

        &--social {
            gap: $footer_subzone_social-gap;

            .button--icon_alone {
                background-color: $footer_subzone_social_button-bg;
                border-color: $footer_subzone_social_button-border-color;

                .button__icon {
                    fill: $footer_subzone_social_button-color;
                }

                &:hover, &:focus {
                    background-color: $footer_subzone_social_button-bg--hover;
                    border-color: $footer_subzone_social_button-border-color--hover;

                    .button__icon {
                        fill: $footer_subzone_social_button-color--hover;
                    }
                }
            }
        }

        &--visual-link {
            width: 100%;
            align-items: $footer_subzone_visualink-alignItems;
            gap: $footer_subzone_visualink-gap;
            background-color: $footer_subzone_visualink-bg;
            border: $footer_subzone_visualink-border;
            transition: {
                property: background-color, border-color;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                @include size($footer_subzone_visualink-svg-size);
                fill: $footer_subzone_visualink-svg-fill;
            }

            .footer__link {
                font-family: $footer_subzone_visualink-text-ffamily;
                font-weight: $footer_subzone_visualink-text-fweight;
                font-size: $footer_subzone_visualink-text-fsize;
                line-height: $footer_subzone_visualink-text-lineheight;
                text-transform: $footer_subzone_visualink-text-transform;
                text-align: $footer_subzone_visualink-text-align;
                color: $footer_subzone_visualink-color;
                transition: color $duration $timing;
            }

            &:hover, &:focus {    
                background-color: $footer_subzone_visualink-bg--hover;
                border-color: $footer_subzone_visualink-border-color--hover;
            }
        }

        &--menu {
            gap: 10px;

            .c-custom_menu {
                &__link {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    gap: 4px;
                    color: $footer_subzone_menu-color;
                    text-decoration: underline;
                    text-decoration-color: transparent;
                    transition: {
                        property: color, text-decoration-color;
                        duration: $duration;
                        timing-function: $timing;
                    }
            
                    &:hover, &:focus {
                        color: $footer_subzone_menu-color--hover;
                        text-decoration-color: $footer_subzone_menu-color--hover;

                        .c-custom_menu__icon {
                            fill: $footer_subzone_menu-color--hover
                        }
                    }
                }

                &__icon {
                    fill: $footer_subzone_menu-color;
                }
            }
        }
    }

    &__button {
        margin-top: 5px;
        background-color: $footer_button-bg;
        border-color: $footer_button-border-color;
        color: $footer_button-color;

        .button__icon {
            fill: $footer_button-color;
        }

        &:hover, &:focus {
            background-color: $footer_button-bg--hover;
            border-color: $footer_button-border-color--hover;
            color: $footer_button-color--hover;
    
            .button__icon {
                fill: $footer_button-color--hover;
            }
        }
    }

    &__menu {
        padding: $footer_menu_padding;
        background-color: $footer_menu-bg;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            gap: 20px;
            width: 100%;
        }

        &__item {
            position: relative;
            padding: 0;
        }

        &__link {
            font-size: $footer_menu_link-fsize;
            color: $footer_menu_link-color;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: {
                property: color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                color: $footer_menu_link-color--hover;
                text-decoration-color: $footer_menu_link-color--hover;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {
        &__content {
            gap: 30px 40px;
        }

        &__logo  {
            @include size($footer_logo-width--1200, $footer_logo-height--1200);
        }

        &__coord, &__subzone {
            min-width: 220px;
        }

        &__subzone--visual-link {
            align-items: $footer_subzone_visualink-alignItems--1200;

            .footer__link {
                text-align: $footer_subzone_visualink-text-align--1200;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        &__wrapper, &__content {
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        &__logo {
            flex: none;
            @include size($footer_logo-width--960, $footer_logo-height--960);
        }

        &__infos-list {
            align-items: center;
            justify-content: center;
        }

        &__infos {
            flex-direction: column;
        }

        &__coord,
        &__opening-hours {
            flex: none;
            width: 100%;
        }

        &__coord,
        &__opening-hours {
            align-items: center;
            min-width: inherit;
            max-width: inherit;
            text-align: center;
        }

        &__menu__list {
            justify-content: center;
            gap: 20px 30px;
        }

        &__subzone--visual-link {
            align-items: $footer_subzone_visualink-alignItems--960;

            .footer__link {
                text-align: $footer_subzone_visualink-text-align--960;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .footer {
        margin-bottom: $header--fixed-height--640;
        
        &__logo {
            @include size($footer_logo-width--640, $footer_logo-height--640);
        }

        &__coord,
        &__opening-hours {
            flex: none;
            width: 100%;
        }

        &__subzone--visual-link {
            align-items: $footer_subzone_visualink-alignItems--640;

            .footer__link {
                text-align: $footer_subzone_visualink-text-align--640;
            }
        }
    }

}

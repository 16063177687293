.c-access {
    @include flex($direction: column, $alignItems: $c_access-alignItems, $justifyContent: $c_acesss-justifyContent);
    gap: $c_access-gap;
    height: 100%;

    &__image, &__svg {
        @include flex($justifyContent: center, $alignItems: center);
        @include size($c_access__image-size);
        background-color: $c_access__image-bg;
        border: $c_access__image-border;
        border-radius: $c_access__image-radius;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &__title {
        max-width: $c_access__title-max-width;
        font-family: $c_access__title-ffamily;
        font-weight: $c_access__title-fweight;
        font-size: $c_access__title-fsize;
        text-transform: $c_access__title-ttransform;
        line-height: $c_access__title-line-height;
        text-align: $c_access__title-talign;
        color: $c_access__title-color;
        transition: color $duration $timing;
    }

    &:hover, &:focus {
        .c-access {
            &__image, &__svg {
                background-color: $c_access__image-bg--hover;
                border-color: $c_access__image-bd-color--hover;
            }

            &__title {
                color: $c_access__title-color--hover;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .c-access {  
        gap: $c_access-gap--1200;  

        &__image, &__svg {
            @include size($c_access__image-size--1200);
        }
    }
   
}


// 960
@media screen and (max-width: $medium) {

    .c-access {
        gap: $c_access-gap--960;

        &__image, &__svg {
            @include size($c_access__image-size--960);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .c-access {    
        gap: $c_access-gap--640;

        &__image, &__svg {
            @include size($c_access__image-size--640);
        }
    }

}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    @include fixed($bottom: 0, $left: 0);
    z-index: 100;
    width: 100%;
    background-color: $color-black--rgba;
    color: $color-white;

    &__container {
        @include flex($alignItems: center);
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: $color-white;
        transition: {
            property: color, text-decoration-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            color: $color-white;
            text-decoration-color: transparent;
        }
    }

    &__button {
        justify-content: center;
        min-height: $btn-height;
        width: 240px;
        padding: $btn-padding;
        margin-left: 15px;
        background-color: $color-white;
        border: $btn-border-width $btn-border-style $color-white;
        border-radius: $btn-border-radius;
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        text-transform: $btn-text-transform;
        text-decoration: none;
        color: $color-text;
        transition: {
            property: color, background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            background-color: transparent;
            border-color: $color-white;
            color: $color-white;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

}

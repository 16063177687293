.m-fs-croped {
    position: relative;
    padding: $m-croped-padding;

    .swiper, .c-slideshow__bloc--video, .c-slideshow__link--video {
        border-radius: $m-croped-radius;
    }

    .c-slideshow__bloc--video, .c-slideshow__link--video {
        display: block;
    }
    
    &__container--nav, &__container--pag {
        @include absolute(0, 0, 0, 0);
        pointer-events: none;
    }

    &__container--nav, &__container--pag, .c-slideshow__container {
        max-width: inherit;
        margin: $m-croped-container-margin;
        padding: 0;
    }

    &--with-searchbar {
        .m-fs-croped__container--pag {
            display: flex;
            justify-content: center;
        }

        .c-slideshow__searchbar {
            margin: auto !important;
            padding-top: $m-searchbar-paddingTop;
            padding-bottom: $m-searchbar-paddingBottom;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .m-fs-croped {
        padding: 0;

        .swiper, .c-slideshow__bloc--video, .c-slideshow__link--video {
            border-radius: 0;
        }

        &__container {
            max-width: inherit;
            margin: 0;
            padding: 0;
        }

        &__container--nav, &__container--pag, .c-slideshow__container {
            max-width: 90%;
            padding: 0 ($gl-gutter-xs * 0.5);
            margin: 0 auto;
        }

        &--with-description {
            .m-croped__nav {
                bottom: $m-slideshow_nav_bottom--640--desc;
                top: $m-slideshow_nav_top--640--desc;
            } 

            .m-croped__pag {
                bottom: $m-slideshow_pag_bottom--640--desc;
                top: $m-slideshow_pag_top--640--desc;
            } 
        }
    } 

}
.c-navigation {
    @include flex($alignItems: center, $justifyContent: flex-end);
    gap: 10px;

    &--slideshow {
        justify-content: inherit;
        z-index: 1;
        pointer-events: auto;
        @include absolute($bottom: $m-slideshow_nav_bottom);
        gap: $m-slideshow_nav-gap;

        .button {
            background-color: $m-slideshow_nav_btn-bg;
            border-color: $m-slideshow_nav_btn-bd-color;

            svg {
                fill: $m-slideshow_nav_btn-color;
            }

            &:hover, &:focus {
                background-color: $m-slideshow_nav_btn-bg--hover;
                border-color: $m-slideshow_nav_btn-bd-color--hover;

                svg {
                    fill: $m-slideshow_nav_btn-color--hover;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .c-navigation {
        justify-content: flex-start;

        &--slideshow {
            bottom: $m-slideshow_nav_bottom--640;
        }
    }

}

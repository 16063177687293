
.comments {
    margin-top: 50px;

    .comment-reply-title {
        margin: 0 0 20px 0;
    }

    &__title {
        margin: 30px 0 0 0;
    }

    .comment-form {
        .description {
            display: block;
            font-style: italic;
            color: $color-text--light;
            margin: 0;
        }

        label {
            margin: 0 0 5px 0;
        }
    
        p.comment-form-comment, p.comment-form-author, p.comment-form-email {
            margin-top: 30px;
        }

        .rgpd-container {
            margin-top: 30px;

            label {
                margin: 0;
            }
        }
    
        &-cookies-consent {
            display: flex;
            align-items: center;
            margin-top: 20px;
    
            input {
                margin-right: 10px;
            }
        }
    
        .form-submit {
            @include flex($justifyContent: flex-start);
            
            input {
                margin: 20px 0;
            }
        }

        .logged-in-as {
            font-style: italic;
            color: $color-text--light;

            a {
                text-decoration: underline;
            }
        }
    }

    .comment-reply-title {
        #cancel-comment-reply-link {
            font-family: $font-family;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            line-height: 1.2;
            text-transform: uppercase;
            color: $color-text--light;
            margin-left: 10px;

            &:hover, &:focus {
                color: $color-link--hover;
            }
        }
    }

    .commentlist {
        .comment-body {
            @include flex($direction: column);
            margin-top: 30px;

            > p {
                order: 3;
                margin-top: 20px;
            }

            &--moderation {
                flex-direction: column-reverse;

                .comment-meta {
                    order: 3;
                }

                .comment-author {
                    margin-bottom: 0;
                }

                br {
                    display: none;
                }
            }
        }

        .comment-meta {
            order: 1;
            font-size: $font-size--text-small;
            color: $color-text--light;
        }

        .comment-author {
            order: 2;
            margin-top: 5px;
            font-size: $font-size--5;

            .fn {
                color: $color-dark;
                font-weight: $font-weight-bold;
                font-style: normal;
            }

            .says {
                color: $color-text--light;
            }
        }
        
        .reply {
            order: 4;
            margin-top: 20px;

            a.comment-reply-link {
                color: $color-text--light;
                text-decoration: underline;
                text-decoration-color: $color-text--light;
                transition: {
                    property: color, text-decoration-color;
                    duration: $duration;
                    timing-function: $timing;
                }
        
                &:hover, &:focus {
                    color: $color-link--hover;
                    text-decoration-color: transparent;
                }
            }
        }

        ul.children {
            margin-left: 30px;

            .comment-body {
                padding: 10px 0 10px 20px;
                box-sizing: border-box;
                box-shadow: -3px 0px 0px $color-main;
            }

            > li {
                > ul.children {
                    .comment-body {
                        padding: 10px 0 10px 20px;
                        box-shadow: -3px 0 0 rgba($color-main, .5);
                    }
                }
            }
        }

        .comment-awaiting-moderation {
            color: $color-text--light;
            order: 1;
            margin-bottom: 20px;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;

        &-top {
            .comments {
                &__nav-left,
                &__nav-right {
                    a {
                        margin-top: 30px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        
        &-bottom {
            .comments {
                &__nav-left,
                &__nav-right {
                    a {
                        margin-top: 50px;
                    }
                }
            }
        }

        &-left,
        &-right {
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                font-weight: $font-weight-bold;
                font-size: $font-size--text-small;
                text-transform: uppercase;
                color: $color-text--light;

                &:hover, &:focus {
                    color: $color-dark;
    
                    svg {
                        fill: $color-dark;
                    }
                }
            }

            svg {
                height: 26px;
                width: 26px;
                fill: $color-text--light;
                transition: $duration;
            }
        }


        &-left {
            svg {
                margin-right: 8px;
            }
        }

        &-right {
            svg {
                transform: rotate(180deg);
                margin-left: 8px;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================


// 640
@media screen and (max-width: $small) {

    .comments {
        .commentlist {
            .children {
                .comment-body {
                    padding: 10px 0 10px 20px;
                }
            }
        }
    }

}

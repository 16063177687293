.breadcrumb {
    ul {
        display: block;
    }

    li {
        position: relative;
        display: inline;
        color: $breadcrumb-color;
        line-height: 1.75;

        a {
            color: $breadcrumb-color;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: {
                property: color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                color: $breadcrumb-color--hover;
                text-decoration-color: $breadcrumb-color--hover;
            }
        }

        svg {
            display: inline-block;
            fill: $breadcrumb-color;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}

//======================================================================================================
// Bloc Contextual publication
//======================================================================================================
.contextual-publications {
    margin: 30px 0;

    .grid-center {
        width: calc(100% + 30px);
    }
    
    .swiper-wrapper {
        height: auto;
    }
    
    .swiper-slide {
        height: auto;

        .grid, .card, .card__link, .card__container, .card__content {
            height: 100%;
        }
    }
    
}

.card-embed-wrapper {
    margin: 20px 0 15px 0;

    .swiper-container {
        margin: -15px;
        padding: 15px;
    }

    .swiper-pagination {
        position: relative;
        bottom: 0 !important;
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active {
        @include size(12px);
        background-color: transparent;
        border: 1px $border-style $color-dark;
        border-radius: $border-radius--icon;
        margin: 0 5px;
        opacity: 1;
        transition: background-color $duration $timing;

        &:hover, &:focus {
            background-color: $color-dark;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: $color-dark;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .contextual-publications {    
        .swiper-slide .card__container {
            &--event-v1 {
                min-height: inherit;
            }
        }
    }

}

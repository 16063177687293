.m-banner {
    margin: $m-banner_section-margin;
    padding: $m-banner_section-padding;
    background: $m-banner_section-bg;

    &__wrapper {
        @include flex($alignItems: $m-banner_wrapper-alignItems, $justifyContent: $m-banner_wrapper-justifyContent);
        gap: $m-banner_wrapper-gap;
        margin: $m-banner_wrapper-margin;
        padding: $m-banner_wrapper-padding;
        background: $m-banner_wrapper-bg;
        border: $m-banner_wrapper-border;
        border-radius: $m-banner_wrapper-radius;
        box-shadow: $m-banner_wrapper-shadow;
    }
    
    &__links {
        @include flex($wrap: $m-banner_links-wrap, $alignItems: $m-banner_links-alignItems, $justifyContent: $m-banner_links-justifyContent);
        gap: $m-banner_links-gap;
        flex: none;
    }
    
    &__title {
        padding-bottom: 0;

        &::after {
            display: none;
        }
    }

    &__infos {
        @include flex($direction: column);
        gap: 10px;
    }

    &__social {
        background-color: $m-banner__socials_btn-bg;
        border-color: $m-banner__socials_btn-bd-color;
        
        .button__icon {
            fill: $m-banner__socials_btn-color;
        }

        &:hover, &:focus {
            background-color: $m-banner__socials_btn-bg--hover;
            border-color: $m-banner__socials_btn-bd-color--hover;
            
            .button__icon {
                fill: $m-banner__socials_btn-color--hover;
            }
        }
    }

    &__buttons {
        justify-content: $m-banner_buttons-justifyContent;
    }

    &__button {
        background-color: $m-banner_buttons_btn-bg;
        border-color: $m-banner_buttons_btn-bd-color;
        color: $m-banner_buttons_btn-color;
        
        .button__icon {
            fill: $m-banner_buttons_btn-color;
        }

        &:hover, &:focus {
            background-color: $m-banner_buttons_btn-bg--hover;
            border-color: $m-banner_buttons_btn-bd-color--hover;
            color: $m-banner_buttons_btn-color--hover;
            
            .button__icon {
                fill: $m-banner_buttons_btn-color--hover;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-banner {
        &__wrapper {
            padding: $m-banner_wrapper-padding--1200;
            gap: $m-banner_wrapper-gap--1200;
        }

        &__links {
            flex-wrap: $m-banner_links-wrap--1200;
            gap: $m-banner_links-gap--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-banner {
        &__wrapper {
            flex-wrap: wrap;
            padding: $m-banner_wrapper-padding--960;
        }

        &__links {
            flex: unset;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-banner {
        &__wrapper {
            padding: $m-banner_wrapper-padding--640;
        }
    }

}

.bloc-title {
    margin: 30px 0 0 0;

    &.h2 {    
        + .bloc-content--simple {
            margin-top: 20px;
        }
    }

    &.h3 {
        + .bloc-content--simple {
            margin-top: 15px;
        }
    }

    &.h4 {
        + .bloc-content--simple {
            margin-top: 10px;
        }
    }
}

.c-admin_area {
    @include flex($alignItems: center);

    &__image {
        flex: none;
        @include flex($justifyContent: center, $alignItems: center);
        min-height: unset;
        background-color: $color-bg--image;
        border-radius: $border-radius--image;
        overflow: hidden; 

        picture, img {
            @include size(100%);
            object-fit: cover;
            //object-position: top;
            transform: none;
            transition: transform $duration--second $timing;
        }
    }

    &__figure {
        position: relative;
        margin: 0;
    }
    
    &__icon {
        @include absolute($top: 10px, $right: 10px);
        background-color: $color-white;
        border-radius: $border-radius--icon;
        fill: $color-text;
    }

    &__figcaption {
        @include absolute($bottom: 20px);
        margin-left: 20px;
        margin-right: 20px;
        padding: $c_admin_area__caption-padding;
        background-color: $c_admin_area__caption-bg;
        border: $c_admin_area__caption-border;
        border-radius: $border-radius--second;
        color: $c_admin_area__caption-color;
        text-align: center;
    }

    &__content {
        box-sizing: border-box;
        @include flex($direction: column, $justifyContent: center);
        width: 100%;
    }

    &--link {
        &:hover, &:focus {
            .c-admin_area__image img {
                transform: scale(1.05);
            }
        }
    }

    &--video {
        .c-admin_area__figure {           
            cursor: pointer;

            .c-popup_area__button {
                background-color: $c_admin_area__video_btn-bg;
                border-color: $c_admin_area__video_btn-bd-color;

                svg {
                    fill: $c_admin_area__video_btn-color;
                }
            }
            
            &:hover, &:focus-within {
                .c-popup_area__button {
                    background-color: $c_admin_area__video_btn-bg--hover;
                    border-color: $c_admin_area__video_btn-bd-color--hover;
    
                    svg {
                        fill: $c_admin_area__video_btn-color--hover;
                    }
                }
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .c-admin_area {    
        &--link {
            &:hover, &:focus {
                .c-admin_area__image img {
                    transform: none;
                }
            }
        }
    }

}

.container {
    @extend %container;
}

#sprite-svg {
    @extend %visuallyhidden;
}

body.js-open_popupArea {
    overflow: hidden;
}

.content-wrapper {
    margin-top: $header-height;
    
    &--full_screen_under_menu {
        margin-top: 0;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .content-wrapper {
        margin-top: 0;
    }

    .site-map-template .menu__nav__list .sub-menu .nav-drop.container, 
    .site-map-template .menu__nav__list .sub-menu .nav-drop .nav-grandchild.container {
        max-width: 100%;
    }

}


// 640
@media screen and (max-width: $small) {

   

}

//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin: 20px 0;

    &__item {
        position: relative;
    }

    &__btn {
        @include flex($alignItems: center);
        column-gap: 15px;
        height: 60px;
        margin: 0;
        padding: 0 25px;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        font-size: $font-size--text-small;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color-text--light;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: {
            property: background-color, color, text-decoration-color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover, &:focus {
            color: $color-text;
            text-decoration-color: $color-text;

            .actions-post__icon {
                fill: $color-text;
            }
        }

        &.action-share {
            transition: {
                property: background-color, color, text-decoration-color opacity;
                duration: $duration, $duration, $duration, 0s;
                timing-function: $timing;
            } 
        }
    }

    svg.actions-post__icon {
        @include size(26px);
        fill: $color-text--light;
        transition: fill $duration $timing;
    }

    // Share list open
    &.shared-on {
        .action-share {
            opacity: 0;
        }

        .ac-share {
            &__list {
                display: flex;
            }
        }
    }
}

// Share list
.ac-share {

    &__list {
        @include absolute($top: 10px, $left: 30px);
        display: none;
        column-gap: 10px;
    }

    &__item {
        border-right: 0;

        &--copy {
            position: relative;

            #post_url {
                position: absolute;
                opacity: 0;
                visibility: hidden;
            }
            
            .copy-confirm {
                width: 105px;
                height: 30px;
                position: absolute;
                top: 35px;
                left: 50%;
                transform: translate(-50%, 0);
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                color: $color-white;
                text-transform: none;
                background-color: $color-dark;
                opacity: 0;
                box-sizing: border-box;
        
                &.show-hide {
                    animation : show-hide 2s;
                }
            }
        }
    }

    &__btn {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $btn-bg;
        border: $btn-border-width $btn-border-style $btn-border-color;
        border-radius: $border-radius--icon;
        opacity: 1;
        cursor: pointer;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(32px);
            margin: 0;
            fill: $btn-color;
            transition: fill $duration $timing;
        }

        &:hover, 
        &:focus {
            background-color: $btn-bg--hover;
            border-color: $btn-border-color--hover;

            svg {
                fill: $btn-color--hover;
            }
        }

        &--close {
            border-color: $color-light;
            background-color: $color-light;

            svg {
                fill: $color-text; 
            }

            &:hover, 
            &:focus {
                background-color: $color-dark;
                border-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

section[data-cpt=maps] .actions-post {
    margin: 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

    // 960
    @media screen and (max-width: $medium) {

        .actions-post {
            &.shared-on {
                margin: 60px auto 20px;

                .action-share {
                    opacity: 1;
                }

                .share-list {
                    top: -50px;
                    left: -120px;
                    // filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
                }
            }
        }

    }



    // 640
    @media screen and (max-width: $small) {

        .actions-post {
            column-gap: 30px;
            margin: 20px auto;

            &__item {
                border-right: 0;
            }

            &__btn {
                width: 40px;
                height: 40px;
                justify-content: center;
                background-color: $btn-second-bg;
                border: $btn-border-width $btn-border-style $btn-second-border-color;
                border-radius: $border-radius--icon;
                padding: 0;

                &:hover, 
                &:focus, 
                &:active {
                    background-color: $btn-second-bg--hover;
                    border-color: $btn-second-border-color--hover;

                    svg.actions-post__icon {
                        fill: $btn-second-color--hover;
                    }
                }
            }

            &__text {
                display: none;
            }

            svg.actions-post__icon {
                fill: $btn-second-color;
            }

            &.shared-on {
                .ac-share {
                    &__list {
                        left: -195px;
                    }
                }
            }
        }
    }
.m-fp-4_identical {
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 20px;
    }
    
    .m-4_identical__button {
        background-color: $m-fp-4_identical_btn-bg;
        border-color: $m-fp-4_identical_btn-bd-color;
        color: $m-fp-4_identical_btn-color;
    
        &:hover, &:focus {
            background-color: $m-fp-4_identical_btn-bg--hover;
            border-color: $m-fp-4_identical_btn-bd-color--hover;
            color: $m-fp-4_identical_btn-color--hover;
        }

        &--prop {
            background-color: $m-fp-4_identical_btn_prop-bg;
            border-color: $m-fp-4_identical_btn_prop-bd-color;
            color: $m-fp-4_identical_btn_prop-color;
        
            &:hover, &:focus {
                background-color: $m-fp-4_identical_btn_prop-bg--hover;
                border-color: $m-fp-4_identical_btn_prop-bd-color--hover;
                color: $m-fp-4_identical_btn_prop-color--hover;
            }
     
        }
    }

    // If model without category & description
    &__item:not(:has(.card__description)) {
        .card__title {
            font-size: $font-size--3;
        }

        .card__content--has-category {
            .card__title {
                font-size: $font-size--4;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fp-4_identical__list {
        grid-column-gap: 20px;
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fp-4_identical {
        &__list {
            @include flex($direction: column);
        }
    }

}

//======================================================================================================
// Post date
//======================================================================================================
.publication-date {
    display: flex;
    align-items: center;
    margin: 15px 0 0 0;
    color: $color-date--single;

    svg {
        width: 26px;
        height: 26px;
        margin-right: 5px;
        fill: $color-date--single;
    }
}


//======================================================================================================
// Tags
//======================================================================================================
.tags {
    margin: 60px 0 50px;

    &__list {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        gap: 10px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .actions-event {
        margin-top: 10px;
        margin-bottom: 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .card--event {
        .image-wrapper {
            width: 100%;
        }
    }

}

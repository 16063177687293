.m-fa-1_sliding_bloc {
    
    &--with_title {
        .m-1_sliding_bloc__nav {
            position: relative;
            margin-top: $m-fa-1_sliding_bloc_nav_btn-margin-top;
            min-height: 40px;
        }
    }

    .m-1_sliding_bloc {
        &__nav {
            .nav-swiper--prev, .nav-swiper--next {
                background-color: $m-fa-1_sliding_bloc_nav_btn-bg;
                border-color: $m-fa-1_sliding_bloc_nav_btn-bd-color;
    
                .button__icon {
                    fill: $m-fa-1_sliding_bloc_nav_btn-color;
                }
            
                &:hover, &:focus {
                    background-color: $m-fa-1_sliding_bloc_nav_btn-bg--hover;
                    border-color: $m-fa-1_sliding_bloc_nav_btn-bd-color--hover;
    
                    .button__icon {
                        fill: $m-fa-1_sliding_bloc_nav_btn-color--hover;
                    }
                }
            }
        }

        &__buttons {
            justify-content: $m-fa-1_sliding_bloc-justifyContent;
        }
    
        &__button {
            background-color: $m-fa-1_sliding_bloc_btn-bg;
            border-color: $m-fa-1_sliding_bloc_btn-bd-color;
            color: $m-fa-1_sliding_bloc_btn-color;
            
            .button__icon {
                fill: $m-fa-1_sliding_bloc_btn-color;
            }
    
            &:hover, &:focus {
                background-color: $m-fa-1_sliding_bloc_btn-bg--hover;
                border-color: $m-fa-1_sliding_bloc_btn-bd-color--hover;
                color: $m-fa-1_sliding_bloc_btn-color--hover;
                
                .button__icon {
                    fill: $m-fa-1_sliding_bloc_btn-color--hover;
                }
            }
        }
    }
    
    &__content {
        margin: 0 !important; // swiper override
    }

    &__item {
        height: auto !important; // swiper override
    }

    .c-admin_area {

        &__image {
            position: relative;
            @include size(58%, 410px);
            border-radius: $m-fa-1_sliding_bloc_image-radius;
        }

        &__content {
            position: relative;
            align-items: flex-start;
            gap: 10px;
            min-height: $m-fa-1_sliding_bloc_content-min-height;
            margin-left: -30px;
            padding: $m-fa-1_sliding_bloc_content-padding;
            background-color: $m-fa-1_sliding_bloc_content-bg;
            border: $m-fa-1_sliding_bloc_content-bd;
            border-radius: $m-fa-1_sliding_bloc_content-radius;
            box-shadow: $m-fa-1_sliding_bloc_content-shadow;
            transition: {
                property: background-color, border-color, color, box-shadow;
                duration: $duration--second;
                timing-function: $timing;
            }
        }

        &__title {
            font-family: $m-fa-1_sliding_bloc_title-ffamily;
            font-weight: $m-fa-1_sliding_bloc_title-fweight;
            font-size: $m-fa-1_sliding_bloc_title-fsize;
            line-height: $m-fa-1_sliding_bloc_title-lineheight;
            color: $m-fa-1_sliding_bloc_title-color;
        }

        &__description {
            font-family: $m-fa-1_sliding_bloc_desc-ffamily;
            font-weight: $m-fa-1_sliding_bloc_desc-fweight;
            font-size: $m-fa-1_sliding_bloc_desc-fsize;
            line-height: $m-fa-1_sliding_bloc_desc-lineheight;
            color: $m-fa-1_sliding_bloc_desc-color;
        }

        &__icon {
            @include absolute($top: 10px, $right: 10px);
            background-color: $m-fa-1_sliding_bloc_icon-bg;
            border-radius: $border-radius--icon;
            fill: $m-fa-1_sliding_bloc_icon-color;
        }

        &:has(.c-admin_area__content) {
            .c-admin_area__icon {
                bottom: 10px;
                right: 10px;
                top: unset;
            }
        }

        &--link {
            &:hover, &:focus {
                .c-admin_area__content {
                    background-color: $m-fa-1_sliding_bloc_content-bg--hover;
                    border-color: $m-fa-1_sliding_bloc_content-bd-color--hover;
                    box-shadow: $m-fa-1_sliding_bloc_content-shadow--hover;
                    color: $m-fa-1_sliding_bloc_content-color--hover;
                }
            }
        }
    }
   
    .swiper {
        margin: $swiper-margin;
        padding: $swiper-padding;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fa-1_sliding_bloc {
        .c-admin_area {
            &__image {
                height: 350px;
            }

            &__content {
                min-height: 240px;
                margin-left: -60px;
                padding: $m-fa-1_sliding_bloc_content-padding--1200;
            }
        }

        .swiper {
            margin: $swiper-margin--1200;
            padding: $swiper-padding--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fa-1_sliding_bloc {
        .c-admin_area {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            &__image {
                @include size(100%, 360px);
            }
            
            &__content {
                width: inherit;
                min-width: 80%;
                min-height: $m-fa-1_sliding_bloc_content-min-height--960;
                margin: -50px 30px 0 30px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fa-1_sliding_bloc {

        &--with_title .m-1_sliding_bloc__nav {
            position: unset;
            margin-top: 0;
        }

        .c-admin_area {           
            &__image {
                height: 190px;
            }
            
            &__content {
                width: 100%;
                min-width: unset;
                margin: -30px 0 0 0;
                padding: $m-fa-1_sliding_bloc_content-padding--640;
            }

            &:has(.c-admin_area__content) {
                .c-admin_area__icon {
                    bottom: 5px;
                    right: 5px;
                }
            }
        }
    }

}

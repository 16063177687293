.nav-main {
    margin-left: auto;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
    }

    // Main menu
    .menu {
        @include flex($wrap: wrap, $alignItems: center);
        gap: $smenu_gap;

        &__item {
            &.menu-item-has-children {

                .menu__link::before {
                    content: "";
                    @include absolute($top: 78px);
                    z-index: -1;
                    @include size(30px);
                    border-radius: 7px;
                    background-color: $smenu_bg;
                    transform: rotate(45deg);
                    visibility: hidden;
                    opacity: 0;
                    pointer-events: none;
                    transition: {
                        property: top, visibility, opacity, pointer-events;
                        duration: 0s;
                        timing-function: $timing;
                        delay: 0s;
                    }
                }

                &.js-open-submenu {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: inherit;
                    }
    
                    .menu__link {
                        &::before {
                            opacity: 1;
                            pointer-events: inherit;
                            visibility: visible;
                            top: 68px;
                            transition-delay: .1s;
                            transition-duration: $duration;
                        }
                    }
                }
            }

            &:last-child {
                .menu__link {
                    min-height: $smenu_menu_link_last_item-min-height ;
                    padding: $smenu_menu_link_last_item-padding;
                    background-color: $smenu_menu_link_last_item-bg;
                    border: $smenu_menu_link_last_item-border;
                    border-radius: $smenu_menu_link_last_item-radius;
                    color: $smenu_menu_link_last_item-color;

                    transition: {
                        property: background-color, border-color, color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover, &:focus {
                        background-color: $smenu_menu_link_last_item-bg--hover;
                        border-color: $smenu_menu_link_last_item-border-color--hover;
                        color: $smenu_menu_link_last_item-color--hover;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: center);
            padding: $smenu_menu_link-padding;
            font-family: $smenu_menu_link-ffamily;
            font-weight: $smenu_menu_link-fweight;
            font-size: $smenu_menu_link-fsize;
            line-height: $smenu_menu_link-lineheight;
            text-transform: $smenu_menu_link-ttransform;
            color: $smenu_menu_link-color;
            transition: color $duration $timing;

            &:hover, &:focus {
                color: $smenu_menu_link-color--hover;
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        @include absolute($top: $header-height, $left: 0, $right: 0);
        z-index: 1;
        width: 100%;
        padding: 50px 15px 30px 15px;
        background-color: $color-main;
        box-shadow: $shadow;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: top, visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &::before {
            content: '';
            @include absolute($top: 0, $bottom: 0);
            display: block;
            width: 100vw;
            background-color: $smenu_bg;
            z-index: -1;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            position: relative;
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
            
            &--child {
                &::before {
                    content: "";
                    display: inherit;
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    background-color: $smenu_submenu_link-before--color;
                    transition: width $duration $timing;
                }
            }

            &:hover, &:focus-within {
                &::before {
                    width: 100%;
                }
            }
        }

        &__link {
            @include flex($alignItems: center, $justifyContent: space-between);
            @include size(100%, auto);
            min-height: 50px;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: $smenu_submenu_link-padding;
            border-bottom: $smenu_submenu_link-border-bottom;
            font-family: $smenu_submenu_link-ffamily;
            font-weight: $smenu_submenu_link-fweight;
            font-size: $smenu_submenu_link-fsize;
            line-height: $line-height;
            color: $smenu_submenu_link-color;
            transition: {
                property: padding, color, opacity;
                duration: $duration;
                timing-function: $timing;
            }

            &__icon {
                fill: $smenu_submenu_link_icon-color;
                opacity: $smenu_submenu_link_icon-opacity;
                transition: {
                    property: opacity, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                color: $smenu_submenu_link-color--hover;

                .submenu__link__icon {
                    fill: $smenu_submenu_link_icon-color--hover;
                    opacity: $smenu_submenu_link_icon-opactiy--hover;
                }
            }

            &--child {
                &:hover, &:focus {
                    padding-left: 15px;
                }
            }

            &--grandchild {
                justify-content: flex-start;
                padding: 10px 0 10px 10px;
                border-bottom: 0;
                font-size: $smenu_submenu_link-fsize--grandchild;
                font-weight: $smenu_submenu_link-fweight--grandchild;
                text-transform: initial;
                text-decoration: underline;
                text-decoration-color: transparent;

                transition: {
                    property: color, text-decoration-color;
                    duration: $duration;
                    timing-function: $timing;
                }

                &::before {
                    display: none;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration-color: $smenu_submenu_link-color--hover;
                }
            }
        }
    }

    .nav-button {
        &__menu-open, &__menu-close {
            display: none;
        }

        &__menu-close, &__submenu-close {
            background-color: $smenu_button_close-bg;
            border-color: $smenu_button_close-border-color;

            .button__icon {
                fill: $smenu_button_close-color;
            }

            &:hover, &:focus {
                background-color: $smenu_button_close-bg--hover;
                border-color: $smenu_button_close-border-color--hover;

                .button__icon {
                    fill: $smenu_button_close-color--hover;
                }
            }
        }

        &__submenu-close {
            @include absolute($top: 10px);
            @extend %SpaceFull-to-Container--right;
            @include size(30px);

            &--desktop {
                display: block;
            }

            &--mobile {
                display: none;
            }
        }
    }

}

.header--fixed {
    .nav-main .submenu {
        top: $header-height--fixed;
    }

    .nav-main .menu__item.menu-item-has-children.js-open-submenu .menu__link:before {
        top: 60px;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            gap: $smenu_gap--1200;

            &__link {
                font-size: $smenu_menu_link-fsize--1200;
            }

            &__item.menu-item-has-children.js-open-submenu .menu__link:before {
                top: 72px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }

    .nav-main {
        position: relative;

        .nav-button {
            &__menu-open, &__menu-close {
                display: flex;
            }

            &__menu-close, &__submenu-close {    
                @include absolute($top: 30px);
                z-index: 11;

                &:hover, &:focus {
                    background-color: $smenu_button_close-bg;
                    border-color: $smenu_button_close-border-color;
    
                    .button__icon {
                        fill: $smenu_button_close-color;
                    }
                }
            }

            &__menu-open {
                height: 45px;
                padding: $btn-padding !important;
                font-family: $bmenu_button_open-ffamily;
                font-weight: $bmenu_button_open-fweight;
                font-size: $bmenu_button_open-fsize;
                text-transform: $bmenu_button_open-ttransform;
                background-color: $bmenu_button_open-bg;
                border-color: $bmenu_button_open-border-color;
                color: $bmenu_button_open-color;
                transition: all $duration $timing;
                
                svg {
                    fill: $bmenu_button_open-color;
                }

                &:hover,
                &:focus {
                    background-color: $bmenu_button_open-bg--hover;
                    border-color: $bmenu_button_open-border-color--hover;
                    color: $bmenu_button_open-color--hover;

                    svg {
                        fill: $bmenu_button_open-color--hover;
                    }
                }
            }

            &__menu-close {
                @include size(40px);
                right: calc(5% + 10px);
            }
            
            &__submenu-close {
                left: calc(5% + 10px);
                min-height: auto;
                @include size(40px);

                &--desktop {
                    display: none;
                }
    
                &--mobile {
                    display: block;
                    fill: $color-main;
                }
            }
        }

        // If menu open
        &.js-open-menu {
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            @include fixed($top: 0, $left: -100vw);
            z-index: 10;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 15px;
            max-width: inherit;
            @include size(100%, 100vh);
            background-color: $smenu_bg--resp;
            padding: 0;
            transition: left $duration--second $timing;
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            max-width: 90%;
            margin: 0 auto;
            overflow-y: auto;
            padding: 100px 40px 40px;
            row-gap: 20px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;

                &.menu-item-has-children {

                    .menu__link {
                        gap: 15px;

                        &::after {
                            content: "";
                            @include size(20px);
                            mask-image: url($urlShort + 'ic-nav-chevron-right.svg');
                            mask-position: center;
                            mask-size: contain;
                            mask-repeat: no-repeat;
                            background-color: $color-text--main;
                        }
                    }

                    // If sub menu open
                    &.js-open-submenu {
                        .submenu {
                            left: 0;
                        }
                    }
                }

                &:last-child {
                    .menu__link {
                        padding: 5px 0;
                        background-color: transparent;
                        border: 0;
                        color: $color-text--main;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 5px 0;
                color: $color-text--main;
                transition: none;
                font-size: $smenu_menu_link-fsize--960;
                text-transform: none;

                &::before {
                    display: none;
                }

                &:hover, &:focus {
                    color: $color-text--main !important;
                }
            }
        }

        // Sub menu
        .submenu {
            @include absolute($top: 0, $left: -100vw, $right: unset);
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100%, 100vh);
            padding: 100px 40px 40px;
            background-color: $smenu_bg--resp;
            opacity: 1;
            visibility: visible;
            pointer-events: inherit;
            transition: left $duration--second $timing;

            &::before {
                display: none;
            }

            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: 5px;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 0;
            }

            &__item {
                width: 100%;
                
                &::before {
                    display: none;
                }
            }

            &__link {
                width: 100%;
                padding: 5px 0;
                min-height: unset;
                flex-direction: row-reverse;
                justify-content: flex-end;
                gap: 10px;
                border: 0;

                
                &__icon {
                    @include size(20px);
                }

                &--child {
                    &:hover, &:focus {
                        padding-left: 0
                    }
                }

                &--grandchild {
                    padding-left: 30px;

                    .submenu__link__icon {
                        display: none;
                    }
                }
            }
        }
    }

    // If admin logged
    .admin-bar .nav-main {
        &__container {
            top: 32px;
        }

        .submenu {
            height: calc(100vh - 32px);
        }
    }

}



// 782 -- For admin bar only
@media screen and (max-width: 782px) {

    // Admin 
    .admin-bar .nav-main__container {
        top: 46px !important;
    }

    .admin-bar .nav-main .menu {
        min-height: calc(100vh - 46px);
    }

    .admin-bar .nav-main .submenu {
        height: calc(100vh - 46px);
    }

}


// 640
@media screen and (max-width: $small) {

    .nav-main {
        margin-left: unset;

        .nav-button__submenu-close,
        .nav-button__menu-close {
            top: 20px;
        }

        .nav-button {
            &__menu-open {
                &:hover,
                &:focus {
                    background-color: $bmenu_button_open-bg;
                    border-color: $bmenu_button_open-border-color;
                    color: $bmenu_button_open-color;

                    svg {
                        fill: $bmenu_button_open-color;
                    }
                }
            }

            &__menu-close, &__submenu-close {
                &:hover, &:focus {
                    background-color: $color-text--main;
                    border-color: $color-text--main;

                    .button__icon {
                        fill: $color-main;
                    }
                }
            }
        }

        .menu {
            padding: 80px 20px 40px;
        }

        .submenu {
            padding-top: 80px;
        }
    }

}

.m-mixed_publications {
    
    &__content {
        display: flex;
        gap: 30px;

        &__large {
            display: flex;
            gap: 30px;
        }

        &__small {
            @include flex($direction: column);
            gap: 20px;
            min-width: 370px;
            max-width: 370px;
        }
    }

    &__item {
        flex: 1;
    }

    &__button {
        &--post {
            background-color: $m-mixed_pub_post_btn-bg;
            border-color: $m-mixed_pub_post_btn-bd-color;
            color: $m-mixed_pub_post_btn-color;
        
            &:hover, &:focus {
                background-color: $m-mixed_pub_post_btn-bg--hover;
                border-color: $m-mixed_pub_post_btn-bd-color--hover;
                color: $m-mixed_pub_post_btn-color--hover;
            }
        }

        &--event {
            background-color: $m-mixed_pub_event_btn-bg;
            border-color: $m-mixed_pub_event_btn-bd-color;
            color: $m-mixed_pub_event_btn-color;
        
            &:hover, &:focus {
                background-color: $m-mixed_pub_event_btn-bg--hover;
                border-color: $m-mixed_pub_event_btn-bd-color--hover;
                color: $m-mixed_pub_event_btn-color--hover;
            }
        }

        &--prop {
            &--post {
                background-color: $m-mixed_pub_post_btn_prop-bg;
                border-color: $m-mixed_pub_post_btn_prop-bd-color;
                color: $m-mixed_pub_post_btn_prop-color;
            
                &:hover, &:focus {
                    background-color: $m-mixed_pub_post_btn_prop-bg--hover;
                    border-color: $m-mixed_pub_post_btn_prop-bd-color--hover;
                    color: $m-mixed_pub_post_btn_prop-color--hover;
                }
            }

            &--event {
                background-color: $m-mixed_pub_event_btn_prop-bg;
                border-color: $m-mixed_pub_event_btn_prop-bd-color;
                color: $m-mixed_pub_event_btn_prop-color;
            
                &:hover, &:focus {
                    background-color: $m-mixed_pub_event_btn_prop-bg--hover;
                    border-color: $m-mixed_pub_event_btn_prop-bd-color--hover;
                    color: $m-mixed_pub_event_btn_prop-color--hover;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-mixed_publications__content,
    .m-mixed_publications__content__large {
        gap: 20px;
    }

    .m-mixed_publications__content__small {
        min-width: 300px;
        max-width: 300px;
    }
    
    .m-post_events {
        .m-mixed_publications__content__small .card {
            &__container {
                padding: $m-mixed_pub_event_only_card__container-padding--1200;
            }

            &__content {
                padding: $m-mixed_pub_event_only_card__content-padding--1200;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .m-mixed_publications {
        &__content {
            flex-direction: column;

            &__small {
                max-width: inherit;
                min-width: inherit;

                .card__content {
                    min-height: 140px;
                }
            }
        }
    }

    .m-post_events {
        .m-mixed_publications__content__small .card {
            &__container {
                padding: $m-mixed_pub_event_only_card__container-padding--640;
            }

            &__content {
                padding: $m-mixed_pub_event_only_card__content-padding--640;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-mixed_publications {        
        &__content__large {
            flex-direction: column;
        }
    }

}

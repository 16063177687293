.c-proposition {
    margin: 30px 0;

    &__wrapper {
        @include flex($alignItems: center, $justifyContent: space-between);
        min-height: 120px;
        background-color: $c_proposition-bg;
        border: $c_proposition-border;
        border-radius: $border-radius;
        box-shadow: $c_proposition-boxshadow;
    }

    &__icon {
        height: 70px;
        max-width: 100px;
        margin: 25px 45px;
        fill: $c_proposition-color;
    }

    &__content {
        flex: 1;
        @include flex($alignItems: center);
    }
    
    &__text {
        max-width: 730px;
        padding: 20px 0;
        color: $c_proposition-color;
    }

    .a-subinfo {
        color: $c_proposition-color;

        .icon {
            fill: $c_proposition-color;
        }
    }

    &__date {
        font-weight: $font-weight-bold;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--4;
        line-height: $line-height--heading;
        color: $c_proposition-color;
        margin-bottom: 10px;
    } 

    &__intro {
        font-size: $font-size--text-small;
        color: $c_proposition-color;
        margin: 0;

        span {
            font-weight: $font-weight-bold;
        }
    }

    .button {
        margin: 30px 40px;
        background-color: $c_proposition_btn-bg;
        border-color: $c_proposition_btn-bd-color;
        color: $c_proposition_btn-color;

        .button__icon {
            fill: $c_proposition_btn-color;
        }

        &:hover, &:focus {
            background-color: $c_proposition_btn-bg--hover;
            border-color: $c_proposition_btn-bd-color--hover;
            color: $c_proposition_btn-color--hover;

            .button__icon {
                fill: $c_proposition_btn-color--hover;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960 // à revoir en structure html
@media screen and (max-width: $medium) {

    .c-proposition {
        &__wrapper {
            flex-direction: column;
            align-items: flex-start;
            padding: 30px;
        }

        &__content {
            @include flex($direction: column, $alignItems: baseline);
            width: 100%;
            padding: 0;  
        }

        &__text {
            width: 100%;
            padding: 0;
        }

        &__icon {
            display: none;
        }

        .button {
            margin: 20px 0 0 0;
        }
            
    }

}

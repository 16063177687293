//-------------------------------------------------------------------- //
// Document single
//-------------------------------------------------------------------- //
.card {

    &--document {
        margin-bottom: 20px;

        &__button {
            margin: 20px 0 0 0;
        }

        .accordion--transcription {
            margin: 20px 0 0 0;
        }
    }

    &__container {
        &--document {
            min-height: 0;
            background-color: $card-bg !important;
            box-shadow: $card-shadow !important;
        }
    }

    &__content {
        &--document {
            display: block;
            color: $card-color;
            background-color: $card-bg !important;
        }
    }


    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.documents,
        &.documents_category {
            background-image: url($urlShort + '/documents-icons/documents.svg');
        }
    }
    
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .card--document {
        .accordion--transcription {
            margin-top: 25px;
        }
    }

}

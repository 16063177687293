.c-docs {
    @include flex($alignItems: center);

    &__image {
        position: relative;
        flex: none;
        overflow: hidden;
        @include size(205px, 290px);
        border-radius: $c_document_image-radius;
        box-shadow: $c_document_image-shadow;

        img {
            @include size(100%);
            object-fit: cover;
            object-position: center;
        }

        &--no-image {
            @include flex($alignItems: center, $justifyContent: center);
            background-color: $color-bg--image;
        
            svg {
                width: 35%;
            }
        }
    }

    &__content {
        box-sizing: border-box;
        @include flex($direction: column, $justifyContent: center);
        gap: 15px;
        width: 100%;
        min-height: 230px;
        padding: $c_document_content-padding;
        background-color: $c_document_content-bg;
        border-radius: $c_document_content-radius;
        box-shadow: $c_document_content-shadow;
    }

    &__title {
        margin: 0;
        padding: 0;
        font-family: $c_document_title-ffamily;
        font-weight: $c_document_title-fweight;
        font-size: $c_document_title-fsize;
        line-height: $c_document_title-lineheight;
        text-transform: $c_document_title-ttransform;
        color: $c_document_title-color;
    }

    &__infos {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 5px 15px;
    }

    &__date, &__extension {
        color: $c_document_subinf-color;

        .icon {
            fill: $c_document_subinf-color;
        }
    }

    &__extension {
        span {
            text-transform: uppercase;
        }
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 10px;
    }

    &__button {
        background-color: $c_document_button-bg;
        border-color: $c_document_button-bd-color;

        .button__icon {
            fill: $c_document_button-color;
        }
    
        &:hover, &:focus {
            background-color: $c_document_button-bg--hover;
            border-color: $c_document_button-bd-color--hover;

            .button__icon {
                fill: $c_document_button-color--hover;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .c-docs {
        &__content {
            padding: 20px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .c-docs {
        flex-direction: column;
        align-items: flex-start;

        &__image {
            margin-left: 20px;
        }

        &__content {
            min-height: inherit;
            padding-top: 145px;
            margin-top: -130px;
            border-radius: $border-radius;
        }
    }

}
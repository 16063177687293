//======================================================================================================
// Filters
//======================================================================================================

// Filter bloc
$f-maps-bg                                  : $color-bg--neutral !default;

//===============================================
// Filter Keyword - Button search / close (no results)
//===============================================
// Colors
$maps-Keyword-close-color                   : $color-text--main;
$maps-Keyword-close-color--hover            : $color-text;

// Backgrounds
$maps-Keyword-close-bg                      : $color-main;
$maps-Keyword-close-bg--hover               : transparent;

// Borders
$maps-Keyword-close-border-color            : $maps-Keyword-close-bg;
$maps-Keyword-close-border-color--hover     : $maps-Keyword-close-border-color;


//===============================================
// Filter input (category)
//===============================================
$f-maps-input-font-size                     : $f-input-font-size;
$f-maps-input-color                         : $f-input-color;
$f-maps-input-bg                            : $f-input-bg;
$f-maps-input-border-width                  : $f-input-border-width;
$f-maps-input-border-color                  : $f-input-border-color;
$f-maps-input-border-radius                 : $f-input-border-radius;
$f-maps-input-icon                          : $f-input-icon;
$f-maps-input-container-bg                  : transparent;

// Active / is open
$f-maps-input-border-color--selected        : $color-dark;
$f-maps-input-icon--selected                : $f-input-icon--active;

// Checked filter + hover filter
$f-maps-input-color--checked                : $color-text--main;
$f-maps-input-bg--checked                   : $color-main;
$f-maps-input-border-color--checked         : $f-input-border-color--selected;
$f-maps-input-icon--checked                 : $color-text--main;

//===============================================
// Sub Filter input (subcategory)
//===============================================
$f-maps-sub-input-color                     : $f-sub-input-color;
$f-maps-sub-input-bg                        : $f-sub-input-bg;
$f-maps-sub-input-border-width              : $f-sub-input-border-width;
$f-maps-sub-input-border-radius             : $f-sub-input-border-radius;
$f-maps-sub-input-border-color              : $f-sub-input-border-color;

// Sub filter checked + hover filter
$f-maps-sub-input-color--checked            : $f-sub-input-color--checked;
$f-maps-sub-input-bg--checked               : $f-sub-input-bg--checked;
$f-maps-sub-input-border-color--checked     : $color-dark;


//======================================================================================================
// View
//======================================================================================================

    //===============================================
    // Button toggler view (list or map)
    //===============================================
    
    // Title 
    $btn-toggler-view-title-font-family         : $btn-toggler-title-font-family !default;
    $btn-toggler-view-title-font-size           : $btn-toggler-title-font-size !default;
    $btn-toggler-view-title-font-weight         : $btn-toggler-title-font-weight !default;
    $btn-toggler-view-title-line-height         : $btn-toggler-title-line-height !default;
    $btn-toggler-view-title-text-transform      : $btn-toggler-title-text-transform !default;
    $btn-toggler-view-title-color               : $btn-toggler-title-color !default;

    // Colors
    $btn-toggler-view-color                     : $btn-toggler-color !default;
    $btn-toggler-view-color--hover              : $btn-toggler-color--hover !default;

    // Backgrounds
    $btn-toggler-view-bg                        : $btn-toggler-bg !default;
    $btn-toggler-view-bg--hover                 : $btn-toggler-bg--hover !default;

    // Borders
    $btn-toggler-view-border-color              : $btn-toggler-border-color !default;
    $btn-toggler-view-border-color--hover       : $btn-toggler-border-color--hover !default;

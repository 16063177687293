.c-date {
    @include flex($alignItems: center, $justifyContent: $card_date-justifyContent);
    @include size($card_date-width, $card_date-height);
    padding: $card_date-padding;
    background-color: $card_date-bg-v1;
    border-radius: $card_date-radius;
    box-sizing: border-box;
    flex: none;
    
    &__start, &__end {
        font-family: $card_date-ffamily;
        font-weight: $card_date-fweight;
        font-size: $card_date-fsize;
        line-height: $card_date-lineheight;
        text-transform: $card_date-ttransform;
        color: $card_date-color;
    }
    
    &__icon {
        fill: $card_date-color;
    }

    &--opacity {
        background-color: $card_date-bg-v2 ; 
    }

    &--vertical {
        flex-direction: column;
        justify-content: center;
        @include size($card_date--vertical-width, $card_date--vertical-height);
        gap: 5px;
        padding: $card_date--vertical-padding;
        border-radius: $card_date--vertical-radius;

        .c-date {
            &__start, &__end {
                @include flex($direction: column, $alignItems: center, $justifyContent: center);
                gap: 3px;
            }

            &__icon {
                transform: rotate(90deg);
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .c-date {
        &--vertical {
            flex-direction: row;
            justify-content: $card_date--vertical-justifyContent--640;
            align-items: center;
            padding: $card_date--vertical-padding--640;
            @include size($card_date--vertical-width--640, $card_date--vertical-height--640);
    
            .c-date {
                &__start, &__end {
                    flex-direction: row;
                    gap: 3px;
                }
    
                &__icon {
                    transform: none;
                }
            }
        }
    }

}
.m-fd-2_documents {

    &__list {
        @include flex($alignItems: center);
        gap: 30px;
    }

    &__item {
        flex: 1;
    }

    .c-docs__content {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &__button {
        background-color: $c_document_button_sys-bg;
        border-color: $c_document_button_sys-bd-color;
        color: $c_document_button_sys-color;
    
        &:hover, &:focus {
            background-color: $c_document_button_sys-bg--hover;
            border-color: $c_document_button_sys-bd-color--hover;
            color: $c_document_button_sys-color--hover;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fd-2_documents {
        &__list {
            gap: 20px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .m-fd-2_documents {

        &__list {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }

        &__item {
            width: 100%;
        }

        .l-section__buttons {
            justify-content: flex-start;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fd-2_documents .c-docs__content {
        border-radius: $c_document_content-radius;
    }

}
.card {

    &--contact--v3 {
        .card__title {
            margin-top: 15px;
        }
    }
    
    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.contacts,
        &.contacts_category {
            background-image: url($urlSpriteImage + '/contacts-icons/contacts.svg');
        }
    }
}
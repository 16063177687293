#push-subscription {
    @include flex($direction: column, $justifyContent: center);
    gap: 20px;
    width: 100%;

    .subscription-state-message {
        display: none;
        font-style: italic;
        line-height: 1.2;
        color: $color-text--light;

        &--disabled {
            display: block;
        }

        &--iphone {
            display: block;
        }
    }

    .button-container {
        @include flex($direction: column);
        gap: 30px;
        width: 100%;
        margin-top: 25px;

        &__subscribed {
            display: none;
            gap: 20px;
        }

        #subscribe_notification_button {
            display: none;
        }

        &__message {
            display: none;
            // height: 44px;
            padding: 14px 24px;
            font-weight: $font-weight-bold;
            line-height: 1;

            &--success {
                display: block;
                background: $color-bg-success;
                border-left: 6px solid $color-success;
            }

            &--error {
                display: block;
                background: $color-bg-error;
                border-left: 6px solid $color-error;
            }
        }

        button {
            background-color: $btn-bg;
            border-color: $btn-border-color;
            color: $btn-color;
        
            svg {
                fill: $btn-color;
            }
        
            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked {
                background-color: $btn-bg--hover;
                border-color: $btn-border-color--hover;
                color: $btn-color--hover;
        
                svg {
                    fill: $btn-color--hover;
                }
            }
        }

        button.button--secondary {
            min-height: $btn-height;
            padding: $btn-padding;
            background-color: $btn-second-bg;
            border-color: $btn-second-border-color;
            color: $btn-second-color;
    
            svg {
                fill: $btn-second-color;
            }
    
            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked {
                color: $btn-second-color--hover;
                background-color: $btn-second-bg--hover;
                border-color: $btn-second-border-color--hover;
    
                svg {
                    fill: $btn-second-color--hover;
                }
            }
        }
        
    }
}

.push-subs {

    &__list {
        @include flex($direction: column, $justifyContent: center);
        gap: 20px;

        & > li {
            @include flex($direction: column);
            width: 100%;
            background-color: $color-bg--neutral;
            border-radius: $border-radius;
            // list-style-type: none; useful ?
    
            .top-card {
                @include flex($alignItems: center, $justifyContent: space-between);
                gap: 20px;
                padding: 0 20px;
                height: 70px;
    
                &--general {
                    height: auto;
    
                    div {
                        padding: 20px 0;
                    }
                }

                &__title {
                    margin: 0;
                }

                &__subtitle {
                    display: block;
                    margin-top: 5px;
                    color: $color-text--light;
                    font-size: $font-size--text;
                    font-style: italic;
                }
    
                &__switch {
                    flex: none;
                    position: relative;
                    display: inline-block;
                    @include size(48px, 26px);
                    margin: 0;
                    border-radius: 34px;
                }
    
                &__input {
                    opacity: 0;
                    @include size(0);
                }
    
                &__slider {
                    @include absolute($top: 0, $left: 0, $right: 0, $bottom: 0);
                    background-color: transparent;
                    border: 1px $border-style $color-text--light;
                    border-radius: 34px;
                    transition: {
                        property: background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }
                    cursor: pointer;
                    box-sizing: border-box;
    
                    &::before {
                        content: "";
                        @include absolute($left: 2px, $bottom: 2px);
                        @include size(20px);
                        background-color: $color-text--light;
                        border-radius: $border-radius--icon;
                        transition: {
                            property: background-color, transform;
                            duration: $duration;
                            timing-function: $timing;
                        }
                    }
                }
    
                &__input:checked + .top-card__slider {
                    background-color: $color-dark;
                    border-color: $color-dark;
                }
    
                &__input:checked + .top-card__slider::before {
                    transform: translateX(22px);
                    background-color: $color-white;
                }
            }
    
            .button-wrapper {
                @include flex($alignItems: center, $justifyContent: space-between);
                gap: 10px;
                margin: 0;
                padding: 15px 20px;
                border-top: 1px solid rgba(40, 40, 40, 0.10);
                cursor: pointer;
    
                &__instruction {
                    color: $color-text;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                }
    
                &__count {
                    color: $color-text--light;
                    font-weight: $font-weight-normal;
                }
    
                svg {
                    flex: none;
                    @include size(20px);
                    transition: transform $duration $timing;
                }
    
                &__input {
                    position: absolute;
                    @include size(0);
                    opacity: 0;
    
                    &:checked ~ ul {
                        height: var(--categoriesListTotalHeight);
                    }
    
                    &:checked + .button-wrapper svg {
                        transform: rotate(180deg);
                    }
                }
            }
    
            ul {
                @include flex($direction: column);
                gap: 10px;
                padding: 10px 0 0 15px;
    
                label {
                    @include flex($alignItems: center);
                    gap: 5px;
                    margin: 0;
                    font-size: $font-size--text;
                    font-weight: $font-weight-normal;
                    line-height: 1.25;
                }
    
                input {
                    @include size(16px);
                }
            }
    
            & > ul {
                padding: 0 20px;
                height: 0;
                overflow: hidden;
                transition: height $duration ease-in-out;
                will-change: heigt;
    
                & > li:last-child {
                    padding-bottom: 15px;
                }
            }
    
            .wrapped {
                height: var(--categoriesListTotalHeight);
            }
        }
    }

    &__btn {
        gap: 5px;
        width: fit-content;
        margin: 0;
        transition: {
            property: color, background-color, border-color, opacity, pointer-event;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            flex: none;
        }

        &--secondary {
            height: auto;
            gap: 8px;
            padding: 15px 30px;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-small;
        }
    }
}

// Style based on subscription status
#content .push-subscription {
    &--subscribed {
        .subscription-state-message--unsubscribed,
        .button-container #subscribe_notification_button {
            display: none;
        }

        .subscription-state-message--subscribed,
        .button-container #button-container__subscribed {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    &--unsubscribed {
        .subscription-state-message--subscribed,
        .button-container #button-container__subscribed {
            display: none;
        }

        .subscription-state-message--unsubscribed,
        .button-container #subscribe_notification_button {
            display: flex;
            flex-wrap: wrap;
        }
    }
}


// Style animation loading
#snippet_pendig {
    display: none;
    @include relative($top: 20px, $left: 100px);
}

.filter-contrast {
    filter: contrast(5);
    background-color: $color-white;
}

.tooltip::after {
    content: attr(aria-label);
    @include absolute($top: 100%, $left: 50%);
    pointer-events: none;
    margin-top: 8px;
    padding: 6px 8px;
    background-color: rgba(97, 97, 97, .9);
    border-radius: $border-radius;
    @include font-size(12);
    line-height: 1;
    color: $color-white;
    transform: translateX(-50%);
}

.dot-flashing {
    position: relative;
    @include size(15px);
    border-radius: 8px;
    background-color: $color-main;
    color: $color-main;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before, &::after {
        content: "";
        display: inline-block;
        @include absolute($top: 0);
        @include size(15px);
        border-radius: 8px;
        background-color: $color-main;
        color: $color-main;
        animation: dot-flashing 1s infinite alternate;
    }
    
    &::before {
        left: -20px;
        animation-delay: 0s;
    }
    
    &::after {
        left: 20px;
        animation-delay: 1s;
    }
}

@keyframes dot-flashing {
    0% {
        background-color: $color-main;
    }
    50%, 100% {
        background-color: rgba(152, 128, 255, 0.2);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    #push-subscription .button-container .push-subs__btn {
        padding: 15px 20px;
    }

}


// 480
@media screen and (max-width: 480px) {

    .push-subs__list > li .top-card--general {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 20px;

        div {
            padding-bottom: 0;
        }

        .top-card__subtitle {
            margin-top: 10px;
        }
    }

}
//======================================================================================================
// Bloc Content (textual content) - alert, paragraph, question-answer, quote
//======================================================================================================
.bloc-content {
    margin-top: 30px;

    p {
        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    a {
        display: inline-block;
        color: $color-link;
        text-decoration: underline;
        text-decoration-color: $color-link;
        transition: {
            property: color, text-decoration-color;
            duration: $duration;
            timing-function: $timing;
        }

        @extend %external_link;
        &::after {
            @include relative($top: 5px);
        }

        &:hover, &:active, &:focus {
            color: $color-link--hover;
            text-decoration-color: transparent;

            &::after {
                background-color: $color-link--hover;
            }
        }
    }

    ul, ol {
        margin-top: 5px;
        padding-left: 16px;
        line-height: 1.75;

        li {
            line-height: 1.5;
            margin-bottom: 5px;
        }
    }

    ul {
        li {
            position: relative;

            &::before {
                content: "";
                @include absolute($left: -15px, $top: 10px);
                display: flex;
                @include size(7px);
                background-color: $bullet-primary-color;
                border-radius: $border-radius--icon;
            }

            ul {
                li {
                    &::before {
                        background-color: $bullet-secondary-color;
                    }

                    ul {
                        li {
                            &::before {
                                background-color: $bullet-neutral-color;
                            }
                        }
                    }
                }
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    & > ul, & > ol,
    & .bloc-content__text > ul, & .bloc-content__text > ol,
    & .accordion__content--question > ul, & .accordion__content--question > ol {
        margin: 10px 0 0 0;

        &:first-child {
            margin: 0;
        }
    }

    &.border {
        padding: 15px;
        border-left: 15px solid;

        &.primary {
            border-color: $b-primary-bg;
        }

        &.secondary {
            border-color: $b-secondary-bg;
        }

        &.neutral {
            border-color: $b-neutral-bg;
        }
    }

    &__icon {
        flex: none;
        @include size(80px);
    }

    &.background {
        @include flex($alignItems: center);
        gap: 10px 30px;
        padding: 30px;
        border-radius: $border-radius;

        &.primary {
            background-color: $b-primary-bg;
            color: $b-primary-color;

            .bloc-content__icon {
                fill: $b-primary-color;
            }

            ul {
                li {
                    &::before {
                        background-color: $b-primary-color;
                    }
                }
            }

            a {
                color: $b-primary-color;
                text-decoration-color: $b-primary-color;

                // External link
                &::after {
                    background-color: $b-primary-color;
                }
                
                &:hover, &:focus {
                    text-decoration-color: transparent;
                }
            }
        }

        &.secondary {
            background-color: $b-secondary-bg ;
            color: $b-secondary-color;

            .bloc-content__icon {
                fill: $b-secondary-color;
            }

            ul {
                li {
                    &::before {
                        background-color: $b-secondary-color;
                    }
                }
            }

            a {
                color: $b-secondary-color;
                text-decoration-color: $b-secondary-color;
                
                // External link
                &::after {
                    background-color: $b-secondary-color;
                }
                
                &:hover, &:focus {
                    text-decoration-color: transparent;
                }
            }
        }

        &.neutral {
            background-color: $b-neutral-bg;
            color: $b-neutral-color;

            .bloc-content__icon {
                fill: $b-neutral-color;
            }

            ul {
                li {
                    &::before {
                        background-color: $b-neutral-color;
                    }
                }
            }

            a {
                color: $b-neutral-color;
                text-decoration-color: $b-neutral-color;
                
                // External link
                &::after {
                    background-color: $b-neutral-color;
                }

                &:hover, &:focus {
                    text-decoration-color: transparent;
                }
            }
        }
    }

    &--alert {
        background-color: $color-flexible-alert;
        color: $color-white;

        .bloc-content {
            &__icon {
                @include size(50px, 45px);
                fill: $color-white;
            }

            &__title {
                font-family: $font-family--heading;
                font-weight: $font-weight;
                font-size: $font-size--text-medium ;
                text-transform: uppercase;
            }

            &__content {
                margin-top: 5px;
            }
        }

        a {
            color: $color-white;
            text-decoration-color: $color-white;

            &[target=_blank]::after {
                background-color: $color-white
            }

            &:hover, &:focus {
                color: $color-white;
                text-decoration-color: transparent;
            }
        }

        & .bloc-content__content > ul, & .bloc-content__content > ol {
            margin: 10px 0 0 0;
        }

        ul {
            li {
                &::before {
                    background-color: $color-white;
                }

                ul {
                    li {
                        &::before {
                            background-color: $color-white;
                        }

                        ul {
                            li {
                                &::before {
                                    background-color: $color-white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--quote {
        @include flex($alignItems: flex-start, $justifyContent: center);
        margin: 50px 0;
        padding: 0;

        .bloc-content__container {
            max-width: 890px;
            padding: 10px 60px;
        }

        .bloc-content__content {
            position: relative;
            margin: 0;

            svg {
                content: "";
                position: absolute;
                @include size(24px, 18px);
                fill: $color-main;

                &:first-child {
                    top: 0;
                    left: -44px;
                }

                &:last-child {
                    right: -44px;
                    bottom: 0;
                }
            }

            blockquote {
                padding: 0;
                margin: 0;
                border-left: none;
                font-size: $font-size--5;
                line-height: 1.4;
                text-align: center;
            }

            p {
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .bloc-content__author, .bloc-content__source {
            margin-top: 10px;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-align: center;
        }

        .bloc-content__source {
            @include flex($justifyContent: center);
            color: $color-link;
            text-decoration: underline;
            text-decoration-color: $color-link;
            transition: {
                property: color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus {
                color: $color-link--hover;
                text-decoration-color: transparent;
            }
        }
    }

    &--form {
        margin-top: 30px !important;
        padding: 0;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-content--paragraph.background,
    .bloc-content--question_answer.background {
        padding: 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .bloc-content {
        padding: 20px;
        margin: 20px 0;

        &--alert {
            flex-direction: column;
            align-items: flex-start !important;

            svg {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        &--paragraph {
            &.background {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &--quote,
        &--paragraph,
        &--question_answer {
            padding: 0;
        }
    }
    
}

//======================================================================================================
// Search Bar
//======================================================================================================
.search-container {
    position: relative;
    @include flex($alignItems: center);

    #searchInput {
        height: $search_input-height;
        padding: 24px 70px 24px 24px;
        background-color: $search_input-bg;
        border-radius: $search_input-border-radius;
        border-color: $search_input-border-color;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        transition: {
            property: border-color, border-radius;
            duration: .25s;
            timing-function: $timing;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border-color: $search_input-border-color--active;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        @include absolute($top: 50%, $right: 25px);
        transform: translateY(-50%);
        @include size(auto);
        background-color: $search_input-button-bg;
        border: $search_input-button-border;
        border-radius: $search_input-button-border-radius;
        cursor: pointer;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size($search_input-button-size);
            fill: $search_input-button-color;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $search_input-button-bg--active;
            border-color: $search_input-button-border-color--focus;

            svg {
                fill: $search_input-button-color--active;
            }
        }
    }

    &-open {
        #searchInput {
            border-radius: $border-radius $border-radius 0 0;
        }
    }
}

.searchResult {
    @include relative($top: -2px);
    @include flex($direction: column);
    background-color: $search_results-bg;
    border-top: $search_results-border;
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;

    li {
        position: relative;

        a {
            @include flex($alignItems: center);
            @include size(100%);
            padding: 15px 24px;
            background-color: $search_results-bg;
            border-bottom: $search_results-border;
            color: $search_results-color;
            transition: {
                property: color, background-color, border-color, padding-left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .resultCPT {
            @include absolute($right: 25px, $top: 50%);
            pointer-events: none;
            color: $search_results-color--light;
            transform: translate(0,-50%);
            transition: color $duration $timing;
        }

        &.no-results {
            span {
                display: flex;
                @include size(100%);
                padding: 15px 24px;
                color: $search_results-color--light;
            }
        }

        &:not(.no-results) {
            a:hover, a:focus {
                padding-left: 35px;
                background-color: $search_results-bg--hover;
                border-color: $search_results-border--hover;
                color: $search_results-color--hover;

                &::after {
                    color: $search_results-color--hover;
                }
            }

            &.selected,
            &:hover, &:focus {
                .resultCPT {
                    color: $search_results-color--light--hover;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background-color: $search_results-bg--hover;
                border-color: $search_results-border--hover;
                color: $search_results-color--hover;

                &::after {
                    color: $search_results-color--hover;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                @include flex($alignItems: center);
                height: 100%;
                margin-left: 10px;
                font: normal 1.125rem/1 dashicons;
                color: $search_results-color;
                transition: color $duration $timing;
            }
        }
    }
}


//======================================================================================================
// Search Popup
//======================================================================================================
body.search-open {
    overflow: hidden;
}

.search-popup {
    @include fixed($top: 0);
    z-index: 150;
    @include size(100%);
    overflow: auto;
    background-color: $search_popup-bg;

    .container {
        position: relative;
        height: 100%;
    }

    .search-popup__content {
        @include relative($top: 30%);
        @include flex($direction: column, $justifyContent: center);
        width: 100%;
        max-width: 970px;
        margin: 0 auto;

        label {
            position: relative;
            @include flex($alignItems: center);
            margin: 0 0 20px 0;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            line-height: $line-height--heading;
            text-transform: none;
            color: $search_popup-color;
        }
    }

    .search-container {
        position: relative;
        @include flex($alignItems: center);
        width: 100%;
        margin: 0;

        #searchInput {
            border-color: $color-white;
            border-width: 1px;

            &:focus {
                border-color: $color-white;
            }
        }
    }

    .searchResult {
        @include relative($top: 1px, $left: 0);
        width: 100%;
        border-top: 0;
    }

    .search-close {
        @include flex($alignItems: center, $justifyContent: center);
        cursor: pointer;
        @include absolute($top: 40px, $right: 15px);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border: $btn-border-width $btn-border-style $color-main;
        border-radius: $border-radius--icon;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }
        
        svg {
            @include size(40px);
            fill: $color-text--main;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: transparent;
            border-color: $color-main;

            svg {
                fill: $color-text;
            }
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search_input-border-color--active !important;
        }
    }
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 30px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
        width: 800px;
    }

    .search-popup .search-popup__content {
        align-items: center;
    }

    .search-popup .container .searchResult {
        left: 0;
        width: calc(100% - 140px);
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        @include font-size(40);
    }

}

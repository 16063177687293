.nav-main--burger {

    // Burger menu Buttons
    .nav-button {
        &__menu-open {
            height: 45px;
            padding: $btn-padding !important;
            font-family: $bmenu_button_open-ffamily;
            font-weight: $bmenu_button_open-fweight;
            font-size: $bmenu_button_open-fsize;
            text-transform: $bmenu_button_open-ttransform;
            background-color: $bmenu_button_open-bg;
            border-color: $bmenu_button_open-border-color;
            color: $bmenu_button_open-color;
            transition: all $duration $timing;

            svg {
                fill: $bmenu_button_open-color;
            }

            &:hover,
            &:focus {
                background-color: $bmenu_button_open-bg--hover;
                border-color: $bmenu_button_open-border-color--hover;
                color: $bmenu_button_open-color--hover;

                svg {
                    fill: $bmenu_button_open-color--hover;
                }
            }
        }

        &__menu-close {
            @include absolute($top: 50px, $right: 15px);
            background-color: $bmenu_button_close-bg;
            border-color: $bmenu_button_close-border-color;
            color: $bmenu_button_close-color;

            svg {
                fill: $bmenu_button_close-color;
            }

            &:hover,
            &:focus {
                background-color: $bmenu_button_close-bg--hover;
                border-color: $bmenu_button_close-border-color--hover;
                color: $bmenu_button_close-color--hover;

                svg {
                    fill: $bmenu_button_close-color--hover;
                }
            }
        }

        // For responsive
        &__submenu-close {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        @include fixed($top: -120vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 100vh);
        background-color: $bmenu_bg;
        padding: 0;

        transition: {
            property: top, left;
            duration: $duration--second, $duration--second;
            timing-function: $timing;
        }
    }

    // Section menu (for overflow)
    &__section {
        overflow-y: auto;
        height: 100%;
    }

    // Container menu
    &__container {
        position: relative;
        @include flex($justifyContent: space-between, $alignItems: flex-start);
        height: 100%;
    }

    // if Burger menu open
    &.js-open-menu {

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top $duration--second $timing;
        }

        // Main menu
        .menu {
            display: flex;
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 40px;
        @include size(32%, 100%);
        max-width: 370px;
        padding: 130px 20px 40px 0;
        border-right: $bmenu_menu-border-right;

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;

            &.menu-item-has-children {
                &.js-open-submenu {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: inherit;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center);
            gap: 15px;
            max-width: inherit;
            @include size(100%, auto);
            padding: 5px 0;
            font-family: $bmenu_menu_link-ffamily;
            font-weight: $bmenu_menu_link-fweight;
            font-size: $bmenu_menu_link-fsize;
            line-height: $bmenu_menu_link-lineheight;
            text-transform: $bmenu_menu_link-ttransform;
            color: $bmenu_menu_link-color;

            &__icon {
                background-color: $bmenu_menu_link_icon-bg;
                border: $btn-border-width $btn-border-style $bmenu_menu_link_icon-border-color;
                fill: $bmenu_menu_link_icon-color;

                transition: {
                    property: background-color, border-color, color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover,
            &:focus {
                color: $bmenu_menu_link-color--hover;

                .menu__link__icon {
                    background-color: $bmenu_menu_link_icon-bg--hover;
                    border-color: $bmenu_menu_link_icon-border-color--hover;
                    fill: $bmenu_menu_link_icon-color--hover;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include absolute($top: 120px, $left: calc(370px + 15px), $right: 15px);
        z-index: inherit;
        width: auto;
        padding: 0 0 0 60px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 10px 40px;
            max-width: inherit;
            max-height: 70vh;
            padding: 0;
            overflow: auto;
        }

        &__item {
            width: calc(50% - 20px);
            margin: 0;
        }

        &__link {
            position: relative;
            @include flex($alignItems: center);
            gap: 10px;
            @include size(auto);
            padding: 5px 0;
            font-family: $bmenu_submenu_link-ffamily;
            color: $bmenu_submenu_link-color;

            &__icon {
                background-color: $bmenu_submenu_link_icon-bg;
                fill: $bmenu_submenu_link_icon-color;

                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover,
            &:focus {
                color: $bmenu_submenu_link-color--hover;

                .submenu__link__icon {
                    background-color: $bmenu_submenu_link_icon-bg--hover;
                    fill: $bmenu_submenu_link_icon-color--hover;
                }
            }

            &--child {
                font-weight: $bmenu_submenu_link-fweight;
                font-size: $bmenu_submenu_link-fsize;
            }

            &--grandchild {
                display: flex;
                padding: 5px 0px 5px 30px;
                font-size: $bmenu_submenu_link-fsize--grandchild;
                font-weight: $bmenu_submenu_link-fweight--grandchild;
                text-transform: initial;
                line-height: initial;
                text-decoration: underline;
                text-decoration-color: transparent;

                transition: {
                    property: color, text-decoration-color;
                    duration: $duration;
                    timing-function: $timing;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration-color: $bmenu_submenu_link-color--hover;
                }
            }
        }
    }

}

.header--fixed {
    .nav-main--burger .nav-button__menu-open {
        background-color: $bmenu_button_open-bg--fixed;
        border-color: $bmenu_button_open-border-color--fixed;
        color: $bmenu_button_open-color--fixed;

        svg {
            fill: $bmenu_button_open-color--fixed;
        }

        &:hover,
        &:focus {
            background-color: $bmenu_button_open-bg--fixed--hover;
            border-color: $bmenu_button_open-border-color--fixed--hover;
            color: $bmenu_button_open-color--fixed--hover;

            svg {
                fill: $bmenu_button_open-color--fixed--hover;
            }
        }
    }
}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        // Main menu
        .menu {
            gap: 30px;
            padding-right: 30px;
            width: 28%;
            max-width: 250px;

            &__link {
                font-size: $bmenu_menu_link-fsize--1200;
            }
        }

        // Sub menu
        .submenu {
            left: calc(250px + 10px);
            right: 10px;
            padding-left: 40px;

            &__nav {
                column-gap: 30px;
            }

            &__item {
                width: calc(50% - 15px);
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;

        button.avoid-main-content {
            display: none;
        }

        .nav-button {
            &__menu-open {

                &:hover,
                &:focus {
                    background-color: $bmenu_button_open-bg;
                    border-color: $bmenu_button_open-border-color;
                    color: $bmenu_button_open-color;

                    svg {
                        fill: $bmenu_button_open-color;
                    }
                }
            }

            &__menu-close {
                top: 30px;
                right: 10px;
                z-index: 11;

                &:hover,
                &:focus {
                    background-color: $bmenu_button_close-bg;
                    border-color: $bmenu_button_close-border-color;
                    color: $bmenu_button_close-border-color--hover;

                    svg {
                        fill: $bmenu_button_close-color;
                    }
                }
            }

            &__submenu-close {
                @include absolute($top: 30px, $left: -100vw);
                z-index: 11;
                display: flex;
                background-color: $bmenu_button_close-bg;
                border-color: $bmenu_button_close-border-color;
                color: $bmenu_button_close-color;

                transition: {
                    property: background-color, border-color, left;
                    duration: $duration, $duration, $duration--second;
                    timing-function: $timing;
                }

                svg {
                    fill: $bmenu_button_close-color;
                }

                &:hover,
                &:focus {
                    background-color: $bmenu_button_close-bg;
                    border-color: $bmenu_button_close-border-color;
                    color: $bmenu_button_close-border-color;

                    svg {
                        fill: $bmenu_button_close-color;
                    }
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;
            transition: left $duration--second $timing;
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Main menu
        .menu {
            display: flex;
            flex-wrap: nowrap;
            @include size(100%, auto);
            max-width: none;
            min-height: 100vh;
            margin: 0;
            padding: 100px 40px 40px;
            row-gap: 20px;
            overflow: hidden;
            border: 0;

            &__link {
                font-size: $bmenu_menu_link-fsize--960;
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            @include flex($direction: column);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 100px 40px 40px;
            background-color: $color-light;
            opacity: 1;
            pointer-events: inherit;
            visibility: visible;
            transition: left $duration--second $timing;

            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: 5px;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 0 calc(5% + 10px);
            }

            &__item,
            &__link {
                width: 100%;
            }
        }


        // if Burger menu open
        &.js-open-menu {

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
                transition: left $duration--second $timing;
            }

            // If submenu open
            &.js-open-submenu {
                .menu__item.menu-item-has-children.js-open-submenu {
                    .submenu {
                        left: 0;
                        display: flex;
                    }
                }

                .nav-main--burger__section {
                    overflow: hidden;
                }

                .nav-button__submenu-close {
                    left: 10px;
                }
            }
        }
    }

    // If admin logged
    .admin-bar .nav-main--burger .nav-main--burger__popup {
        top: 32px;
    }
    .admin-bar .nav-main--burger .submenu {
        top: 32px;
        height: calc(100vh - 32px);
    }

}


// 782 -- For admin bar only
@media screen and (max-width: 782px) {

    // Admin 
    .admin-bar .nav-main--burger .nav-main--burger__popup,
    .admin-bar .nav-main--burger .submenu {
        top: 46px !important;
    }

    .admin-bar .nav-main--burger .menu {
        min-height: calc(100vh - 46px);
    }

    .admin-bar .nav-main--burger .submenu {
        height: calc(100vh - 46px);
    }

}


// 640
@media screen and (max-width: $small) {

    .nav-main--burger {

        .nav-button__submenu-close,
        .nav-button__menu-close {
            top: 20px;
        }

        .menu,
        .submenu {
            padding: 80px 10px 40px;
        }
    }

}

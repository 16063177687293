.c-pagination {
    @include absolute($bottom: $m-slideshow_pag_bottom);
    @include flex($alignItems: center, $justifyContent: flex-end);
    gap: $m-slideshow_pag-gap;
    padding: $m-slideshow_pag-padding;
    background-color: $m-slideshow_pag-bg;
    border-radius: $m-slideshow_pag-radius;
    z-index: 1;
    pointer-events: auto;

    .nav-swiper-autoplay {
        @include size($m-slideshow_pag_btn-size);
        padding: 0;
        background-color: transparent;
        border: 0;
        border-radius: 0;

        &__icon {
            @include size($m-slideshow_pag_btn-size);
            fill: $m-slideshow_pag_btn-color;
        }

        &:hover, &:focus {
            .nav-swiper-autoplay__icon {
                fill: $m-slideshow_pag_btn-color--hover;
            }
        }
    }

    &__bullets {
        @include flex($alignItems: center);
        gap: 10px;

        .swiper-pagination-bullet {
            @include size($m-slideshow_pag_bullet-size);
            margin: 0 !important;
            opacity: 1 !important;
            background-color: $m-slideshow_pag_bullet-bg;
            border: $m-slideshow_pag_bullet-bd;
            border-radius: $m-slideshow_pag_bullet-radius;
            transition: {
                property: background-color, border-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover, &:focus, &-active {
                background-color: $m-slideshow_pag_bullet-bg--hover;
                border-color: $m-slideshow_pag_bullet-bd-color--hover;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .c-pagination {
        bottom: $m-slideshow_pag_bottom--640;
    }
}

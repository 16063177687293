//======================================================================================================
// Simple Menu
//======================================================================================================

$smenu_gap                                      : 40px !default;
$smenu_gap--1200                                : 25px !default;

// Button Close Menu
$smenu_button_close-bg                          : $color-text--main !default;
$smenu_button_close-border-color                : $color-text--main !default;
$smenu_button_close-color                       : $color-main !default;
$smenu_button_close-bg--hover                   : transparent !default;
$smenu_button_close-border-color--hover         : $color-text--main !default;
$smenu_button_close-color--hover                : $color-text--main !default;


// Menu open
$smenu_bg                                       : $color-main !default;
$smenu_bg--resp                                 : $color-main !default;


// Menu links
$smenu_menu_link-padding                        : 10px 0 !default;
$smenu_menu_link-ffamily                        : $font-family--heading !default;
$smenu_menu_link-fweight                        : $font-weight--heading !default;
$smenu_menu_link-fsize                          : $font-size--5 !default;
$smenu_menu_link-fsize--1200                    : $font-size--text !default;
$smenu_menu_link-fsize--960                     : $font-size--4 !default;
$smenu_menu_link-lineheight                     : $line-height--heading !default;
$smenu_menu_link-ttransform                     : uppercase !default;
$smenu_menu_link-color                          : $color-text !default;
$smenu_menu_link-color--hover                   : $color-text !default;

// Last link in button style
$smenu_menu_link_last_item-min-height           : 42px !default; // not button : inherit
$smenu_menu_link_last_item-padding              : 5px 20px !default; // not button : 0
$smenu_menu_link_last_item-bg                   : $color-second !default; // not button : transparent
$smenu_menu_link_last_item-border               : $btn-border-width $btn-border-style $color-second !default; // not button : 0
$smenu_menu_link_last_item-radius               : $btn-border-radius !default; // not button : inherit
$smenu_menu_link_last_item-color                : $color-white !default; // not button : $smenu_menu_link-color
$smenu_menu_link_last_item-bg--hover            : transparent !default; // not button : none
$smenu_menu_link_last_item-border-color--hover  : $color-second !default; // not button : none
$smenu_menu_link_last_item-color--hover         : $color-text !default; // not button : $smenu_menu_link-color--hover


// Submenu links
$smenu_submenu_link-padding                     : 12px 0 !default;
$smenu_submenu_link-ffamily                     : $font-family !default;
$smenu_submenu_link-fweight                     : $font-weight-bold !default;
$smenu_submenu_link-fweight--grandchild         : $font-weight !default;
$smenu_submenu_link-fsize                       : $font-size--text !default;
$smenu_submenu_link-fsize--grandchild           : $font-size--text !default;
$smenu_submenu_link-color                       : $color-text--main !default;
$smenu_submenu_link-color--hover                : $color-text--main !default;

$smenu_submenu_link-border-bottom               : 1px solid rgba($color-text--main, .5) !default;
$smenu_submenu_link-before--color               : $color-text--main !default;

$smenu_submenu_link_icon-color                  : $color-text--main !default;
$smenu_submenu_link_icon-opacity                : 0.5 !default;
$smenu_submenu_link_icon-color--hover           : $color-text--main !default;
$smenu_submenu_link_icon-opactiy--hover         : 1 !default;



//======================================================================================================
// Burger Menu
//======================================================================================================

// Button Open Menu
$bmenu_button_open-ffamily                      : $font-family--heading !default;
$bmenu_button_open-fweight                      : $font-weight-bold !default;
$bmenu_button_open-fsize                        : $font-size--text !default;
$bmenu_button_open-ttransform                   : uppercase !default;
$bmenu_button_open-bg                           : $color-second !default;
$bmenu_button_open-border-color                 : $color-second !default;
$bmenu_button_open-color                        : $color-white !default;

    // Hover, Focus
    $bmenu_button_open-bg--hover                    : transparent !default;
    $bmenu_button_open-border-color--hover          : $color-second !default;
    $bmenu_button_open-color--hover                 : $color-text !default;

    // Header fixed
    $bmenu_button_open-bg--fixed                    : $color-second !default;
    $bmenu_button_open-border-color--fixed          : $color-second !default;
    $bmenu_button_open-color--fixed                 : $color-white !default;

        // Hover, Focus
        $bmenu_button_open-bg--fixed--hover             : $color-second !default;
        $bmenu_button_open-border-color--fixed--hover   : $color-second !default;
        $bmenu_button_open-color--fixed--hover          : $color-white !default;


// Button Close Menu
$bmenu_button_close-bg                          : $color-main !default;
$bmenu_button_close-border-color                : $color-main !default;
$bmenu_button_close-color                       : $color-text--main !default;
$bmenu_button_close-bg--hover                   : transparent !default;
$bmenu_button_close-border-color--hover         : $color-main !default;
$bmenu_button_close-color--hover                : $color-text !default;


// Menu open
$bmenu_bg                                       : $color-light !default;
$bmenu_menu-border-right                        : 1px solid rgba($color-black, 0.1) !default;


// Menu links
$bmenu_menu_link-ffamily                        : $font-family--heading !default;
$bmenu_menu_link-fweight                        : $font-weight--heading !default;
$bmenu_menu_link-fsize                          : $font-size--3 !default;
$bmenu_menu_link-fsize--1200                    : $font-size--4 !default;
$bmenu_menu_link-fsize--960                     : $font-size--4 !default;
$bmenu_menu_link-lineheight                     : $line-height--heading !default;
$bmenu_menu_link-ttransform                     : none !default;
$bmenu_menu_link-color                          : $color-text !default;
$bmenu_menu_link-color--hover                   : $color-text !default;

$bmenu_menu_link_icon-bg                        : $color-white !default;
$bmenu_menu_link_icon-border-color              : $color-white !default;
$bmenu_menu_link_icon-color                     : $color-text !default;
$bmenu_menu_link_icon-bg--hover                 : $color-main !default;
$bmenu_menu_link_icon-border-color--hover       : $color-main !default;
$bmenu_menu_link_icon-color--hover              : $color-text--main !default;


// Submenu links
$bmenu_submenu_link-ffamily                     : $font-family !default;
$bmenu_submenu_link-fweight                     : $font-weight-bold !default;
$bmenu_submenu_link-fweight--grandchild         : $font-weight !default;
$bmenu_submenu_link-fsize                       : $font-size--text !default;
$bmenu_submenu_link-fsize--grandchild           : $font-size--text !default;
$bmenu_submenu_link-color                       : $color-text !default;
$bmenu_submenu_link-color--hover                : $color-text !default;

$bmenu_submenu_link_icon-bg                     : transparent !default;
$bmenu_submenu_link_icon-border-color           : transparent !default;
$bmenu_submenu_link_icon-color                  : $color-text !default;
$bmenu_submenu_link_icon-bg--hover              : $color-main !default;
$bmenu_submenu_link_icon-border-color--hover    : $color-main !default;
$bmenu_submenu_link_icon-color--hover           : $color-text--main !default;

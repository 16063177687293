.m-fqa-classic {
    margin: $m-fqa-classic_section-margin;
    padding: $m-fqa-classic_section-padding;
    background-color: $m-fqa-classic_section-bg;

    &__wrapper {
        position: $m-fqa-classic_wrapper-position;
        z-index: $m-fqa-classic_wrapper-zindex;
        margin: $m-fqa-classic_wrapper-margin;
        padding: $m-fqa-classic_wrapper-padding;
        background: $m-fqa-classic_wrapper-bg;
        border: $m-fqa-classic_wrapper-bd;
        border-radius: $m-fqa-classic_wrapper-radius;
        box-shadow: $m-fqa-classic_wrapper-boxshadow;
    }

    &__list {
        @include flex($wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
        gap: $m-fqa-classic_list-gap;
    }

    &__item {
        @include size($m-fqa-classic_item-size);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fqa-classic {
        margin: $m-fqa-classic_section-margin--1200;

        &__wrapper {
            margin: $m-fqa-classic_wrapper-margin--1200;
            padding: $m-fqa-classic_wrapper-padding--1200;
        }

        &__list {
            gap : $m-fqa-classic_list-gap--1200;
        }

        &__item {
            @include size($m-fqa-classic_item-size--1200);
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fqa-classic {
        margin: $m-fqa-classic_section-margin--960;

        &__wrapper {
            margin: $m-fqa-classic_wrapper-margin--960;
            padding: $m-fqa-classic_wrapper-padding--960;
        }

        &__list {
            justify-content: space-evenly;
            gap : $m-fqa-classic_list-gap--960;
        }

        &__item {
            @include size($m-fqa-classic_item-size--960);
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .m-fqa-classic {
        margin: $m-fqa-classic_section-margin--640;

        &__wrapper {
            margin: $m-fqa-classic_wrapper-margin--640;
            padding: $m-fqa-classic_wrapper-padding--640;
        }
        
        &__list {
            gap : $m-fqa-classic_list-gap--640;
        }

        &__item {
            @include size($m-fqa-classic_item-size--640);
        }
    }
   

}

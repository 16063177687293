.c-slideshow {
    @include size(100%, $m-slideshow_height !important);

    img, video {
        object-fit: cover;
        //object-position: top;
        @include size(100%);
    }

    &__container {
        @include absolute(0, 0, 0, 0);
        box-sizing: border-box;
    }

    &__link, &__bloc, &__figure {
        display: block;
        height: 100%;
    }

    &__content {
        @include flex($direction: column);
        gap: $m-slideshow_fig-gap;
        box-sizing: border-box;
        @include absolute($bottom: $m-slideshow_content_bottom);
        max-width: $m-slideshow_content-maxwidth;
        padding: $m-slideshow_content-padding;
        background-color: $m-slideshow_content-bg;
        border-radius: $m-slideshow_content-radius;
        color: $m-slideshow_content-color;
    }

    &__title {
        font-family: $m-slideshow_title-ffamily;
        font-size: $m-slideshow_title-fsize;
        font-weight: $m-slideshow_title-fweight;
    }

    &__description {
        font-family: $m-slideshow_desc-ffamily;
        font-size: $m-slideshow_desc-fsize;
        font-weight: $m-slideshow_desc-fweight;
    }

    &__figcaption {
        box-sizing: border-box;
        @include absolute($bottom: $m-slideshow_fig_bottom);
        max-width: $m-slideshow_fig_bottom-maxwidth;
        padding: $m-slideshow_fig-padding;
        background-color: $m-slideshow_fig-bg;
        border-radius: $m-slideshow_fig-radius;
        color: $m-slideshow_fig-color;
        font-family: $m-slideshow_fig-ffamily;
        font-size: $m-slideshow_fig-fsize;
        font-weight: $m-slideshow_fig-fweight;
    }

    &__bloc--video, &__link--video {
        position: relative;
        overflow: hidden;
        @include size(100%, $m-slideshow_height);

        picture, img {
            display: none;
        }

        .c-slideshow {
            &__content {
                bottom: $m-slideshow_content_bottom--video;
            }

            &__figcaption {
                bottom: $m-slideshow_fig_bottom--video;
            }
        }
    }

    &--with-pagination {
        .c-slideshow__content {
            bottom: $m-slideshow_content_bottom--pag;
        }
        .c-slideshow__figcaption {
            bottom: $m-slideshow_fig_bottom--pag;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .c-slideshow {
        height: $m-slideshow_height--1200 !important;

        &__content {
            max-width: $m-slideshow_content-maxwidth--1200;
        }

        &__bloc--video, &__link--video {
            height: $m-slideshow_height--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .c-slideshow {
        height: $m-slideshow_height--960 !important;

        video {
            display: none;
        }

        &__bloc--video, &__link--video {
            height: $m-slideshow_height--960;

            picture, img {
                display: block;
            }
        }

        &__content {
            bottom: $m-slideshow_content_bottom--960;
            max-width: $m-slideshow_content-maxwidth--960;
        }

        &__figcaption {
            bottom: $m-slideshow_fig_bottom--960;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .c-slideshow {
        height: auto !important;

        &__container {
            position: relative;
            top: unset;
            right: unset;
            bottom: unset;
            left: unset;
        }

        &__content {
            position: relative;
            bottom: unset;
            max-width: 100%;
            margin: $m-slideshow_content-margin--640;
            background-color: $m-slideshow_content-bg--640;
        }

        img {
            position: unset;
            height: $m-slideshow_height--640;
        }

        &__figcaption {
            bottom: $m-slideshow_fig_bottom--640;
            max-width: 100%;
            margin-right: 10px;
            padding: $m-slideshow_fig-padding--640;
            font-size: $m-slideshow_fig-fsize--640;
        }

        &__bloc--video, &__link--video {
            height: auto;
            
            .c-slideshow {
                &__content {
                    bottom: $m-slideshow_content_bottom--640;
                }

                &__figcaption {
                    bottom: $m-slideshow_fig_bottom--video--640;
                }
            }
        }

        &--with-pagination {
            .c-slideshow {
                &__content {
                    bottom: unset;
                }

                &__figcaption {
                    bottom: $m-slideshow_fig_bottom--pag--640;
                }
            }
        }
    }

}



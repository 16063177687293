//======================================================================================================
// Footer
//======================================================================================================

// Globals
$footer-bg                                      : $color-main !default;
$footer-color                                   : $color-text--main !default;
$footer_wrapper-padding                         : 60px 0 40px 0 !default;

// Logo size
$footer_logo-width                              : 170px !default;
$footer_logo-height                             : 130px !default;
$footer_logo-width--1200                        : 125px !default;
$footer_logo-height--1200                       : 95px !default;
$footer_logo-width--960                         : 119px !default;
$footer_logo-height--960                        : 91px !default;
$footer_logo-width--640                         : 119px !default;
$footer_logo-height--640                        : 91px !default;

// Phone color link
$footer_phone-color                             : $footer-color !default;
$footer_phone-color--hover                      : $footer-color !default;

// Buttons colors
$footer_button-bg                               : $color-second !default;
$footer_button-border-color                     : $color-second !default;
$footer_button-color                            : $color-white !default;
$footer_button-bg--hover                        : $color-white !default;
$footer_button-border-color--hover              : $color-white !default;
$footer_button-color--hover                     : $color-text !default;

// Infos
$footer_infos-gap                               : 60px !default;
$footer_infos-padding                           : 0 !default;
$footer_infos-bg                                : transparent !default;
$footer_infos-border-width                      : 0 !default;
$footer_infos-border-color                      : $color-text--main !default;

// Coord
$footer_coord-maxwidth                          : 300px !default;

// Opening hours
$footer_opening-maxwidth                        : 450px !default;

// Subzone globals
$footer_subzone-maxwidth                        : 270px !default;
$footer_subzone-padding                         : 30px !default;
$footer_subzone-bg                              : transparent !default;
$footer_subzone-border                          : 1px solid $color-text--main !default;
$footer_subzone-radius                          : $border-radius !default;

// Subzone social
$footer_subzone_social-gap                      : 30px !default;
$footer_subzone_social_button-bg                : $color-white !default;
$footer_subzone_social_button-border-color      : $color-white !default;
$footer_subzone_social_button-color             : $color-text !default;
$footer_subzone_social_button-bg--hover         : transparent !default;
$footer_subzone_social_button-border-color--hover : $color-text--main !default;
$footer_subzone_social_button-color--hover      : $color-text--main !default;

// Subzone visual link
$footer_subzone_visualink-alignItems            : flex-start !default;
$footer_subzone_visualink-alignItems--1200      : $footer_subzone_visualink-alignItems !default;
$footer_subzone_visualink-alignItems--960       : center !default;
$footer_subzone_visualink-alignItems--640       : $footer_subzone_visualink-alignItems--960 !default;
$footer_subzone_visualink-gap                   : 20px !default;
$footer_subzone_visualink-svg-size              : 72px !default;
$footer_subzone_visualink-svg-fill              : $footer-color !default;
$footer_subzone_visualink-text-ffamily          : $font-family !default;
$footer_subzone_visualink-text-fweight          : $font-weight-bold !default;
$footer_subzone_visualink-text-fsize            : $font-size--text-small !default;
$footer_subzone_visualink-text-lineheight       : 1.1 !default;
$footer_subzone_visualink-text-transform        : uppercase !default;
$footer_subzone_visualink-text-align            : left !default;
$footer_subzone_visualink-text-align--1200      : $footer_subzone_visualink-text-align !default;
$footer_subzone_visualink-text-align--960       : center !default;
$footer_subzone_visualink-text-align--640       : $footer_subzone_visualink-text-align--960 !default;
$footer_subzone_visualink-bg                    : transparent !default;
$footer_subzone_visualink-border                : 1px solid $footer-color !default;
$footer_subzone_visualink-color                 : $footer-color !default;
$footer_subzone_visualink-bg--hover             : rgba($color-text--main, 0.1) !default;
$footer_subzone_visualink-border-color--hover   : $color-text--main !default;
$footer_subzone_visualink-color--hover          : $footer-color !default;

// Subzone menu link color
$footer_subzone_menu-color                      : $footer-color !default;
$footer_subzone_menu-color--hover               : $footer-color !default;

// Footer menu
$footer_menu_padding                            : 30px 0 !default;
$footer_menu-bg                                 : $footer-bg !default;
$footer_menu_link-fsize                         : $font-size--text !default;
$footer_menu_link-color                         : $footer-color !default;
$footer_menu_link-color--hover                  : $footer-color !default;

//===================================================================
// Google translate - GTranslate.io
//===================================================================

.c-translate {
    position: relative;

    &__wrapper {
        @include absolute($left: 50%, $top: $gt-wrapper-top);
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        gap: $gt-wrapper-gap;
        width: max-content;
        padding: $gt-wrapper-padding;
        background-color: $gt-wrapper-bg;
        border-radius: $gt-wrapper-radius;
        transform: translateX(-50%);
        filter: $gt-wrapper-filter;

        &.js-open {
            display: flex;
        }

        &::before {
            content: '';
            @include absolute($top: -6px, $left: 50%);
            display: block;
            @include size(30px);
            background-color: $gt-wrapper-bg;
            border-radius: $gt-wrapper-radius--arrow;
            transform: translateX(-50%) rotate(45deg);
            pointer-events: none;
            z-index: -1;
        }

        a.glink {
            position: relative;
            font-family: $font-family;
            font-size: $font-size--text;
            font-weight: $font-weight;
            color: $gt_link-color;
            text-decoration: underline;
            text-decoration-color: transparent;

            transition: {
                property: color, text-decoration-color;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover,
            &:focus {
                color: $gt_link-color--hover;
                text-decoration-color: $gt_link-color--hover;
            }

            &.gt-current-lang {
                font-weight: $gt_link-fweight--current;
                color: $gt_link-color--current;
                text-decoration-color: $gt_link-color--current;
            }
        }
    }

    &__close {
        @include absolute($bottom: -10px, $left: 50%);
        @include flex($alignItems: center, $justifyContent: center);
        transform: translateX(-50%);
        @include size($gt_close-size);
        background-color: $gt_close-bg;
        border-color: $gt_close-border-color;
        padding: 0;
        margin: 0;

        .button__icon {
            fill: $gt_close-color;
        }

        &:hover,
        &:focus {
            background-color: $gt_close-bg--hover;
            border-color: $gt_close-border-color--hover;

            .button__icon {
                fill: $gt_close-color--hover;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// // 640
@media screen and (max-width: $small) {

    .c-translate {
        &__wrapper {
            bottom: 60px;
            top: unset;
            padding: 25px 20px 15px;

            &::before {
                bottom: -6px;
                top: unset;
            }
        }

        &__close {
            top: -10px;
            bottom: unset;
        }
    }

}
.m-fp-2_slides {

    &--with_title {
        .m-2_slides__nav {
            position: relative;
            margin-top: $m-fp-2_slides_nav_btn-margin-top;
            min-height: 40px;
        }
    }

    &__content {
        margin: 0 !important; // swiper override
    }

    &__item {
        height: auto !important; // swiper override
    }
    
    .card {
        &__container {
            border: 0;
            padding: 0;
            background: none;
            box-shadow: none;
            overflow: unset;
     
            &:hover, &:focus {                        
                .card__content {
                    box-shadow: $card-shadow--hover; 
                }
            }
        }

        &__image-wrapper {
            @include size(100%, 350px);
            border-radius: $border-radius--image;
        }

        &__content {
            position: relative;
            align-items: flex-start;
            gap: 10px;
            margin: -50px 20px 0;
            padding: 30px;
            border: $card-border;
            border-radius: $card-radius;
            box-shadow: $card-shadow;
            transition: {
                property: background-color, box-shadow;
                duration: $duration--second;
                timing-function: $timing;
            }
        }

        &__category {
            position: unset;
        }

        &--v2 .card__date {
            top: $m-fp-2_slides_date-pos-top;
            right: $m-fp-2_slides_date-pos-right;
            bottom: $m-fp-2_slides_date-pos-bot;
            left: $m-fp-2_slides_date-pos-left;
            width: $m-fp-2_slides_date-pos-width;
            margin: $m-fp-2_slides_date-pos-margin;
        }

        &__container, &__title {
            text-align: unset;
        }
    }

    .m-2_slides {
        &__nav .nav-swiper--prev, &__nav .nav-swiper--next {
            background-color: $m-fp-2_slides_nav_btn-bg;
            border-color: $m-fp-2_slides_nav_btn-bd-color;

            .button__icon {
                fill: $m-fp-2_slides_nav_btn-color;
            }
        
            &:hover, &:focus {
                background-color: $m-fp-2_slides_nav_btn-bg--hover;
                border-color: $m-fp-2_slides_nav_btn-bd-color--hover;

                .button__icon {
                    fill: $m-fp-2_slides_nav_btn-color--hover;
                }
            }
        }

        &__button {
            background-color: $m-fp-2_slides_btn-bg;
            border-color: $m-fp-2_slides_btn-bd-color;
            color: $m-fp-2_slides_btn-color;
        
            &:hover, &:focus {
                background-color: $m-fp-2_slides_btn-bg--hover;
                border-color: $m-fp-2_slides_btn-bd-color--hover;
                color: $m-fp-2_slides_btn-color--hover;
            }

            &--prop {
                background-color: $m-fp-2_slides_btn_prop-bg;
                border-color: $m-fp-2_slides_btn_prop-bd-color;
                color: $m-fp-2_slides_btn_prop-color;
            
                &:hover, &:focus {
                    background-color: $m-fp-2_slides_btn_prop-bg--hover;
                    border-color: $m-fp-2_slides_btn_prop-bd-color--hover;
                    color: $m-fp-2_slides_btn_prop-color--hover;
                }
        
            }
        }
    }

    .swiper {
        margin: $swiper-margin;
        padding: $swiper-padding;
    }

    // If model without category & description
    &__item:not(:has(.card__description)) {
        .card__title {
            font-size: $font-size--3;
        }

        .card__content--has-category {
            .card__title {
                font-size: $font-size--4;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fp-2_slides {
        .card__image-wrapper {
            height: 280px;
        }

        .swiper {
            margin: $swiper-margin--1200;
            padding: $swiper-padding--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fp-2_slides {

        .card {
            &__image-wrapper {
                height: 220px;
            }

            &--v2 .card__date {
                top: $m-fp-2_slides_date-pos-top--960;
                right: $m-fp-2_slides_date-pos-right--960;
                bottom: $m-fp-2_slides_date-pos-bot--960;
                left: $m-fp-2_slides_date-pos-left--960;
            }    

            &__content {
                margin: -40px 10px 0;
                padding: 20px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fp-2_slides {
        &--with_title .m-2_slides__nav {
            position: unset;
            margin-top: 0;
            min-height: unset;
        }
    }

}

.m-fqa-double {
    background-color: $m-fqa-double-bg;

    &__wrapper {
        display: flex;
    }
    
    .m-access {
        @include flex($direction: column, $justifyContent: center);
        width: 56%;
        background-color: $m-fqa-double_access-bg;

        &__list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $m-fqa-double_access-gap;
        }
    }

    .c-custom_menu {
        position: relative;
        flex: 1;

        &::before {
            content: '';
            @include absolute($top: 0);
            z-index: 0;
            @include size(50vw, 100%);
            background-color: $m-fqa-double_menu-bg;
            pointer-events: none;
        }

        &__list {
            position: relative;
            z-index: 1;
        }

        &__link {
            padding: $m-fqa-double_menu_link-padding;
            background-color: $m-fqa-double_menu_link-bg;
            border: $m-fqa-double_menu_link-border;
            border-radius: $m-fqa-double_menu_link-radius;
            font-family: $m-fqa-double_menu_link-ffamily;
            font-weight: $m-fqa-double_menu_link-fweight;
            font-size: $m-fqa-double_menu_link-fsize;
            line-height: $m-fqa-double_menu_link-line-height;
            color: $m-fqa-double_menu_link-color;
            transition: {
                property: background-color, border-color, color;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                fill: $m-fqa-double_menu_link-color;
                transition: fill $duration $timing;
            }

            &:hover, &:focus {
                background-color: $m-fqa-double_menu_link-bg--hover;
                border-color: $m-fqa-double_menu_link-bd-color--hover;
                color: $m-fqa-double_menu_link-color--hover;

                svg {
                    fill: $m-fqa-double_menu_link-color--hover;
                }
            }

        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fqa-double {
        .m-access__list {
            gap: $m-fqa-double_access-gap--1200;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-fqa-double {
        &__wrapper {
            flex-direction: column;
        }

        .m-access {
            width: 100%;
            padding: $m-fqa-double_access-padding--960 !important;

            &__list {
                @include flex($wrap: wrap, $alignItems: flex-start, $justifyContent: space-evenly);
                gap: $m-fqa-double_access-gap--960;
            }

            &__item {
                @include size($m-fqa-double_item-size--960);
            }
        }

        .c-custom_menu {
            padding: $m-fqa-double_menu-padding--960 !important;

            &::before {
                left: -50vw !important;
                right: -50vw !important;
                width: inherit;
            }

            &__list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            &__link {
                height: 100%;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-fqa-double {
        .m-access {
            &__list {
                gap: $m-fqa-double_access-gap--640;
            }

            &__item {
                @include size($m-fqa-double_item-size--640);
            }
        }

        .c-custom_menu {
            &__list {
                display: flex;
            }

            &__link {
                height: auto;
            }
        }
    }

}

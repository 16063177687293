.events-infos {
    .bloc-map {
        margin: 0;
        height: 100%;
    }

    .wrapper_map {
        height: 100%;
    }

    #map {
        min-height: 550px;
    }
}

.date-event {
    margin: 30px 0;
    
    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--2;
        line-height: $line-height--heading;
        color: $color-text;
        margin: 0;
        padding: 0;
        border: 0;

        &::before, &::after {
            display: none;
        }
    }

    &__recurrence {
        color: $color-gray;
        margin-top: 5px;
    }
}

.actions-event {
    margin-top: 25px;
    margin-bottom: 40px;
    width: 100%;

    .actions-container {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 20px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .events-infos {
        .grid {
            .col_sm-12 {
                flex: 100%;
            }
        }
    }

}


// 480
@media screen and (max-width: $x-small) {

    .actions-event .actions-container form {
        width: 100%;
    } 

}


.events-infos {
    .listing {
        &--accessibility {
            @include flex($wrap: wrap, $alignItems: center);
            gap: 20px 30px;
            padding: 25px 10px;
        }
    
        &__item {
            display: flex;
            gap: 15px;
            padding: 15px 10px;
            box-shadow: 0px 1px 0px rgba($color-black, 0.15);
    
            &--accessibility {
                align-items: center;
                gap: 5px;
                padding: 0;
                box-shadow: none;
        
                &:first-of-type {
                    width: 100%;
                } 
            }
        }
    
        &__icon {
            @include size(50px);
            fill: $color-svg--events;
    
            &--accessibility {
                @include size(60px);
            }
        }
    
        &__text {
            flex: 1;
            @include flex($direction: column, $justifyContent: center);
        }
    
        &__adress {
            &__additional {
                margin-top: 10px;
            }
        }
    
        &__title {
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 4px;
        }
    
        &__desc {
            line-height: 1.4;
        }
        
        &__contacts {
            @include flex($direction: column);
            gap: 15px;
    
            &__item {
                @include flex($direction: column);
            }
    
            &__name {
                font-weight: $font-weight-bold;
            }
    
            &__phone {
                color: $color-link;
                text-decoration: underline;
                text-decoration-color: transparent;
                transition: {
                    property: color, text-decoration-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                &:hover, &:focus {
                    color: $color-link--hover;
                    text-decoration-color: $color-link--hover;
                }
            }
    
            &__mail {
                @include flex($alignItems: center);
                gap: 2px;
                word-break: break-all;
                color: $color-link;
                text-decoration: underline;
                text-decoration-color: $color-link;
                transition: {
                    property: color, text-decoration-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                svg {
                    @include relative($top: 1px);
                }
    
                &:hover, &:focus {
                    color: $color-link--hover;
                    text-decoration-color: transparent;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .events-infos {
        .listing {
            &__item {
                padding: 15px 0;
                gap: 10px;

                &--accessibility {
                    padding: 0;
                }
            }

            &__icon {
                @include size(35px);

                &--accessibility {
                    @include size(45px);
                }
            }

            &--accessibility {
                padding: 25px 0;
            }
        }
    }

}

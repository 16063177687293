//======================================================================================================
// Bloc Image
//======================================================================================================
.bloc-image {
    display: flex;
    margin-top: 30px;

    &__container {
        position: relative;
        display: inline-flex;
        justify-content: center;
        max-width: 100%;
        overflow: hidden;
        border-radius: $border-radius--image;

        picture, img {
            max-width: 100%;
        }
    }

    &__link {
        position: relative;
        line-height: 0;
    }

    &__icon {
        @include absolute($top: 10px, $right: 10px);
    }

    &__caption {
        position: absolute;
        bottom: 20px;
        margin: 0 20px;
        max-width: 100%;
        padding: 10px 20px;
        background-color: $color-bg-caption;
        border-radius: $border-radius-caption;
        color: $color-caption;
        pointer-events: none;
        text-align: center;
    }

    &.original {
        img {
            @include size(100%);
            object-fit: cover;
            //object-position: top;
        }
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }
}

.m-fa-2_sliding_blocs {
    
    &--with_title {
        .m-2_sliding_blocs__nav {
            position: relative;
            margin-top: $m-fa-2_sliding_blocs_nav_btn-margin-top;
            min-height: 40px;
        }
    }

    .m-2_sliding_blocs {
        &__nav {
            .nav-swiper--prev, .nav-swiper--next {
                background-color: $m-fa-2_sliding_blocs_nav_btn-bg;
                border-color: $m-fa-2_sliding_blocs_nav_btn-bd-color;

                .button__icon {
                    fill: $m-fa-2_sliding_blocs_nav_btn-color;
                }
            
                &:hover, &:focus {
                    background-color: $m-fa-2_sliding_blocs_nav_btn-bg--hover;
                    border-color: $m-fa-2_sliding_blocs_nav_btn-bd-color--hover;

                    .button__icon {
                        fill: $m-fa-2_sliding_blocs_nav_btn-color--hover;
                    }
                }
            }
        }

        &__buttons {
            justify-content: $m-fa-2_sliding_blocs-justifyContent;
        }
    
        &__button {
            background-color: $m-fa-2_sliding_blocs_btn-bg;
            border-color: $m-fa-2_sliding_blocs_btn-bd-color;
            color: $m-fa-2_sliding_blocs_btn-color;
            
            .button__icon {
                fill: $m-fa-2_sliding_blocs_btn-color;
            }
    
            &:hover, &:focus {
                background-color: $m-fa-2_sliding_blocs_btn-bg--hover;
                border-color: $m-fa-2_sliding_blocs_btn-bd-color--hover;
                color: $m-fa-2_sliding_blocs_btn-color--hover;
                
                .button__icon {
                    fill: $m-fa-2_sliding_blocs_btn-color--hover;
                }
            }
        }
    }
    
    &__content {
        margin: 0 !important; // swiper override
    }

    &__item {
        height: auto !important; // swiper override
    }

    .c-admin_area {
        &__image {   
            @include size(100%, 320px);
            border-radius: $m-fa-2_sliding_blocs_image-radius;
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fa-2_sliding_blocs {
        .c-admin_area__image {
            height: 255px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .m-fa-2_sliding_blocs {
        .c-admin_area {
            &__image {
                height: 210px;
            }

            &__figcaption {
                bottom: 10px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .m-fa-2_sliding_blocs {
        &--with_title .m-2_sliding_blocs__nav {
            position: unset;
            margin-top: 0;
        }
    }

}

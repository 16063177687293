.m-fqa-double_left {
    .m-access {
        padding: $m-fqa-double_left_access-padding;
    }

    .c-custom_menu {
        padding: $m-fqa-double_left_menu-padding;

        &::before {
            left: 0;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-fqa-double_left {
        .m-access {
            padding: $m-fqa-double_left_access-padding--1200;
        }

        .c-custom_menu {
            padding: $m-fqa-double_left_menu-padding--1200;
        }
    } 

}

//======================================================================================================
// Header
//======================================================================================================

$header-height                                  : 140px !default;
$header-padding                                 : 25px 0 !default;
$header-bg                                      : $color-white !default;
$header-border                                  : 0 !default;
$header-shadow                                  : none !default;
$header_nav-gap                                 : 35px !default;
$header_nav-gap--1200                           : 10px !default;
$header_nav-gap--960                            : 10px !default;
$header_content-alignItems                      : flex-end !default;

$header-height--fixed                           : 100px !default;
$header-padding--fixed                          : 20px 0 !default;
$header-bg--fixed                               : $color-white !default;
$header-border--fixed                           : 0 !default;
$header-shadow--fixed                           : $shadow !default;
$header_content-alignItems--fixed               : center !default;

$header-height--960                             : 100px !default;
$header-height--640                             : 100px !default;
$header--fixed-height--640                      : 70px !default;


//======================================================================================================
// Header - Model full under top
//======================================================================================================
$header_m_full_under_top-filter-shadow          : drop-shadow(0 0 30px rgba(0, 0, 0, .549)) !default;
$header_m_full_under_top-filter-shadow--fixed   : none !default;
$header_m_full_under_top-bg                     : linear-gradient(180deg, rgba($color-main, 0.9) 0%, rgba($color-main, 0) 100%) !default;
$header_m_full_under_top-bg--fixed              : $color-dark !default;
$header_m_full_under_top-bg--mobile             : linear-gradient(180deg, $color-main 0%, rgba($color-main, 0) 100%) !default;
$header_m_full_under_top_svg-color              : $color-text--main !default;
$header_m_full_under_top_link-color             : $color-text--main !default;
$header_m_full_under_top_svg-color--fixed       : $color-white !default;
$header_m_full_under_top_link-color--fixed      : $color-white !default;
$header_m_full_under_top_svg-color--mobile      : $color-text--main !default;


//======================================================================================================
// Header - Tools
//======================================================================================================
$tool-bg                                        : $color-main !default;
$tool-border-color                              : $color-main !default;
$tool-color                                     : $color-text--main !default;

    // Hover, Focus
    $tool-bg--hover                                 : transparent !default;
    $tool-border-color--hover                       : $color-main !default;
    $tool-color--hover                              : $color-text !default;

    // Header fixed
    $tool-bg--fixed                                 : $color-main !default;
    $tool-border-color--fixed                       : $color-main !default;
    $tool-color--fixed                              : $color-text--main !default;

        // Hover, Focus
        $tool-bg--fixed--hover                          : transparent !default;
        $tool-border-color--fixed--hover                : $color-main !default;
        $tool-color--fixed--hover                       : $color-text !default;


//======================================================================================================
// Header - Google Translate
//======================================================================================================
$gt_wrapper-top                                 : 65px !default;
$gt_wrapper-gap                                 : 10px !default;
$gt_wrapper-padding                             : 15px 20px 30px !default;
$gt_wrapper-bg                                  : $color-white !default;
$gt_wrapper-radius                              : $border-radius !default;
$gt_wrapper-radius--arrow                       : 5px !default;
$gt_wrapper-filter                              : drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08)) !default;

$gt_link-color                                  : $color-text !default;
$gt_link-color--hover                           : $color-text !default;

$gt_link-fweight--current                       : $font-weight-bold !default;
$gt_link-color--current                         : $color-text !default;

$gt_close-size                                  : 26px !default;
$gt_close-bg                                    : $color-main !default;
$gt_close-border-color                          : $color-main !default; 
$gt_close-color                                 : $color-text--main !default;
$gt_close-bg--hover                             : $color-white !default;
$gt_close-border-color--hover                   : $color-white !default; 
$gt_close-color--hover                          : $color-text !default;

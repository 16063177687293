.m-mixed-slideshow_access {
    display: flex;
}

.m-mixed-slideshow {
    position: relative;
    width: calc(50% + 220px + $m-mixed-sa__slideshow-width);
    background-color: $m-mixed-sa__access-bg;
    
    &__container {
        @include absolute(0, 0, 0, 0);
        pointer-events: none;
    }

    &__container, .c-slideshow__container {
        padding-left: calc((100vw - ($large - $gl-gutter))/2);
    }

    .c-slideshow {
        height: 550px !important;

        &__bloc--video, &__link--video {
            height: 550px !important;
        }

        &__figcaption {
            max-width: 485px;
        }
    }
}

.m-mixed-access {
    position: $m-mixed-sa-position;
    z-index: $m-mixed-sa-zindex;
    @include flex($alignItems: center);
    flex: 1;
    margin-left: $m-mixed-sa__access-margin-left;
    padding-left: $m-mixed-sa__access-padding-left;
    padding-top: $m-mixed-sa__access-padding-top-bottom;
    padding-bottom: $m-mixed-sa__access-padding-top-bottom;
    @extend %SpaceFull-to-Container--padding-right;
    border-radius: $m-mixed-sa__access-radius;
    background-color: $m-mixed-sa__access-bg;

    &__container {
        height: 100%;
        max-width: inherit;
        margin: 0;
        padding: 0;
    }

    &__list {
        height: 100%;
        @include flex($wrap: wrap, $alignItems: $m-mixed-sa__access__list-alignItems, $justifyContent: $m-mixed-sa__access__list-justifyContent);
        align-content: $m-mixed-sa__access__list-alignContent;
        gap: $m-mixed-sa__access__list-gap;
        padding: $m-mixed-sa__access__list-padding;
        background-color: $m-mixed-sa__access__list-bg;
        box-shadow: $m-mixed-sa__access__list-shadow;
    }

    &__item {
        @include size(140px);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-mixed-slideshow {
        width: calc(50% + 150px);

        &__container, .c-slideshow__container {
            padding-left: calc((100vw - ($medium - $gl-gutter-md))/2);
        }

        .c-slideshow {
            height: 460px !important;

            &__bloc--video, &__link--video {
                height: 460px !important;
            }

            &__content, &__figcaption {
                max-width: 420px;
            }
        }
    }

    .m-mixed-access {
        padding-left: $m-mixed-sa__access-padding-left--1200;
        padding-top: $m-mixed-sa__access-padding-top-bottom--1200;
        padding-bottom: $m-mixed-sa__access-padding-top-bottom--1200;
    
        &__list {
            gap: $m-mixed-sa__access__list-gap--1200;
            align-items: center;
            justify-content: center;
        }
    
        &__item {
            @include size(120px);
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-mixed-slideshow_access {
        display: block;
    }

    .m-mixed-slideshow {
        width: 100%;

        &__container, .c-slideshow__container {
            padding: 0 ($gl-gutter-sm * 0.5);
        }
    }


    .m-mixed-access {
        padding: $m-mixed-sa-padding-top-bottom--960 0;
        margin-left: 0;
        border-radius: 0;

        &__container {
            width: 100%;
            max-width: 90%;
            padding: 0 ($gl-gutter-sm * 0.5);
            margin: 0 auto;
        }

        &__list {
            padding: $m-mixed-sa__access-padding-top-bottom--960 0;
            justify-content: space-evenly;
            align-items: flex-start;
        }
    
        &__item {
            @include size(100px, auto);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .m-mixed-slideshow {

        &__container, .c-slideshow__container {
            padding: 0 ($gl-gutter-xs * 0.5);
        }

        &--with-description {
            .m-mixed-slideshow__nav {
                bottom: unset;
                top: 200px;
            } 

            .m-mixed-slideshow__pag {
                bottom: unset;
                top: 210px;
            } 
        }

        .c-slideshow {
            height: auto !important;

            &__bloc--video, &__link--video {
                height: auto !important;
            }

            &__content, &__figcaption {
                max-width: 100%;
            }

            img {
                height: 280px;
            }
        }
    }
    
    .m-mixed-access {
        &__container {
            padding: 0 ($gl-gutter-xs * 0.5);
        }

        &__list {
            padding: $m-mixed-sa__access-padding-top-bottom--640 0;
            // justify-content: center;
        }
    }

}

//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

// Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered block
//=================================================================================================

// Usage : @extend %center
%center {
    display: block;
    margin-left: auto;
    margin-right: auto; 
}


//=================================================================================================
// Centered container
//=================================================================================================

// Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//=================================================================================================
// Cacul for positionning element left or right container
//=================================================================================================

// Usage : @extend %SpaceFull-to-Container--left
%SpaceFull-to-Container--left {
    left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--right
%SpaceFull-to-Container--right {
    right: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--padding-left
%SpaceFull-to-Container--padding-left {
    padding-left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--padding-right
%SpaceFull-to-Container--padding-right {
    padding-right: calc((100% - ($large - $gl-gutter))/2);
}


//=================================================================================================
//  External link
//=================================================================================================
// Usage : @extend %external_linkAfter
%external_linkAfter {
    &::after {
        content: "";
        display: inline-block;
        mask-image: url($urlShort + 'ic-nav-exit.svg');
        mask-size: contain;
        mask-repeat: no-repeat;
        background-color: $color-link;
        @include size(20px);
        transition: background-color $duration $timing
    }
}

// Usage : @extend %external_link
%external_link {
    &[target="_blank"] {
        @extend %external_linkAfter;
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    %container {
        max-width: $medium;
        padding: 0 ($gl-gutter-md * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--padding-left {
        padding-left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--padding-right {
        padding-right: calc((100% - ($medium - $gl-gutter-md))/2);
    }
}


// 960
@media screen and (max-width: $medium) {

    %container {
        max-width: 90%;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--padding-left {
        padding-left: calc((100% - (90% - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--padding-right {
        padding-right: calc((100% - (90% - $gl-gutter-sm))/2);
    }
}


// 640
@media screen and (max-width: $small) {

    %container {
        padding: 0 ($gl-gutter-xs * 0.5);
    }
    
    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--padding-left {
        padding-left: calc((100% - (90% - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--padding-right {
        padding-right: calc((100% - (90% - $gl-gutter-xs))/2);
    }
}

// Thème Créalocal

    // Accès rapides
    .c-access {
        background-color: $color-white;
        border-radius: $border-radius;
        box-shadow: $shadow;
        transition: {
            property: background-color, box-shadow;
            duration: $duration;
            timing-function: $timing;
        }

        &:is(a) {
            &:hover, &:focus {
                background-color: $color-main;
                box-shadow: unset;
            }
        }
    }

    @media screen and (max-width: 960px) {
        
        .m-fqa-classic__list {
            justify-content: center;
        }
    }

    // Publications

        // Swiper
        .m-fp-2_slides, .m-fp-1_big_3_slides, .m-fp-3_slides {
            .swiper {
                padding-bottom: 20px;
                margin-bottom: -20px!important;
            }
        }

    // Kiosque
    .c-docs {
        &__content {
            background-color: $color-light;
        }
    }

// Thème Client

    // Access
    .m-fqa-classic {
        background-color: $color-light;

        .c-access {
            &:is(a) {
                &:hover, &:focus {
                    background-color: $card-bg--hover;
                }
            }
        }
    }

    // Events
    .m-fp-3_slides--events {
        background-color: $color-light;
    }

    // Admin Area
    .m-fa-1_sliding_bloc .c-admin_area__content {
        background-color: $color-white;
        box-shadow: $shadow;
    }

    .m-fa-1_sliding_bloc {
        .container {
            overflow: hidden;
        }

        .swiper {
            overflow: unset;
        }
    }
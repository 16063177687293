.m-social_wall {

    &__links {
        @include flex($wrap: wrap, $alignItems: center, $justifyContent: flex-end);
        gap: 20px;
    }

    &__social {
        background-color: $m-social_wall__socials_btn-bg;
        border-color: $m-social_wall__socials_btn-bd-color;
        
        .button__icon {
            fill: $m-social_wall__socials_btn-color;
        }

        &:hover, &:focus {
            background-color: $m-social_wall__socials_btn-bg--hover;
            border-color: $m-social_wall__socials_btn-bd-color--hover;
            
            .button__icon {
                fill: $m-social_wall__socials_btn-color--hover;
            }
        }
    }

    &__button {
        background-color: $m-social_wall__buttons_btn-bg;
        border-color: $m-social_wall__buttons_btn-bd-color;
        color: $m-social_wall__buttons_btn-color;
        
        .button__icon {
            fill: $m-social_wall__buttons_btn-color;
        }

        &:hover, &:focus {
            background-color: $m-social_wall__buttons_btn-bg--hover;
            border-color: $m-social_wall__buttons_btn-bd-color--hover;
            color: $m-social_wall__buttons_btn-color--hover;
            
            .button__icon {
                fill: $m-social_wall__buttons_btn-color--hover;
            }
        }
    }
    
    .ff-stream.ff-layout-grid {
        padding: 0;
        background: none !important;
        min-height: unset !important;

        .ff-header, .ff-loadmore-wrapper {
            display: none !important;
        }

        .ff-stream-wrapper {
            padding: 0;
            margin: 0 -30px;
        }

        .picture-item__inner {
            background: $m-social_wall__socials_card-bg !important;
            color: $m-social_wall__socials_card-color !important;
            box-shadow: $m-social_wall__socials_card-shadow !important;
            overflow: hidden !important;
            border-radius: $m-social_wall__socials_card-radius !important;
            transition-duration: $duration--second;

            &::before {
                background-color: rgba(0, 0, 0, 0) !important;
                transition: background-color $duration--second $timing !important;
            }

            &:hover, &:focus {
                box-shadow: $m-social_wall__socials_card-shadow--hover !important;

                &::before {
                    background-color: $m-social_wall__socials_card-bg--hover !important;
                }
            }
        }

        .ff-nickname,.ff-timestamp,.ff-item-bar, .ff-item-bar a,.ff-item h1, .ff-stream-wrapper.ff-infinite .ff-nickname, h4, h4 a, .ff-name, .ff-name, .ff-nickname, .ff-timestamp, .ff-item-bar, .ff-item-bar a {
            color: $m-social_wall__socials_card-color !important;
        }

        .ff-carousel-icon, .ff-video-icon {
            right: 5px !important;
            left: unset !important;
        }

        .ff-label-wrapper {
            left: 10px !important;
            top: 10px !important;
        }

        .ff-icon {
            @include flex($alignItems: center !important, $justifyContent: center !important);
            @include size(30px !important);
            border: 0 !important;
            background: $color-main !important;
            border-radius: $border-radius !important;

            &::before {
                display: none !important;
            }
        }

        .ff-icon-inner {
            left: unset !important;
            right: unset !important;
            top: unset !important;
            bottom: unset !important;
            @include flex($alignItems: center !important, $justifyContent: center !important);
            line-height: 0 !important;
            font-size: toRem(13) !important;
            color: $color-text--main !important;
        }

        .ff-item-bar {
            border-top: 1px solid $m-social_wall__socials_card-ff-item-bar-bd-color !important;
        }
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .m-social_wall {
        .ff-stream.ff-layout-grid {
            .ff-stream-wrapper {
                margin: 0 -20px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .m-social_wall__links {
        justify-content: flex-start;
    }

}


// 640
@media screen and (max-width: $small) {

   

}

$btnAll : ('#push-subscription button, .gform_previous_button, .gform_next_button, .buttons-container .button, .navigation_link, [type=submit], [type=reset]');
$btn : ('[type=submit], [type=reset]');

#{$btn}, button {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
}

#{$btnAll} {
    @include flex($alignItems: center, $justifyContent: center);
    column-gap: 5px;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    width: fit-content;
    min-height: $btn-height;
    padding: $btn-padding;
    border: $btn-border-width $btn-border-style transparent;
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    text-transform: $btn-text-transform;
    text-decoration: none;
    transition: {
        property: color, background-color, border-color;
        duration: $duration;
        timing-function: $timing;
    }

    svg {
        flex: none;
        @include size(26px);
        transition: fill $duration $timing;
    }
}

#{$btn} {
    background-color: $btn-bg;
    border-color: $btn-border-color;
    color: $btn-color;

    svg {
        fill: $btn-color;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background-color: $btn-bg--hover;
        border-color: $btn-border-color--hover;
        color: $btn-color--hover;

        svg {
            fill: $btn-color--hover;
        }
    }
}

.button {
    @include flex($alignItems: center);
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    width: fit-content;
    border: $btn-border-width $btn-border-style transparent;
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    text-transform: $btn-text-transform;
    text-decoration: none;
    transition: {
        property: color, background-color, border-color;
        duration: $duration;
        timing-function: $timing;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active,
    &.is-checked {
        background-color: $btn-bg--hover;
        border-color: $btn-border-color--hover;
        color: $btn-color--hover;
    }
    

    // -------------- Composition -------------- //
    // With icon
    &--icon {
        @include flex($alignItems: center, $justifyContent: center);
        column-gap: 5px;

        .button__icon {
            transition: fill $duration $timing;
        }
    }

    // With icon & text + _blank
    &--reverse {
        flex-direction: row-reverse;
    }


    // -------------- Style -------------- //
    &--primary {
        min-height: $btn-height;
        padding: $btn-padding;
        background-color: $btn-bg;
        border-color: $btn-border-color;
        color: $btn-color;

        .button__icon {
            fill: $btn-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-color--hover;
            background-color: $btn-bg--hover;
            border-color: $btn-border-color--hover;

            .button__icon {
                fill: $btn-color--hover;
            }
        }
    }

    &--secondary {
        min-height: $btn-height;
        padding: $btn-padding;
        background-color: $btn-second-bg;
        border-color: $btn-second-border-color;
        color: $btn-second-color;

        .button__icon {
            fill: $btn-second-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-second-color--hover;
            background-color: $btn-second-bg--hover;
            border-color: $btn-second-border-color--hover;

            .button__icon {
                fill: $btn-second-color--hover;
            }
        }
    }

    &--empty {
        min-height: $btn-height;
        padding: $btn-padding;
        background-color: $btn-empty-bg;
        border-color: $btn-empty-border-color;
        color: $btn-empty-color;

        .button__icon {
            fill: $btn-empty-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-empty-color--hover;
            background-color: $btn-empty-bg--hover;
            border-color: $btn-empty-border-color--hover;

            .button__icon {
                fill: $btn-empty-color--hover;
            }
        }
    }
 
    &--tag {
        min-height: $btn-tag-height;
        padding: $btn-tag-padding;
        background-color: $btn-tag-bg;
        border-width: $btn-tag-border-width;
        border-color: $btn-tag-border-color;
        color: $btn-tag-color;

        .button__icon {
            fill: $btn-tag-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            color: $btn-tag-color--hover;
            background-color: $btn-tag-bg--hover;
            border-color: $btn-tag-border-color--hover;

            .button__icon {
                fill: $btn-tag-color--hover;
            }
        }
    }

    &--icon_alone {
        @include size(40px);
        min-height: inherit;
        padding: 0;
        background-color: $btn-bg;
        border-color: $btn-border-color;

        .button__icon {
            fill: $btn-color;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background-color: $btn-bg--hover;
            border-color: $btn-border-color--hover;

            .button__icon {
                fill: $btn-color--hover;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 480
@media screen and (max-width: $x-small) {

    #{$btnAll}, .button--primary, .button--secondary {
        width: 100%;
        padding: $btn-padding--640;
        text-align: center;
        justify-content: center;
        line-height: 1.3;
    }

}
